@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);

.order-header,
.order-Header__box {
  display: flex;
  flex-direction: column;
}

.order-Header__box,
.order-detail-header {
  width: 700px;
  height: 120px;
  margin-top: 10px;
  border: 1px solid #ccc;
  font-weight: var(--chakra-fontWeights-semibold);
}
.order-Header__search,
.order-Header__date {
  width: 600px;
  text-align: center;
}
.order-Header__search {
  margin-bottom: 10px;
}

.order-Header__search__right input {
  border: 1px solid #ccc;
  margin-right: 10px;
}
.order-Header__search__right,
.order-Header__date__right {
  width: 450px;
}
.order-Header__search,
.order-Header__date,
.order-Header__search__right,
.order-Header__date__right {
  display: flex;
  justify-content: space-between;
}
.order-Header__date__right button {
  width: 70px;
  margin-right: 5px;
}
.order-Header__date__calendar {
  /* width:0!important; */
  width: 120px !important;
  padding: 3px 0px;
  margin-left: 2px;
  border: 1px solid #ccc;
  text-align: center;
}

.order_view_cal {
  -webkit-padding-end: var(--chakra-space-4);
          padding-inline-end: var(--chakra-space-4);
  height: var(--chakra-sizes-10);
  border-radius: var(--chakra-radii-md);
  border: 1px solid;
  width: 100%;
}

.order-detail-header {
  font-weight: 400;
  align-items: flex-start !important;
  justify-content: space-around !important;
  padding: 20px 15px;
  height: 80px;
}
.order-detail-header-textbox {
  width: 500px !important;
  display: grid;
  grid-template-columns: 0.3fr 0.6fr 0.8fr;
}
.order-detail-header-btns {
  height: 20px;
  padding: 5px 10px;
  background-color: #fff !important;
  font-size: 12px !important;
  border: 1px solid gray;
  margin: 0 5px;
}
.order-deatil__payinfo-box {
  width: 700px;
  margin: 20px 0px;
}
.order-deatil__payinfo-title {
  width: 100%;
  margin-top: 10px;
  font-size: 20px;
  border-bottom: 1px solid black;
  padding-bottom: 5px;
}
.order-deatil__payinfo-contents {
  width: 100% !important;
  display: grid;
  grid-template-columns: 1fr 3fr 2fr;
  padding: 10px 0px;
  border-bottom: 1px solid #ccc;
}
.order-deatil__back {
  width: 100px;
  height: 35px;
  margin: 20px 0px;
  font-weight: var(--panda-main-font-weight);
  background-color: var(--panda-main-color);
  border-radius: 5px;
  color: white !important;
  font-size: 15px;
}
.order-deatil__recipt-view {
  width: 100px !important;
  height: 30px !important;
  font-size: 12px !important;
  background-color: white !important;
  border: 1px solid gray;
}

/* =========== */
.order-list__table-header {
  height: 50px;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  font-size: 13px !important;
  background-color: #f9f9f9;
  border-top: 1px solid black;
}

.order-list__item {
  font-family: "Noto Sans KR", sans-serif;
  border-top: none;
  border-bottom: 1px solid var(--chakra-colors-gray-100) !important;
  font-size: 12px;
}
.order-list__item-box {
  height: 20px !important;
  margin-top: 10px;
}
.order-list__item-container {
  width: 750px;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr;
}

.order-list__cart-header {
  width: 700px;
  border-bottom: 1px solid black !important;
  text-align: center;
}

.order-list__cart-container,
.order-list__cart-header {
  width: 750px;
  display: grid;
}
.order-list__cart-container,
.order-list__cart-header {
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr;
}
.order-list__cart-item {
  width: 100%;
  height: 30px;
  text-align: center !important;
}
.order-list__cart-price {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
}
.order-list__shipping-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 80px;
  color: white;
  background-color: #555555;
  border-radius: 4px;
  font-weight: 600;
}
.order-list__shipping-box:hover {
  background-color: var(--panda-main-color);
}
.order-list__cart-info {
  width: 100%;
  padding: 0px 30px;
}
.order-list__cart-item {
  position: relative;
}
.order-list__cart-info-container {
  position: absolute;
  top: 5px;
  height: 120px;
  border-right: 1px solid #eee;
}
.order-list__item {
  font-family: "Noto Sans KR", sans-serif;
  border-top: none;
  border-bottom: 1px solid var(--chakra-colors-gray-100) !important;
  font-size: 12px;
  padding-top: 10px;
}

.container {
  position: relative;
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
}

.container > div:not(#track-line) {
  height: 15px;
  width: 85%;
  margin: 0 auto;
}

.tab-circle-container {
  position: relative;
  height: 100%;
  width: 25%;
  float: left;
}

.tab-circle {
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border-radius: 20px;
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
  box-sizing: border-box;
}

.tab-circle-container span {
}

#track-line {
  width: 85%;
  height: 33px;
  margin: 0 auto;
}

#track-line div {
  width: 25%;
  float: left;
  height: 100%;
  position: relative;
}

#track-line span {
  color: #333;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.track-line .tab-circle-container .active {
  color: #fff;
  width: 35px;
  height: 35px;
  right: -6px;
  line-height: 2.5;
  z-index: 10;
}

.track-line .tab-circle-container .ordering,
.track-line .tab-circle-container .shipping,
.track-line .tab-circle-container .delivery,
.track-line .tab-circle-container .completion {
  height: 100%;
  z-index: 5;
}

.track-line .tab-circle-container .ordering {
  border-radius: 10px 0 0 10px;
}

.track-line .tab-circle-container .completion {
  border-radius: 0 10px 10px 0;
}

.center {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.parent {
  position: relative;
}
.children {
  position: absolute;
  top: -30px;
  left: 0px;
  color: black;
}
.min-height{
  min-height:500px;
}

.layout-category{
  position:absolute;
  top:130px;
  width:200px;
  height:300px;
  background-color:#F6F6F6;
  color:black;
}
.layout__category-box{
  width:200px;
  height:250px;
  padding-left:30px;
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  color:#44465C;
}

.layout-header{
  display:flex;
  justify-content:space-between
}
.layout-body{
  border-top:1px solid black;
}


.mypage-children{
  left:5px;
}
.mypage-Header_text{
  padding-top:20px;
  font-size:12px;
}


.mypage-info, .privacy-children{
  width:210px;
  left:0px;
  }


.header {
  width: 100%;
}
.maxist_body {
  width: 100%;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  width: 1100px;
  color: #555;
  padding-top: 1.25rem;
}
.top-bar,
.search-gnb {
  margin: 0 auto;
}
.search-bar {
  width: 100%;
}
.search-gnb {
  height: 80px;
  width: 1100px;
  color: #fff;
}

.search-gnb > div:first-child {
  width: 400px;
  padding-right: 15px;
}
.search-gnb > div:nth-child(2) {
  width: 450px;
  position: relative;
  display: block;
}
.category-bar {
  border-top: 1px;
  border-bottom: 1px;
  border-color: #ededed;
  width: 100%;
  color: #555;
  font-weight: bold;
  font-size: 14px;
}
.category-bar > div {
  margin: 0 auto;
  width: 1100px;
}
.top-bar a,
.top-bar_box {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-box-pack: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  width: auto;
  line-height: 1.2;
  border-radius: var(--chakra-radii-md);
  font-weight: var(--chakra-fontWeights-semibold);
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  height: var(--chakra-sizes-10);
  min-width: var(--chakra-sizes-10);
  font-size: var(--chakra-fontSizes-md);
  -webkit-padding-start: var(--chakra-space-4);
          padding-inline-start: var(--chakra-space-4);
  -webkit-padding-end: var(--chakra-space-4);
          padding-inline-end: var(--chakra-space-4);
  color: inherit;
}

.top-boar__register {
  width: 80px !important;
  height: var(--chakra-sizes-10);
  margin-left: 15px !important;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 4px !important;
  border: 1px solid var(--panda-main-color);
  color: #ee3842 !important;
}

.top-boar__register:hover {
  background-color: var(--panda-main-color);
  color: white !important;
}

.top-boar__search-input {
  font-weight: var(--chakra-fontWeights-semibold);
}
.logout-btn {
  background-color: white !important;
}

/* 서치인풋 */
.top-bar_search-input {
  position: relative;
  border: 1px solid #f7323f;
  width: 100%;
  height: 50px;
  border-radius: 50px;
  padding: 5px 20px;
  color: #333;
}
.top-bar_search-input__search-icon {
  position: absolute;
  top: 15px !important;
  right: 25px;
  font-size: 20px !important;
  color: var(--panda-main-color);
  cursor: pointer;
}
.top-bar_search-input input {
  display: inline-block;
  height: 40px;
  width: 100%;
}
.top-bar_search-input .dropdown-menu {
  width: 410px !important;
  z-index: 999;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #c5c5c5;
  margin-top: 3px;
}
.top-bar_search-input .dropdown-menu a {
  padding: 3px 0px;
}
.top-bar_search-input .dropdown-menu span {
  padding: 3px 0px;
  display: inline-block;
}
.panda-logo {
  margin-bottom: 30px;
  width: 200px !important;
}
.header-link {
  display: flex;
  align-items: center;
  height: 30px !important;
  margin: 10px 0px;
  position: relative;
  margin-right: 25px;
}
.header-link:first-child {
  width: 150px !important;
  height: 100%;
}

.header-link:last-child {
  display: none;
}

.header-logo {
  padding-bottom: 30px;
}

/* 기본 및 tablet 가로 */
@media (max-width: 1024px) {
  .category-bar > div {
    width: 100%;
  }

  .header-logo img {
    max-width: unset;
  }

  .search-gnb > div:first-child {
    width: unset;
  }

  .search-gnb > div:first-child,
  .search-gnb > div:nth-child(2),
  .search-gnb > div:last-child {
    flex: 1 1;
  }

  .search-gnb > div:last-child > div {
    width: unset;
    margin-left: 0;
  }
}

/* tablet 세로 */
@media (max-width: 768px) {
  .header {
    overflow: hidden;
  }

  .top-bar_box {
    padding: 0 10px;
    margin-left: 10px;
  }
  .search-bar {
    padding-top: 30px;
  }
  .search-gnb {
    display: block !important;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .search-gnb > div:first-child {
    padding-right: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
  }
  .search-gnb > div:nth-child(2) {
    width: 98%;
    margin: 0 auto;
  }

  .search-gnb > div:last-child,
  .header-link:first-child,
  .category-bar > div > div:nth-child(2),
  .header-link:nth-child(7),
  .header-link:nth-child(6) {
    display: none;
  }

  .header-link:last-child {
    display: block;
  }

  .header-link {
    flex: 1 1;
    margin-right: 0;
    display: block;
    text-align: center;
    margin: 0;
    padding: 10px 0;
    height: unset !important;
  }

  .header-link.combine {
    display: none;
  }
}

/* 모바일  */
@media (max-width: 480px) {
}

/* 모바일(구) */
@media (max-width: 370px) {
}


  
/* 배송지관리 페이지 */
.shipping-search-address {
  display: flex;
}
.shipping-checkbox {
  justify-content: flex-end;
}
.shipping-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

/* 포인트 페이지 */
.point-Header {
  display: flex;
  justify-content: space-between;
}
.point-Header_text {
  padding-top: 20px;
  font-size: 12px;
}
.point-body {
  border-top: 1px solid black;
}

.point-table_content,
.point-table_point,
.point-table_use_point,
.point-table_datetime {
  width: 200px;
  font-size: 12px !important;
  text-align: center !important;
}
.point-table_point {
  width: 50px;
}

.point-table_datetime,
.point-table_use_point {
  width: 60px;
}

/* 쿠폰페이지 */
.coupon-table_title,
.coupon-table_discount,
.coupon-table_deadline,
.coupon-table_minimum {
  width: 200px;
  font-size: 12px !important;
  text-align: center !important;
}
.coupon-table_discount,
.coupon-table_deadline {
  width: 50px;
}

.coupon-table_minimum {
  width: 60px;
}

/* 주문내역 찾기 */
.ordernum-main {
  height: 500px;
  width: 100%;
}

.ordernum-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 400px;
  height: 250px;
}
.orderNum-input {
  width: 100%;
  min-width: 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  font-size: var(--chakra-fontSizes-md);
  -webkit-padding-start: var(--chakra-space-4);
  padding-inline-start: var(--chakra-space-4);
  -webkit-padding-end: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  height: var(--chakra-sizes-10);
  border-radius: var(--chakra-radii-md);
  border: 1px solid;
  border-color: inherit;
  background: inherit;
  margin-top: var(--chakra-space-2);
  padding: var(--chakra-space-6);
}
.orderNum-btn {
  font-weight: bold;
  width: 100%;
  height: 40px;
  border: 1px solid var(--panda-main-color);
  font-size: 15px;
  border-radius: 4px;
  color: var(--panda-main-color);
}
.orderNum-btn:hover {
  color: white;
  background-color: var(--panda-main-color);
}
.check__order-input::-moz-placeholder {
  text-align: left;
  font-size: 14px;
}
.check__order-input:-ms-input-placeholder {
  text-align: left;
  font-size: 14px;
}
.check__order-input::placeholder {
  text-align: left;
  font-size: 14px;
}

.coupon-non {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.coupon-non span {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: block;
  margin: 0 auto;
  color: #111;
}

/* 기본 및 tablet 가로 */
@media (max-width: 1024px) {
}

/* tablet 세로 */
@media (max-width: 768px) {
}

/* 모바일  */
@media (max-width: 480px) {
  .coupon-table_title {
    width: 25%;
    font-size: 11.5px !important;
  }

  .coupon-table_discount,
  .coupon-table_deadline {
    width: 25%;
    font-size: 10px !important;
  }

  .coupon-table_minimum {
    width: 25%;
    font-size: 10px !important;
  }

  .point-table_content,
  .point-table_point,
  .point-table_use_point,
  .point-table_datetime {
    width: 25%;
    font-size: 10px !important;
  }

  .estimate-list_wrap table tr td,
  .estimate-list_wrap table thead th {
    font-size: 10px !important;
  }
}

/* 모바일(구) */
@media (max-width: 370px) {
}

.review-talbe{
  width:100%;
  height:680px;
}
.review-talbe_num{
  width:9%;
}
.review-talbe_name{
  width:25%;
}
.review-talbe_register{
  width:15%;
}
.review-talbe_subject{
  width:36%;
}
.review-talbe_date{
  width:15%;
}


.qna-table {
  border-top: 1px solid black;
}
.qna-title,
.qna-title_list {
  font-size: 12px !important;
  width: auto;
  text-align: center !important;
}
.qna-title_list {
  text-align: left !important;
}
.qna-date {
  font-size: 12px !important;
  width: 110px;
  text-align: center !important;
}
.qna-status {
  font-size: 12px !important;
  width: 100px;
  text-align: center !important;
}
.qna-status-box {
  width: 80px;
  height: 30px;

  color: gray;
  background-color: white;
  border: 1px solid black;
  border-radius: 4px;
  font-weight: var(--chakra-fontWeights-semibold);
}
.search-input-box {
  position: relative;
  color: black;
}
.search-input__search-icon {
  position: absolute;
  right: 200px;
  font-size: 20px !important;
  color: var(--panda-main-color);
  cursor: pointer;
  z-index: 8;
}

/* 기본 및 tablet 가로 */
@media (max-width: 1024px) {
}

/* tablet 세로 */
@media (max-width: 768px) {
}

/* 모바일  */
@media (max-width: 480px) {
  .search-input-box input {
    width: 95%;
  }
  .search-input__search-icon {
    right: 5%;
  }

  .center-board_list table thead th {
    font-size: 11px;
    padding: 0 2.5%;
  }

  .center-board_list table thead {
    height: 50px;
  }

  .center-board_list table tbody td {
    padding: 0;
    text-align: center;
    height: 50px;
    font-size: 10px;
  }
}

/* 모바일(구) */
@media (max-width: 370px) {
}

.write-background {
  width: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.write-text {
  width: 100%;
  font-size: 30px;
  color: black;
  font-weight: var(--chakra-fontWeights-semibold);
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid black;
}
.write-subject {
  width: 90% !important;
  height: 40px !important;
  margin: 20px 20px;
  border-radius: 3px;
  border: 1px solid black !important;
  color: black;
}
.write-content {
  width: 95% !important;
  height: 200px !important;
  color: black;
  margin: 0px 10px;
  margin-bottom: 20px;
  border: 1px solid black !important;
}
.write-box-checkbox {
  width: 90% !important;
  text-align: right;
}
.write-checkbox {
  color: black;
  /* border:1px solid black; */
}
.write-register-btn {
  width: 82% !important;
  margin: 10px 0px;
  margin-bottom: 5px;
  padding: 4px;
  background-color: #333333 !important;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 400;
}

div.sign {
  background-color: white;
  display: block;
  width: 65%;
  height: 1050px;
  max-width: 980px;
  margin: 0 auto;
  padding: 58px 90px 50px;
}

div.write-background {
  width: 100%;
}

div.write-text {
  font-weight: 500;
}

div.write-text > span {
  font-size: 15px;
  display: block;
  margin: 15px 0 25px;
  line-height: 1.5;
}

div.write-input {
  width: 100%;
  margin-bottom: 40px;
}

.write-input_tit {
  font-size: 24px;
  color: #000;
  text-align: center;
  padding: 35px 0 50px;
}

div.write-input > input {
  display: block;
  margin: 0 auto 20px;
  padding: 0;
  border: none !important;
  max-width: 400px;
  border-bottom: 1px solid #333 !important;
  border-radius: 0 !important;
}

.write-input_select {
  display: flex;
  color: #333;
  font-size: 15px;
  width: 90%;
  max-width: 400px;
  margin: 0 auto;
}

.write-input_select h3 {
  font-size: 15px;
  display: block;
  margin: 12px 0;
}

.write-input_select select {
  border: 1px solid #333;
  width: 70%;
  margin-left: 20px;
}

.write-input_agree {
  margin-top: 12px;
  color: #333;
  width: 90%;
  margin: 25px auto;
  max-width: 400px;
}

.write-input_agree h3 {
  display: block;
  margin-bottom: 12px;
  font-size: 15px;
}

.write-input_agree_ex input {
  margin-right: 10px;
}

.write-input_agree_ex label {
  color: #999;
  max-width: 400px;
}

.write-input_agree_ex label a {
  font-weight: bold;
}

.write-register-btn {
  display: block;
  background-color: #e53e3e !important;
  font-size: 20px;
  max-width: 400px;
  height: 50px;
}

.write-register-last {
  color: #333;
  font-size: 15px;
  text-align: center;
  margin: 25px 0;
}

/* 기본 및 tablet 가로 */
@media (max-width: 1024px) {
}

/* tablet 세로 */
@media (max-width: 768px) {
}

/* 모바일  */
@media (max-width: 480px) {
}
div.write-text {
  font-size: 20px;
}
div.write-text span {
  font-size: 12px !important;
}

div.write-input .write-input_tit {
  font-size: 20px;
}

.write-input_agree_ex,
.write-register-last {
  font-size: 12px;
}

.write-input_agree h3 {
  font-size: 12px;
}

/* 모바일(구) */
@media (max-width: 370px) {
}

.view-title {
  font-size: 20px;
  font-weight: var(--chakra-fontWeights-semibold);
  margin-bottom: 10px;
}

.view-box {
  width: 700px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}
.view-box__title {
  width: 100%;
  padding: 10px 20px;
  font-weight: var(--chakra-fontWeights-semibold);
  border-top: 1px solid red;
  border-bottom: 1px solid #ccc;
}
.view-box__main,
.view-box__main-notice {
  min-height: 200px;
  padding-left: 20px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.view-box__main-notice {
  font-weight: var(--chakra-fontWeights-semibold);
  padding-bottom: 20px;
}

.view-box__btn-box,
.view-write__btn-box {
  text-align: right;
}
.view-box__btn,
.view-write__btn {
  font-size: 12px !important;
  width: 80px !important;
  height: 25px !important;
  font-weight: bold !important;
  color: white;
  font-size: 10px;
  background-color: #e38d13 !important;
  margin: 10px;
}
.view-btn-color {
  background-color: #f7323f !important;
}
.view-box__reply {
  width: 100%;
  margin-bottom: 10px;
  padding-right: 20px;
}
.view-box__reply__box {
  width: 100px !important;
  justify-content: flex-start !important;
}
.view-write {
  padding: 0px 20px;
}
.view-write__input {
  overflow: visible;
  min-height: 150px !important;
}
.view-box__info {
  margin-bottom: 10px;
  padding: 0px 20px;
}

/* 기본 및 tablet 가로 */
@media (max-width: 1024px) {
}

/* tablet 세로 */
@media (max-width: 768px) {
}

/* 모바일  */
@media (max-width: 480px) {
  .view-box p {
    font-size: 11px;
  }

  .view-box__reply {
    padding-right: 2.5%;
  }

  .view-box__reply p {
    width: 70px;
  }

  .view-write {
    margin-top: 25px;
  }
}

/* 모바일(구) */
@media (max-width: 370px) {
}

.board-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top:10px;
}
.board-text {
  font-size: 25px;
  color: black;
  font-weight: var(--chakra-fontWeights-semibold);
}
.board-btn {
  width: 120px;
  height: 35px;
  background-color: #f7323f;
  color: white;
  font-size: 15px;
  font-weight: var(--chakra-fontWeights-semibold);
  border: 1px solid #f7323f;
  border-radius: 5px !important;
}
.mallCategory_wrap {
  width: 1100px;
  margin: 1px auto 0;
}

.category-container {
  margin-bottom: 100px !important;
}
.category-nav {
  font-weight: var(--panda-main-font-weight);
}
.category-nav__list {
  font-size: 13px !important;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}
.category-nav__list:hover {
  color: var(--panda-main-color);
  font-weight: var(--panda-main-font-weight);
  -webkit-text-decoration-line: none !important;
          text-decoration-line: none !important;
}
.category-box {
  width: 980px;
}
.category-list {
  background-color: #fafafa;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 15px;
  row-gap: 15px;
  font-size: 10px;
  /* color: #7f8c8d; */
  color: #444;
  font-weight: 600;
}
.category-list__manufacturer:hover {
  color: var(--panda-main-color);
}

.category-heading {
  padding: 10px;
  font-size: 15px !important;
  background-color: #eaebec;
  cursor: pointer;
}
.category-counting,
.category-list__title {
  margin-top: 10px;
  font-weight: var(--chakra-fontWeights-semibold);
}
.category-list__title-box {
  display: flex;
}

.category-list_header {
  width: 100%;
  height: 60px;
  padding: 10px;
  padding-left: 30px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  display: grid;
  grid-template-columns: 2fr 4fr;
  background-color: #f9f9f9;
}
.category-info,
.category-info__box,
.category-info_list,
.category-info-list_item {
  display: flex;
}
.category-info {
  width: 980px;
  flex-direction: column;
  border-top: 2px solid gray;
  font-weight: var(--chakra-fontWeights-semibold);
}
.category-info__box {
  height: 100px;
  padding: 10px;
  background-color: #f0f0f0;
  justify-content: space-between;
  position: re;
}
.category-info__box-img {
  width: 100px;
  height: 80px;
  margin-right: 10px;
}
.mallCategory-info__box-info:first-child > a {
  position: absolute;
  width: 100px;
  height: 80px;
}

.category-info__contents {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 30px;
}

.category-info__contents-provider {
  display: flex;
  font-size: 12px;
  margin-left: 10px;
}

.category-info-list_blank {
  width: 10%;
}
.category-info-list_item {
  width: 90%;
  padding: 10px;
  justify-content: space-between;
  border-bottom: 1px solid gray;
  text-align: center !important;
}
.category-info_list:last-child .category-info-list_item {
  border-bottom: 1px solid white;
}

.category-info_list__text {
  font-weight: var(--chakra-fontWeights-semibold);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: block;
  margin: 0 auto;
}

.category-info-list_item__goto {
  margin: 10px 0px;
  width: 150px;
  height: 40px;
  font-size: 15px;
  font-weight: var(--chakra-fontWeights-semibold);
  color: white;
  background-color: var(--panda-main-color);
  border-radius: 3px;
}
.category-info-list_item__goto:hover {
  background-color: #c0392b;
}
.category-info-list_item__goto__text {
  font-size: 10px;
  color: #a6a9ad !important;
}

.mallCategory-info-list_item__goto_backOrder {
  border: 1px solid #ccc;
  margin: 10px 0px;
  width: 120px;
  height: 30px;
  font-size: 13px !important;
}

.mallCategory-info-list_item__goto_inventory {
  /* border: 1px solid #ccc; */
  background-color: orange !important;
  margin: 10px 0px;
  width: 120px;
  height: 30px;
  font-size: 13px !important;
  color: #fff !important;
}

.mallCategory-info-list_item__goto_inventory_list {
  margin-top: 5px;
  height: 30px;
  width: 100%;
}

.mallCategory-grid_product_link > a {
  display: block;
  width: 100%;
  height: 30px;
}

.mallCategory-info-list_item__goto_inventory_list > div {
  display: block;
  font-size: 12px !important;
  padding: 5px 15px;
  background-color: orange;
  color: #fff;
  border-radius: 3px;
}
.mallCategory-info-list_item__goto_inventory_list_grid {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mallCategory-info-list_item__goto_inventory_list_grid > div {
  background-color: orange;
  color: #fff;
  padding: 5px 15px;
  border-radius: 3px;
  font-size: 13px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overflow-show {
  height: auto;
  overflow: visible;
}
.overflow-hidden {
  height: 130px;
  overflow: hidden;
}

/* =====전체 카테고리==== */
.all-category__title {
  color: rgba(45, 52, 54, 0.9) !important;
  display: flex;
  align-items: center;
}
.all-category__title-hover:hover {
  color: black !important;
}
.all-category__content-hover:hover {
  transform: scale(1.2) translate(10%, -10%);
}
.all-category__img {
  width: 30px !important;
  height: 30px !important;
  margin-right: 5px;
}

/* mallCategory */
.mallCategory-box {
  width: 100%;
}

.mallCategory-nav {
  padding-top: 25px;
}

.mallCategory-nav > nav {
  /* text-align: center; */
}

.mallCategory-nav,
.mallCategory-nav__list {
  font-size: 30px !important;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  color: #555 !important;
  font-weight: 400;
}

.mallCategory-list {
  font-size: 30px !important;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  color: #555;
  font-weight: 400;
}

.mallCategory-list_header {
  width: 100%;
  height: 60px;
  padding: 10px 10px 10px 30px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  display: flex;
  grid-template-columns: 2fr 4fr;
  background-color: #f0f0f0;
}

.mallCategory-list_header > div {
  display: inline;
  text-align: center;
  font-weight: 400;
  margin-top: 10px;
}

.mallCategory-list_header > div:first-child,
.mallCategory-info__box-info:first-child {
  width: 50%;
  display: flex;
}

.mallCategory-list_header > div:last-child,
.mallCategory-info__box-info:last-child {
  width: 150px;
}

.mallCategory-list_header > div:nth-child(2),
.mallCategory-list_header > div:nth-child(3),
.mallCategory-info__box-info:nth-child(2),
.mallCategory-info__box-info:nth-child(3) {
  width: calc((50% - 150px) / 2);
}

.mallCategory-list_header {
  width: 100%;
  height: 60px;
  padding: 10px;
  padding-left: 30px;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  grid-template-columns: 2fr 4fr;
  background-color: #f0f0f0;
}

.mallCategory-info {
  width: 100%;
  flex-direction: column;
  border-top: 1px solid #e7e7e7;
  font-weight: var(--chakra-fontWeights-semibold);
}
.mallCategory-info__box {
  height: 100px;
  padding: 10px;
  justify-content: space-between;
  display: flex;
}

.category-menu .swiper {
  display: flex;
  padding-bottom: 10px;
  margin-top: 10px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.category-menu .swiper-wrapper {
  width: 90%;
  margin: 0 auto;
  height: 35px;
}

.category-menu .swiper-wrapper .swiper-slide {
  box-shadow: 10px 5px 5px #ccc;
}

.category-menu .swiper-wrapper .swiper-slide a,
.category-menu .swiper-wrapper .swiper-slide button {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 11.5px;
  transform: translate(-50%, -50%);
}

.category-menu .swiper-wrapper .swiper-slide a div {
  width: 100%;
  height: 90%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}

.category-menu .swiper-wrapper .swiper-slide a:hover,
.category-menu .swiper-wrapper .swiper-slide button:hover {
  color: #ea2f3c;
  font-weight: bold;
}

.category-menu .swiper-button-prev,
.category-menu .swiper-button-next {
  background-color: transparent;
  color: #999 !important;
  font-weight: bold;
  width: 50px;
  height: 50px;

  margin-top: 0;
  width: 50px !important;
  height: 100% !important;
  top: 0 !important;
  display: block;
}

.category-menu .swiper-button-prev::after,
.category-menu .swiper-button-next::after {
  font-size: 20px !important;
}

.category-sub {
  margin-bottom: 10px;
}
.category-sub strong {
  font-weight: 700;
  letter-spacing: 0.1em;
  font-size: 15px;
  color: #333;
  line-height: 1.42857143;
}

.category-list-btn {
  width: 100%;
  height: 30px;
  margin: 20px 0;
  position: relative;
}

.category-list-btn ul {
  float: right;
  display: flex;
  margin-right: 10px;
}

.category-list-btn ul li {
  flex: 1 1;
}

.category-list-btn ul li button {
  display: block;
  font-size: 25px;
  color: #99a3a4;
}

.category-list-btn ul li button.active {
  color: #ee3842;
  font-weight: bold;
}

.category-search {
  display: flex;
  margin: 20px 0;
  height: 35px;
}

.category-search > div {
  display: flex;
}

.category-search > div input {
  border: 1px solid #777;
  border-radius: 10px;
  margin-left: 15px;
  margin-right: 5px;
  width: 350px;
  padding-left: 5px;
}

.category-search > div button {
  width: 35px;
  height: 35px;
  margin-left: 3px;
  background: #ea2f3c;
  border-radius: 10px;
}

.category-search > div button:hover {
  background: #c0392b;
}

.category-search > div button i {
  color: #fff;
  font-size: 17.5px;
  padding: 5px 0;
}

.mallCategory-info-list_item {
  width: 100%;
  padding: 5px 10px;
  justify-content: space-between;
  border-bottom: 1px solid gray;
  text-align: center !important;
}
.mallCategory-info-list_item {
  border-bottom: none;
  flex-direction: column;
}

.mallCategory-info-list_item > p {
  display: inline-block;
  text-align: center;
  width: 90%;
  margin: 0 auto;
  line-height: 35px;
  font-weight: 400;
  color: #111;
}

.mallCategory-info__contents {
  flex: 0 1;
  border-left: 1px solid #e7e7e7;
  margin-right: 0;
  float: right;
}

.mallCategory-info_price span:first-child {
  text-decoration: line-through;
  font-weight: 400;
}

.mallCategory-info_number {
  font-weight: bold;
  line-height: none;
  color: rgb(238, 56, 66);
}

.mallCategory-info-list_item > p span {
  font-weight: bold;
  display: block;
}

.mallCategory-info-list_item__goto {
  margin: 10px 0px;
  width: 120px;
  height: 30px;
  font-size: 13px !important;
}
.mallCategory-info-list_item__goto:hover {
  background-color: #c0392b !important;
}

.mallview_info {
  display: flex;
}

.mallview_info_box {
  width: 600px;
  color: #333;
  padding: 0.25rem;
}

.mallview_info_box h1 {
  margin-top: 25px;
  text-shadow: 2px 2px 2px rgb(255 255 255 / 15%);
}

.mallview_ad {
  width: 1000px;
  /* background-color: red; */
  height: 160px;
  color: #fff;
  margin-top: 2.5rem;
}

.mallview_info_content {
  width: 500px;
  color: #333;
  font-size: 14px;
}

.mallview_pd_img {
  width: 100%;
  max-width: 300px;
  min-height: 250px;
}

.mallview_info_qty {
  width: 290px;
}

.mallCategory-list_container.grid {
}

.mallCategory-list_container.grid .mallCategory-list_wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  gap: 10px;
}

.mallCategory-grid_product {
  padding: 10px;
  overflow: hidden;
}

.mallCategory-grid_product_img {
  margin-bottom: 5px;
}

.mallCategory-grid_product_img img {
  width: 100%;
  height: auto;
  width: 80px;
  height: 80px;
  overflow: hidden;
  margin: 0 auto;
}

.mallCategory-grid_product_name {
  display: block;
  height: 60px;
}

.mallCategory-grid_product_name span:first-child {
  font-size: 10.5px;
  display: block;
  color: #99a3a4;
}

.mallCategory-grid_product_name span:nth-child(2) {
  font-size: 15px;
  display: block;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mallCategory-grid_product_name span:nth-child(3) {
  font-size: 10.5px;
  color: #99a3a4;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  width: 95%;
}
.mallCategory-grid_product_number {
  display: block;
  margin: 10px 0;
}

.mallCategory-grid_product_number span:first-child {
  font-size: 12.5px;
  display: block;
}

.mallCategory-grid_product_number span:nth-child(2) {
  display: block;
  font-size: 15px;
  color: #99a3a4;
  text-decoration: line-through;
}

.mallCategory-grid_product_number span:nth-child(3) {
  display: block;
  color: #ee3842;
  font-size: 15px;
  font-weight: bold;
}

.mallCategory-grid_content {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.mallCategory-grid_product_link {
  display: block;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ccc;
}

.mallCategory-grid_product_link .datasheet {
  display: block;
  text-align: center;
  margin: 0;
}

.mallCategory-grid_product_link > a div {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mallCategory-list_wrap,
.category-list_header {
  display: none;
}

.category-grid_product {
  border-bottom: 1px solid #333;
  margin-bottom: 50px;
  padding-bottom: 25px;
}

.category-grid_product_img img {
  width: 200px;
  height: auto;
  margin: 0 auto;
  overflow: hidden;
}

.category-grid_content {
  width: 100%;
  height: 170px;
  padding-bottom: 25px;
  border-bottom: 1px solid #333;
}

.category-grid_product_name {
  width: 100%;
  height: 60px;
  overflow: hidden;
}

.category-grid_product_name span {
  display: block;
}

.category-grid_product_name span:first-child {
  color: rgb(153, 163, 164);
  font-size: 12px;
}

.category-grid_product_name span:nth-child(2) {
  font-size: 15px;
  color: #111;
  font-weight: bold;
}

.category-grid_product_name span:nth-child(3) {
  font-size: 12px;
  color: rgb(153, 163, 164);
  margin-top: 2.5px;
  overflow: hidden;
  width: 95%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.category-grid_info_contents {
  float: right;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 60px;
  margin-top: 25px;
}

.category-grid_stock_match {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  gap: 10px;
}
.category-grid_stock_match_logo {
  width: 100%;
  height: 100px;
  position: relative;
}

.category-grid_stock_match_logo img {
  width: 75%;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.category-grid_stock_match_number,
.category-grid_stock_match_pkg {
  width: 100%;
  height: 25px;
  color: #555;
  text-align: center;
}

.category-grid_stock_match_box a {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto 10px;
}

.category-grid_stock_match_box {
  padding-bottom: 10px;
  border-bottom: 1px solid #e7e7e7;
}

.category-grid_stock_match_box .category-info-list_item__goto__text {
  text-align: center;
}

.mallCategory-info_right {
  width: 60px;
  height: 25px;
  font-size: 12px !important;
  background-color: rgb(62, 151, 255);
  color: rgb(255, 255, 255) !important;
  border-radius: 5px;
  margin-right: 5px;
  justify-content: center;
  align-items: center;
  display: flex !important;
  text-decoration: none !important;
}

.mallCategory-info_special {
  width: 60px;
  height: 25px;
  font-size: 12px !important;
  background-color: #ee3842;
  color: rgb(255, 255, 255) !important;
  border-radius: 5px;
  padding: 5px;
  margin-right: 5px;
  text-decoration: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.non-dataSheet > div {
  color: #e5e7e9;
  cursor: auto;
}

/* 기본 및 tablet 가로 */
@media (max-width: 1024px) {
  .mall_view {
    width: 100% !important;
  }

  .mallview_ad {
    width: 100%;
  }

  .mallCategory_wrap {
    width: 100%;
  }

  .category-box {
    width: 100%;
  }

  .category-list {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* tablet 세로 */
@media (max-width: 768px) {
  .mallCategory-nav > nav {
    margin-left: 15px;
  }

  .category-grid_stock_match_logo img {
    width: 35%;
  }

  .category-list {
    grid-template-columns: repeat(3, 1fr);
  }

  .category-info {
    width: 100%;
  }

  .mallview_info {
    display: block !important;
  }

  .part_view_bc {
    margin-left: 15px;
  }

  .mallview_info_img {
    align-items: flex-start !important;
  }

  .mallview_info_box {
    width: 100%;
    padding: 5px 15px;
  }

  .mallview_info_content {
    width: 100%;
    color: #333;
    font-size: 14px;
  }

  .mallview_info_content ul li span:first-child {
    font-weight: bold;
    width: 140px;
    display: inline-block;
  }

  .mallview_info_content ul {
    width: 100%;
    height: 100%;
  }

  .mallview_info_content ul li {
    width: 100%;
  }

  .mallview_info_content-mpn,
  .mallview_info_content-manu {
    display: flex;
  }

  .mallview_info_content-mpn span:nth-child(2),
  .mallview_info_content-manu h2 {
    width: calc(100% - 150px);
  }

  .mallview_info_content ul h2,
  .mallview_info_content ul span:nth-child(2) {
    display: inline-block;
    margin-left: 10px;
  }

  .mallview_pd_img {
    max-width: 95%;
    max-height: auto;
    margin: 0 auto;
  }

  .mallview_sheet {
    width: 100%;
    heightl: fit-content;
  }

  .mallview_sheet p {
    width: 95%;
    margin: 5px auto;
  }

  .mallview_info_box article {
    max-width: 100%;
  }

  .mallview_add_cart_btn {
    width: 95%;
    margin: 0 auto;
  }

  .mallview_info_qty {
    width: 95%;
    margin: 0 auto;
  }

  .mallview_info_qty article {
    max-width: 100%;
  }

  .mallview_info_list_btn button {
    font-size: 13.5px;
  }

  .mallCategory-info__box {
    height: 150px;
  }
}

/* 모바일  */
@media (max-width: 480px) {
  .category-list {
    grid-template-columns: repeat(2, 1fr);
  }

  .mallview_info_list_btn button {
    font-size: 11px;
  }

  .category-search > div input {
    width: 300px;
  }

  .mallCategory-list_container,
  .category-list_container {
    overflow: visible;
  }

  .mallCategory-list_wrap,
  .category-list_wrap,
  .mallCategory-info,
  .category-list_header {
    width: 100%;
  }

  .mallCategory-info__box,
  .category-info {
    height: 200px;
    display: block;
  }

  .category-info {
    height: 300px;
  }

  .category-info__box {
    height: 63%;
    display: block;
  }

  .category-info > ul {
    height: 37%;
    border-bottom: 1px solid #555;
  }

  .category-info__box > .category-info__contents {
    float: right;
    margin-right: 5px;
  }

  .mallCategory-info__box-info:first-child {
    width: 100%;
    height: 50%;
    border-bottom: 1px solid #e7e7e7;
    position: relative;
  }

  .mallCategory-info__box-info:nth-child(2) {
    width: 25%;
    float: left;
    height: 55%;
    align-items: center;
    display: flex;
    padding: 1% 0;
  }

  .mallCategory-info__box-info:nth-child(3),
  .mallCategory-info__box-info:last-child {
    width: 40%;
    float: left;
    height: 55%;
    align-items: center;
    display: flex;
    padding: 1% 0;
  }

  .mallCategory-info__box-info:nth-child(3) {
    width: 35%;
  }

  .mallCategory-info__box-info:nth-child(3) p {
    line-height: 1.75;
  }

  .mallCategory-info__box-info:last-child {
    height: 60%;
  }

  .mallCategory-info__contents {
    border-left: none;
    margin-top: 10px;
  }

  .mallCategory-info__contents a:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mallCategory-info {
    border-bottom: 1px solid #444;
  }

  .mallCategory-list_header > div:first-child {
    width: 11%;
  }

  .mallCategory-list_header > div:nth-child(2),
  .mallCategory-list_header > div:nth-child(3) {
    width: 25%;
  }

  .category-list_header {
    display: flex;
  }

  .category-list_header > p {
    flex: 1 1;
  }

  .category-list__title-box {
    flex: 3 1;
  }

  .category-list__title-box > p:first-child {
    margin-right: 10px;
  }

  .search-main {
    overflow: visible !important;
  }

  .search-list__header,
  .search-main .flex-between.search-list__content,
  .search-list_provider {
    width: 100% !important;
  }
  .category-list_wrap > ul {
    height: 100%;
  }

  .category-list_wrap > ul > li.category-info {
    height: 100%;
  }

  .category-info__box {
    height: 170px;
  }

  .category-info .category-info-list_blank {
    display: none;
  }
  .category-info .category-info-list_item {
    width: 100%;
  }

  .affiliate_bg {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #222;
    opacity: 0.25;
  }

  .affiliate_banner {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .affiliate_banner p {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    color: #fff;
    font-size: 13.5px;
  }

  .affiliate_banner h2 {
    position: absolute;
    top: 130px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    font-size: 23px;
    color: #fff;
  }

  .affiliate_banner a {
    display: block;
    position: absolute;
    left: 50%;
    top: 210px;
    transform: translateX(-50%);
    z-index: 100;
  }

  .affiliate_warning {
    font-size: 15px !important;
  }

  .affiliate_notice .affiliate_img h4 {
    font-size: 13px;
  }

  .affiliate_notice .affiliate_img img {
    max-width: 75%;
    margin: 0 auto;
  }

  .mallCategory-info__box-info:first-child div {
    width: calc(100% - 100px);
  }
}

/* 모바일(구) */
@media (max-width: 370px) {
  .mallview_info_list_btn {
    font-size: 10px;
  }

  .category-search > div input {
    width: 200px;
  }
  .mallview_info_content p {
    font-size: 11px;
  }
}

.cart-view_btn-container {
  width: 500px !important;
  margin: 40px !important;
  padding: 0px 30px;
}
.cart-view_btn,
.cart-view_btn-order {
  width: 200px !important;
  height: 50px !important;
  padding: 8px !important;
  border: 1px solid #ee3842 !important;
  color: #ee3842 !important;
  font-size: 15px !important;
  border-radius: 3px !important;
}
.cart-view_btn-order {
  background-color: #ee3842 !important;
  color: white !important;
}
.cart-view_btn-order:hover {
  background-color: #c0392b !important;
}
.cart-view_btn:hover {
  background-color: #ecf0f1 !important;
}

.cart-header {
  margin: 10px 0px;
  margin-top: 50px;
}
.cart-header__text {
  font-size: 12px;
  color: var(--panda-main-color);
  background-color: #f7f7f8;
  margin-right: 10px;
}
.cart-header__right {
  width: 350px;
  display: flex;
  justify-content: flex-end;
}
.cart-header__right__btn {
  width: 100px !important;
  color: white;
  background-color: #ee3842 !important;
  margin: 0px 10px;
}
.cart-header__right__coupon {
  margin-right: 5px;
}
.cart-header__search {
  margin: 0px 10px;
}

.cart-checkbox {
  border: 1px solid var(--panda-main-color);
}

.cart-number-input {
  width: 100px;
}
.cart-product-info {
  display: flex;
}
.cart-product-info__text {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: var(--chakra-fontWeights-semibold);
  color: #b2b2b2;
}
.cart-delete-box {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: #f7f7f8;
  border-bottom: 1px solid #989ba2;
  padding: 0px 20px;
}
.cart-delete-box__btn {
  color: #ffffff;
  font-weight: bold;
  border: 1px solid #707070;
  background-color: #707070 !important;
  margin-right: 10px;
}
.cart-result-box {
  width: 95%;
  height: 80px;
  margin: 10px;
  border: 3px solid #989ba2;
}
.cart-result-box__text {
  padding: 20px 150px;
}
.cart-result-box__order-price {
}
.cart-result-box__paragraph {
  font-size: 10px;
  color: #989ba2;
}
.cart-result-list {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 1000px;
  color: #333;
  font-size: 13px;
}
.cart-result-list__text {
  width: 100%;
  font-size: 13px;
  padding-left: 30px;
  margin: 10px 0px;
  color: var(--panda-main-color);
  text-align: left;
}
.cart-result-btns {
  width: 100%;
  padding: 20px;
}
.cart-result-list__btn-box {
  width: 360px;
}
.cart-result-list__btn,
.cart-header__search-btn {
  border: 1px solid #666;
  background-color: white !important;
}
.cart-result-list__btn {
  width: 400px !important;
  background: var(--chakra-colors-gray-100);
  margin-right: var(--chakra-space-2);
  height: var(--chakra-sizes-10);
  min-width: var(--chakra-sizes-10);
  font-size: var(--chakra-fontSizes-md);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  width: auto;
  line-height: 1.2;
  border-radius: var(--chakra-radii-md);
  font-weight: var(--chakra-fontWeights-semibold);
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
}
.excel-btn {
  width: 100px !important;
  font-size: 12px !important;
}
.cart-result-list__btn:hover {
  background-color: #ecf0f1 !important;
}
.cart-trash {
  cursor: pointer;
  font-size: 1.6em !important;
}
.cart-none {
  font-size: 15px;
  text-align: center !important;
  font-weight: var(--chakra-fontWeights-semibold);
}

/* ======= 거래명세서 =======*/

.estimate-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-right: 20px;
}
.estimate-page-print-btn {
  width: 280px !important;
  height: 50px !important;
  margin-top: 30px;
  background-color: var(--panda-main-color) !important;
  color: white;
  border: none;
}
.estimate-page__buyer-table {
  /* width: 330px;
  height: 150px; */
  /* borderCollapse: "collapse", */
  /* border: 1px solid black;
  text-align: center,; */
}
.estimate-page__buyer-table th,
.estimate-page__buyer-table td {
  border: 1px solid black;
}
.estimate-page__orderlist th,
.estimate-page__orderlist td {
  border: 1px solid black;
}

.cart-header__text_estimate {
  text-align: center;
  font-weight: 400;
  line-height: 1.5;
  font-size: 15px;
  margin-bottom: 10px;
}

.cart-estimate-banner {
  display: block !important;
  height: 130px;
  position: relative;
  background-color: orange;
}

.cart-estimate-banner_tp {
  position: relative;
  width: 100%;
  height: 80%;
}
.cart-estimate-banner_btm {
  position: relative;
  width: 100%;
  height: 20%;
  display: block;
  clear: both;
}

.cart-estimate-banner_tp h1 {
  font-size: 40px;
  position: absolute;
  top: 10px;
  left: 20px;
}

.cart-estimate-banner_tp > div {
  position: absolute;
  top: 20px;
  right: 10px;
}

.cart-estimate-banner_tp > div img {
  width: 30px;
  height: auto;
  filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(306deg)
    brightness(103%) contrast(101%);
  margin: 0 auto 5px;
}

.cart-estimate-banner_tp > div span {
  text-align: center;
}

.cart-estimate-banner_btm span {
  font-size: 15px;
  position: absolute;
  bottom: 10px;
  left: 20px;
}

.estimate-page-body {
  padding-top: 100px;
}
.estimate-page-info {
  margin: 20px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.estimate-page-info-buyer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.estimate-page-info-buyer__box {
  display: flex;
  justify-content: space-around;
  width: 300px;
  border-bottom: 1px solid black;
  margin: 10px 0px;
  padding-bottom: 10px;
}
.estimate-page-info-supply {
  width: 380px;
  height: 150px;
  display: flex;
}
.estimate-page-info-supply-box {
  text-align: center;
}
.estimate-page__total {
  height: 25px;
  font-size: 12px;
  font-weight: 600;
}
.estimate-page__siganture-box {
  position: relative !important;
}
.estimate-page__siganture {
  position: absolute !important;
  width: 60px;
  height: 60px;
  top: -20px;
  left: 30px;
}
.estimate-page-body__title {
  font-size: 25px;
}

.cart-banner {
  width: 1000px;
  color: #fff;
  margin-top: 15px;
}
.cart-banner img {
  width: 1000px;
  height: auto;
}

.cart-body {
  width: 1000px;
  background-color: #fff;
  color: #333;
  font-size: 13px;
}

.order_banner {
  width: 1000px;
  color: #fff;
}

.order_banner img {
  width: 1000px;
  height: none;
}
.order-box {
  width: 1000px;
  color: #333;
  font-size: 13px;
  background-color: #fff;
}

.order-box_info_p {
  width: 1000px;
  height: 20px;
  display: flex;
}
.order-number-table {
  margin-top: 1.25rem;
  border-top: 1px solid #555;
}
.order-payment {
  width: 1000px;
  background-color: #fff;
  color: #333;
  font-size: 13px;
}

.order-notice {
  width: 1000px;
}

.order-payment_btn_mobile {
  display: none !important;
}

.order-done_banner {
  width: 1000px;
  margin-top: 15px;
  color: #fff;
}

.order-done_banner img {
  width: 1000px;
  height: auto;
}

.order-done-table {
  font-size: 15px;
  color: #333;
}

.order-purchaser-row-input input {
  width: 360px;
}

.cart-mobile-box {
  display: none;
}

.cart-header_select_all_chk {
  display: none;
}

.cart-product-row-back-order {
  background-color: #fed7d7;
}

.cart-estimate-mobile-empty {
  width: 100%;
  height: 250px;
  border-top: 1px solid #999;
  position: relative;
}

.cart-estimate-mobile-empty > div {
  width: 100%;
  height: 66px;

  background-color: #f7f7f8;
}

.cart-estimate-mobile-empty h3 {
  width: 100%;
  height: calc(250px - 66px);
  position: relative;
}

.cart-estimate-mobile-empty span {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
}

.cart-order-log-note,
.cart-order-log-note-m {
  border: 1px solid #fed7d7;
  text-align: center;
  background-color: #fed7d7;
  color: #fff;
  padding: 2px 1px;
  border-radius: 3px;
  text-shadow: 2px 2px 2px rgba(0 0 0 / 15%);
}

/* 기본 및 tablet 가로 */
@media (max-width: 1024px) {
  .cart-banner,
  .cart-banner img {
    width: 100%;
  }

  .cart-body {
    width: 100%;
  }

  .cart-result-list {
    width: 100%;
  }

  .order_banner {
    width: 100%;
  }

  .order_banner img {
    width: 100%;
  }

  .order-box {
    width: 100%;
  }

  .order-purchaser-table {
    width: 100% !important;
  }

  .order-payment {
    width: 100%;
  }

  .order-notice {
    width: 100%;
  }

  .order-done_banner {
    width: 100%;
  }

  .order-done_banner img {
    width: 100%;
  }
}

/* tablet 세로 */
@media (max-width: 768px) {
  .cart-body table td {
    -webkit-padding-start: unset;
    padding-inline-start: unset;
    -webkit-padding-end: unset;
    padding-inline-end: unset;
    text-align: center;
  }

  .cart-result-box__text {
    padding: 10px;
  }

  .cart-result-box {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .cart-result-box__text {
    display: block !important;
  }

  .cart-delete-box {
    font-size: 13px;
    display: block !important;
    width: 100% !important;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px;
  }

  .cart-delete-box button {
    margin-bottom: 10px;
  }

  .cart-delete-box p {
    text-align: right;
  }

  .cart-plus,
  .cart-minus,
  .cart-equal {
    display: none;
  }

  .cart-result-box__text div {
    margin-top: 15px;
  }

  .cart-result-box__text div strong {
    margin-left: 10px;
  }

  .cart-result-box__text div:first-child {
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
  }

  .cart-result-box__text div:last-child {
    padding-top: 15px;
    border-top: 1px solid #ccc;
    text-align: right;
  }

  .cart-view_btn-container a {
    margin-right: 10px;
  }

  .order-box tbody tr td:nth-child(2) {
    display: block;
  }

  .order-box table th,
  .order-box table td {
    font-size: 10px;
  }

  .order-box_info_p {
    width: 100%;
    display: block;
  }

  .order-box_buyer_info {
    display: block !important;
  }

  .order-receiver-table {
    -webkit-margin-start: 0;
    margin-inline-start: 0;
  }

  .order-number-table {
    margin-top: 0;
    border-top: none;
    border-bottom: 1px solid #333;
  }

  .order-done-table {
    width: 90% !important;
    margin: 25px auto;
  }

  .cart-view_btn-container {
    width: 100% !important;
  }
}

/* 모바일  */
@media (max-width: 768px) {
  .order-box {
    display: block !important;
    overflow-x: scroll;
  }

  .order-box table {
    width: 150%;
  }

  .order-purchaser-row-input input {
    width: 218px;
  }

  .order-receiver-table {
    margin-left: 0 !important;
  }

  .order-number-table {
  }

  .order-view__table-font-size {
    width: 130px !important;
  }

  .cart-body {
    width: 97.5%;
    overflow: visible;
    display: block !important;
    margin: 0 auto;
  }

  .cart-pc-box {
    display: none;
  }

  .cart-mobile-box {
    display: block;
  }

  .cart-mobile-box ul {
    width: 100%;
  }

  .cart-mobile-box ul li {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    border-top: 1px solid #222;
  }

  .cart-mobile-box ul li:last-of-type {
    border-bottom: 1px solid #222;
  }

  .cart-mobile-box ul li > div {
    display: flex;
  }

  .cart-mobile-box_tp {
    width: 100%;
    height: 40px;
    justify-content: space-between;
  }

  .cart-mobile-box_tp > div {
    display: flex;
    align-items: center;
  }

  .cart-mobile-box_md1 {
    width: 100%;
    height: 100px;
    display: block;
  }

  .cart-mobile-box_img {
    width: 15%;
    margin: 0 10px;
    float: left;
  }

  .cart-mobile-box_img img {
    width: 100%;
    height: auto;
    margin-top: 0;
  }

  .cart-mobile-box_name p span:first-child {
    font-size: 10.5px;
    color: #bbb;
    display: block;
  }

  .cart-mobile-box_name p span:nth-child(2) {
    font-size: 15px;
    display: block;
    font-weight: 600;
    margin-top: 7.5px;
  }

  .cart-mobile-box_md2 {
    height: 40px;
    display: block;
  }
  .cart-mobile-box_md2 > div {
    float: left;
    font-size: 12.5px;
    width: 100%;
  }

  .cart-mobile-box_md2_estimate > div {
    display: flex;
    margin: 20px 0;
  }

  .cart-mobile-box_md2_estimate p {
    margin-left: 15px;
    font-weight: 600;
    font-size: 14.5px;
    margin-right: 10px;
  }

  .cart-mobile-box_md2 p {
    margin-right: 7.5px;
    font-weight: 400;
    font-size: 15px;
    float: left;
  }

  .cart-mobile-box_md2 p,
  .cart-mobile-box_estimate p {
    width: 48%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .cart-mobile-box_btm {
    width: 100%;
    height: 35px;
    margin-bottom: 20px;
  }

  .cart-mobile-box_estimate {
    width: 100%;
    height: 35px;
    margin-bottom: 20px;
  }

  .cart-mobile-box_btm > div {
    display: flex;
    align-items: center;
  }

  .cart-mobile-input_change_number {
    margin-left: 15px;
    margin-top: 5px;
  }

  .cart-view_btn-order.market-cart {
    background-color: orange !important;
    border: 1px solid orange !important;
  }
  .cart-mobile-input_total {
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-left: 15px;
    font-size: 14.5px;
  }

  .cart-checkbox {
    border: none;
  }

  .cart-header_notice {
    display: block !important;
    margin-bottom: 10px;
  }

  .cart-header_notice > div p:last-child {
    margin-right: 7.5px;
  }

  .cart-header__right__coupon,
  .cart-header__text {
    margin-left: 5px;
  }

  .cart-header_coupon_list {
    margin-top: 10px;
    width: 95%;
    margin-left: 5px;
  }

  .cart-header_select_all_chk {
    display: flex;
    margin-left: 5px;
    margin-top: 10px;
  }
  .cart-header_select_all_chk p {
    margin-left: 7.5px;
  }

  /* 견적장바구니 견적수량 장바구니 시작 */
  .estimate-mobile_m1 {
    display: block !important;
    margin-bottom: 30px;
  }

  .cart-mobile-box_name {
    padding-left: 5px;
    float: left;
  }

  .estimate-mobile-count,
  .estimate-mobile-price {
    margin: 10px 0;
    display: block !important;
    height: 40px;
  }

  .estimate-mobile-count p {
    float: left;
    font-size: 14.5px;
    font-weight: 500;
    padding-left: 5px;
    width: 100px;
  }

  .estimate-mobile-count > div {
    float: left;
    width: calc(100% - 185px);
  }

  .estimate-mobile-count button {
    float: left;
    width: 50px;
    height: 35px;
    margin-left: 15px;
    background-color: orange;
    color: #fff;
  }

  .estimate-mobile-price p {
    float: left;
    font-size: 14.5px;
    font-weight: 500;
    padding-left: 5px;
    width: 100px;
  }

  .estimate-mobile-price input {
    float: left;
    width: calc(95% - 100px);
  }
  /* 견적장바구니 견적수량 장바구니 끝*/

  .order-box table {
    display: none;
  }

  .order-box .cart-mobile-box_img {
    display: flex;
    align-items: center;
  }

  .order-box .cart-mobile-box_name {
    display: flex;
    align-items: center;
  }

  .order-box .cart-mobile-box_md2 > div {
    margin-left: 15px;
  }

  .order-box .cart-mobile-box ul li {
    border-top: 1px solid #ccc;
  }

  .order-box .cart-mobile-box ul li:last-of-type {
    border-bottom: 1px solid #ccc;
  }

  .order-box_title {
    margin-left: 15px;
    padding-bottom: 0 !important;
  }

  .order__filter-checkbox {
    padding-top: 0 !important;
  }

  .order-purchaser-table .order-purchaser-row-input {
    width: unset !important;
    margin-left: 10px;
  }

  .order-receiver-table thead th,
  .order-receiver-table__row th {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .order-receiver-table thead td,
  .order-receiver-table__row td {
    width: 100%;
  }

  .order-receiver-table button {
    font-size: 10px;
  }

  .order-number-table td,
  .order-number-table th,
  .order-payment th {
    text-align: center;
  }

  .cart-mobile-cart-none {
    display: block;
    text-align: center;
    margin: 25% 0;
  }

  .order-box-join-alert {
    display: block !important;
    line-height: 1.5;
  }

  .order-box-join-alert a {
    margin-left: 0;
  }

  .receipt_info_table tr td:first-child {
    width: 130px !important;
    text-align: center !important;
    background-color: #f9f9f9;
    font-weight: 7600;
    font-size: 0.75rem;
    color: #4a5568;
  }

  .receipt_info_table_radio_box label {
    clear: both;
    float: left;
    margin: 3px 0;
  }
}

@media (max-width: 480px) {
  .cart-mobile-box_img {
    width: 25%;
  }
}

/* 모바일(구) */
@media (max-width: 370px) {
}

.service-body {
  display: flex;
  flex-direction: column;
  padding-left: 80px;
  margin-bottom: 30px;
  background-color: #fff;
  width: 750px;
  color: #555;
}
.service-title {
  margin-bottom: 50px;
}
.service-box {
  width: 100%;
  margin-bottom: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
}
.service-box-title {
  font-weight: bold;
  margin-bottom: 10px;
}
.service-box-content {
  font-size: 13px;
  margin-bottom: 10px;
}

/* 기본 및 tablet 가로 */
@media (max-width: 1024px) {
  .service-body {
    width: 97.5%;
    padding-left: 0;
    margin: 0 auto;
  }
}

/* tablet 세로 */
@media (max-width: 768px) {
}

/* 모바일  */
@media (max-width: 480px) {
}

/* 모바일(구) */
@media (max-width: 370px) {
}

.noneSearch-img {
  margin-top: 100px;
  width: 100px;
  height: 100px;
}
.noneSearch {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: var(--chakra-fontWeights-semibold);
}
.noneSearch-result {
  font-size: 30px;
  margin: 30px 0px;
}
.search-hidden {
  display: none;
}

.search {
  margin: 30px 0px;
}
.search-main {
  margin-top: 30px;
}

.search__header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.search-filter {
  width: 100%;
  min-height: 150px;
  border-top: 2px solid black;
  border-bottom: 1px solid #eaebec;
  color: black;
  font-size: 11px !important;
  font-weight: normal !important;
}
.search-filter-box,
.search-filter-select {
  width: 1000px;
  min-height: 50px;
  border-bottom: 2px solid #eaebec;
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  align-items: center;
}
.search-filter-box {
  grid-template-columns: 150px auto 100px;
}
.search-filter-select {
  background-color: white;
  min-height: 60px !important;
  border-bottom: none;
}
.search-filter-box_count {
  height: 100%;
  background-color: white;
  cursor: pointer;
}
.search-filter-box_count-none {
  height: 100%;
}

.search__filter-title {
  height: 100%;
  background-color: #f0f0f0;
  font-weight: bold !important;
  font-size: 13px;
}
.search__filter-listItem-grid {
  width: 145px !important;
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-items: flex-start;
  align-items: center;
}
.search__filter-checkbox {
  border: none;
  height: 15px;
  margin-top: 3px;
  margin-left: 20px;
}
.search__filter-checkbox span {
  border: 2px solid #7f8c8d;
  width: 20px;
  height: 20px;
}

.search__filter-listItem {
  height: 50px;
  display: flex;
  margin-left: 10px;
}

.search__filter-list,
.search__filter-select-list {
  background-color: white;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  justify-items: center;
}
.search__filter-select-list {
  grid-template-columns: repeat(8, 1fr);
}
.search__filter-reset,
.search-filter-box__selected-btn {
  width: 100px !important;
  height: 30px !important;
}
.search__filter-reset:hover {
  background-color: #636e72 !important;
}
.search__filter-listItem_x {
  font-size: 16px;
  font-weight: 600;
  color: gray;
}

.search-filter-box__selected-btn:hover {
  background-color: #c0392b !important;
}
.search__searched-count {
  margin-top: 50px;
  margin-bottom: 30px;
  font-size: 20px;
  text-align: center;
}
.search-list__header {
  width: 100%;
  height: 50px;
  padding: 10px;
  padding-left: 30px;
  border-top: 1px solid black;
  display: grid;
  grid-template-columns: 1fr 2fr;
  background-color: #f9f9f9;
}
.search-list__header__box {
  display: flex;
  justify-content: space-around;
}

.search-list__header__checkbox {
  border: 1px solid #f0f0f0;
  margin-right: 5px;
}
.search-list__data {
  width: 1000px !important;
  min-height: 150px;
  margin-bottom: 50px !important;
  display: inline;
  justify-content: space-between;
  background-color: #f0f0f0;
  flex-direction: column;
}
.search-list__img {
  width: 100px;
  height: 100px;
  margin-right: 10px;
}
.search-list__content {
  padding: 20px;
  background-color: #f0f0f0;
  height: 100% !important;
  border-top: 2px solid #7f8c8d;
}

.search-list__content-info,
.search-list_content-dataSheetBox {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: bold;
  /* margin-bottom:50px; */
}
.search-list_provider {
  width: 1000px !important;
  border-bottom: 2px solid #eaebec;
  display: flex;
  grid-template-columns: 50px 50px 100px 120px 150px 20px 100px 150px !important;
  justify-items: center;
}
.search-list_provider:last-child {
  margin-bottom: 30px;
}

.search-list_provider__onday-non {
  width: 60px;
  height: 25px;
  font-size: 12px;
  background-color: #3e97ff;
  color: white;
  border-radius: 5px;
}

.search-list_provider__onday {
  width: 100%;
  height: 100%;
  position: relative;
}

.search-list_provider__onday span {
  width: 60px;
  height: 25px;
  font-size: 12px;
  background-color: #3e97ff;
  color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-list_provider__onday-non {
  background-color: white;
}

.search-list_provider_export {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.search-list_provider__price {
  display: grid;
  justify-content: space-between;
  grid-template-rows: 1fr 1fr;
}
.search-list_provider__price-infos {
  display: grid;
  grid-template-columns: 80px 70px;
  justify-content: space-between;
}
.search-list_provider_export {
  font-size: 12px;
  align-items: center;
}
.search-list_provider__goto,
.search-list_provider__goto-ask {
  margin: 10px 0px;
  width: 100px;
  height: 40px;
  font-size: 13.5px;
}

.search-list_provider__goto-ask {
  width: 100px;
  height: 40px;
  color: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin: 10px 0;
  font-weight: 700;
  font-size: 13.5px;
}
.search-list_provider__goto:hover {
  background-color: #c0392b !important;
}
.search-list_provider__goto-ask:hover {
  background-color: #f39c12 !important;
}

.search-filter__overflow-show {
  height: auto;
  overflow: visible;
}
.search-filter__overflow-hidden {
  height: 50px;
  overflow: hidden;
}
.search-list__contents-provider {
  display: flex;
  font-size: 12px;
}

.search-list_export {
  color: var(--panda-main-color);
}
.none-cursor {
  cursor: none;
}
.search-list__radio input {
  border: 1px solid red;
}

.load_partner-img {
  /* margin-top:30px; */
  width: 200px !important;
  height: 150px !important;
}
.search_loading {
  margin-top: 250px !important;
  padding-top: 7px;
  width: 250px !important;
  height: 170px;
}

.search-list_provider_1st,
.search-list_provider_2nd,
.search-list_provider_3rd {
  display: flex;
}

.search-filter-close {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-align: right;
}

.search-list_provider_3rd.back_order_btn {
  background-color: #fff !important;
  color: #ee3842 !important;
  border-radius: 3px;
  border: 1px solid #ccc;
  cursor: pointer;
  font-size: 15px;
  width: 150px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  height: 40px;
  margin: 10px 0;
}

.search-list_provider_3rd.back_order_btn.inventory_btn {
  background-color: orange !important;
  color: #fff !important;
  border: none !important;
}

/* 기본 및 tablet 가로 */
@media (max-width: 1024px) {
  .search-filter-box,
  .search-filter-select {
    width: 100%;
  }

  .search-list_provider {
    width: 100% !important;
  }

  .search__filter-list,
  .search__filter-select-list {
    grid-template-columns: repeat(3, 1fr);
    width: 50%;
  }
}

/* tablet 세로 */
/* @media (max-width: 768px) {



  .search-list__produc {
    display: block !important;
  }

  .search__filter-listItem-grid {
    width: 130px !important;
  }

  .search__filter-list,
  .search__filter-select-list {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    height: fit-content !important;
    overflow: visible !important;
  }

  .search__filter-listItem-grid > p {
    display: inline-block;
    margin-left: 3.5px;
  }

  .search-filter-box_count-none {
    display: none !important;
  }
} */

/* 모바일  */
@media (max-width: 768px) {
  .search-list__content {
    display: block !important;
  }

  .search-list__content .search-list__product {
    width: 100%;
  }

  .search-list__content .search-list__product .search-list__contents-provider,
  .search-list__content .search-list__product a {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .search-list__content .search-list__product a > div {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .search-list_provider {
    flex-wrap: wrap;
    width: 100% !important;
  }
  .search-list_provider_1st {
    width: 100%;
    height: 100px;
    border-top: 1px solid #ccc;
    padding: 10px 0;
    justify-content: center;
  }

  .search-list_provider__onday-non {
    display: none;
  }
  .search-list_provider_2nd {
    flex-wrap: wrap;
    width: 100%;
    height: 50px;
    justify-content: center;
  }
  .search-list_provider_3rd {
    flex-wrap: wrap;
  }

  .search-list_provider__goto,
  .search-list_provider__goto-ask {
    width: 150px;
    font-size: 15px;
  }

  .search-list_provider__goto-ask {
    width: 150px;
    font-size: 15px;
  }

  .search-list_provider__onday {
    width: 15%;
    height: 100%;
    position: relative;
  }
}

/* 모바일(구) */
@media (max-width: 370px) {
}

/* ======= estimate */
.estimate_btn {
  width: 30rem;
  display: flex;
  justify-content: space-around;
}
.estimate-request__info-box {
  width: 100%;
  height: 200px;
  margin: 20px 0px;
  background-color: #fcf8e3;
  padding: 10px;
  padding-left: 30px;
  color: #8c6f3e;
  border: 1px solid #f5e79e;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.estimate-request__user-info {
  width: 100%;
  margin: 10px 0px;
  margin-bottom: 50px;
}
.estimate-request__user-info__title {
  font-weight: 600px;
  font-size: 20px;
}
.estimate-request__user-info__input-box {
  width: 100%;
  height: 200px;
  margin: 10px 0px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #8c6f3e;
}
.estimate-request__input-container {
  display: flex;
  justify-content: space-around;
}
.estimate-request__input-container-box {
  width: 400px;
  display: flex;
  justify-content: space-around;
}
.estimate-request__checkbox span {
  border: 1px solid black;
  margin-right: 5px;
}

.estimate-request__grid-box {
  height: 250px !important;
  overflow: scroll;
}
.estimate-request__btn-box {
  margin: 20px 0px;
  text-align: center;
}
.estimate-request__btn {
  background-color: white !important;
  border: 1px solid #ccc;
  color: gray;
  font-weight: 400;
  height: 60px !important;
}

.estimate-request__btn:hover {
  background-color: #fff9f9 !important;
  transform: scale(103%);
}

/* 견적내역 페이지 */
.estimate-list__table_num {
  text-align: center !important;
  width: 80px;
  font-size: 14px !important;
}
.estimate-list__table_es_name {
  text-align: center !important;
  width: 300px;
  font-size: 14px !important;
}
.estimate-list__table_es_cnt {
  text-align: center !important;
  width: 100px;
  font-size: 14px !important;
}
.estimate-list__table_price_cnt {
  text-align: center !important;
  width: 110px;
  font-size: 14px !important;
}
.estimate-list__table_reg_date {
  text-align: center !important;
  width: 110px;
  font-size: 14px !important;
}
.estimate-list__table_alarm_flag {
  text-align: center !important;
  width: 150px;
  font-size: 14px !important;
}
.eastimate-list_nav {
  width: 100%;
  height: 50px;
  background-color: #fff9f9;
}
.eastimate-list_nav-box {
  width: 1000px;
  height: 50px;
  background-color: #fff9f9;
  /* padding-left:3px; */
  padding-top: 12px;
  padding-left: 15px;
  display: flex;
  font-size: 16px;
  font-family: var(--chakra-fontWeights-bold);
}

.estimate-list__container {
  width: 980px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* margin-left:300px!important; */
}
/* === 견적 상세페이지 */

.estimate-detail__body {
  width: 1000px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.estimate-detail__table {
  width: 1000px !important;
}
.estimate-detail__table-box {
  border: 1px solid #ddd;
  height: 40px;
}
.estimate-detail__btn {
  width: 100px !important;
  height: 30px !important;
  font-size: 15px !important;
  font-weight: 600px !important;
  border: 1px solid #ddd;
}
.estimate-detail__btn:hover,
.estimate-detail__btn:visited,
.estimate-detail__btn:focus {
  width: 100px !important;
  height: 30px !important;
  font-size: 15px !important;
  font-weight: 600px !important;
  border: 1px solid #ddd !important;
}
.estimate-detail__btn-red {
  background-color: var(--panda-main-color);
  color: white;
}
.estimate-detail__btn-box {
  margin-right: 750px;
}

.estimate_btn {
  width: 30rem;
  display: flex;
  justify-content: space-between;
}

.estimate-select__base_container {
  display: flex;
  justify-content: center;
}
.estimate-request__table-option-box {
  width: 1000px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.estimate-request__table-btn-box {
  display: flex;
  justify-content: space-around;
  width: 180px;
}

/* 기본 및 tablet 가로 */
@media (max-width: 1024px) {
  .eastimate-list_nav-box {
    width: 100%;
  }
  .estimate-list__container {
    width: 95% !important;
  }

  .estimate-detail__body,
  .estimate-detail__table {
    width: 100% !important;
  }
}

/* tablet 세로 */
@media (max-width: 768px) {
  .eastimate-list_nav-box p:first-child {
    display: none;
  }

  .estimate-detail__body {
    display: block;
  }
  .estimate-detail__btn-box,
  .estimate-detail__btn-box-replace {
    display: block !important;
  }

  .estimate-detail__body,
  .estimate-detail__table {
    width: 95% !important;
  }

  .estimate-detail__btn-box {
    margin-right: 0;
  }
}

/* 모바일  */
@media (max-width: 480px) {
}

/* 모바일(구) */
@media (max-width: 370px) {
}

.req-password__base {
  width: 1000px;
  height: 570px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
}
.req-password__title {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 30px;
}
.req-password__box {
  height: 250px;
  width: 500px;
  border: 1px solid #ededed;
  border-radius: 5px;
}
.req-password__input,
.req-password__btn {
  width: 300px !important;
  margin-top: 10px;
}
.req-password__btn {
  background-color: #ee3842 !important;
  color: white;
}
.req-password__btn:hover {
  background-color: var(--panda-main-hover-color) !important;
}

.border-red {
  border: 2px solid red !important;
}

@media (max-width: 1024px) {
  .req-password__base {
    width: 100%;
  }
}

/* tablet 세로 */
@media (max-width: 768px) {
  .req-password__box {
    width: 100%;
    border: none;
  }
}

/* 모바일  */
@media (max-width: 480px) {
}

/* 모바일(구) */
@media (max-width: 370px) {
}

/* === order view page */

.order-info {
  display: flex;
  justify-content: flex-start;
}
.order-log-img {
  width: 60px;
  height: 20px;
}
 .order-receiver-table {
  height: 240px !important;
}

.order-view-img {
  margin-top: 20px !important;
}

.order-view-img {
  margin-top: 20px !important;
}
.order-purchaser-table {
  border-top:1px solid black;
  width:1000px;
  height: 240px;
}
.order-purchaser-row{
  display:flex;
  height:50px;
}
.order-purchaser-table__name-row{
  height:64px;
  border-bottom: 1px solid var(--chakra-colors-gray-100);
  color: var(--chakra-colors-gray-600);
  font-weight: var(--chakra-fontWeights-bold);
  font-family: var(--chakra-fonts-heading);
}
.order-purchaser-table__email-row{
  height:115px;
  border-bottom: 1px solid var(--chakra-colors-gray-100);
  color: var(--chakra-colors-gray-600);
  font-weight: var(--chakra-fontWeights-bold);
  font-family: var(--chakra-fonts-heading);
}
.order-purchaser-table__phone-row{
  height:59px;
  border-bottom: 1px solid var(--chakra-colors-gray-100);
  color: var(--chakra-colors-gray-600);
  font-weight: var(--chakra-fontWeights-bold);
  font-family: var(--chakra-fonts-heading);
}

.order-view__table-font-size{
  
  font-size:12px!important;
  background-color: #f9f9f9;

}
.order-purchaser-row-name{
  width:90px;
}
.order-purchaser-row-input{
  width:400px;
  
}
.order__filter-checkbox {
  border: none;
  height: 15px;
  margin-top: 3px;
  margin-left: 10px;
}

.order__filter-checkbox span:nth-child(2) {
  border: 2px solid #7f8c8d;
  width: 20px;
  height: 20px;
  
}
.brand-main{
  min-width:1000px!important;
  min-height:500px;
}
.brand__img{

  margin-top:5px;
  border:1px solid #eee;
  height:200px;
}
.brand-info-container{
  width:500px;
  margin-left: 20px;
}
.brand-info-container__title{
  display: flex;
  margin-bottom: 10px;
}
/* var */
:root {
  --panda-main-color: #ee3842;
  --panda-main-hover-color: #c0392b;
  --panda-main-font-weight: var(--chakra-fontWeights-bold);
}
.cursor {
  cursor: pointer;
}
.clicked {
  font-weight: bold;
}

/* components */

/* pages */
/* mypage */

/* board */
/* board */
/* cart */
/* auth */
/* search */
/* estimate */
/* auth */
/* order */
/* brand */

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  src: url(/_next/static/media/Montserrat-Italic-VariableFont_wght.7603bae7.ttf);
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  src: url(/_next/static/media/OpenSans-VariableFont_wdth,wght.abb0f3a0.ttf);
}

@font-face {
  font-family: "Nanum Square";
  font-weight: normal;
  font-style: normal;
  src: url(/_next/static/media/NanumSquareRoundR.0f315e74.ttf);
}

@font-face {
  font-family: "NanumBarun";
  font-weight: normal;
  font-style: normal;
  src: url(/_next/static/media/NanumBarunGothic.df624420.ttf);
}

@font-face {
  font-family: "GmarketSans";
  font-style: normal;
  src: url(/_next/static/media/GmarketSansTTFMedium.9ca6ad89.ttf);
}

/* 쓰는 변수 */
/* font-weight: var(--chakra-fontWeights-semibold); */
html,
body {
  font-family: "Noto Sans kr", Nanum Barun Gothic, Nanum Gothic,
    "Apple SD Gothic Neo", "Nanum Square", "Montserrat", Apple SD Gothic Neo,
    Dotum, Verdana, Arial, GmarketSansTTF, sans-serif !important;
  font-size: 14px;
}
.hidden-table {
  display: none;
}
/*chakra 자체의 아웃라인 생기는 부분 수정*/
*:focus,
*:hover,
*:active {
  outline: 0 !important;
}

a:hover,
a:visited,
a:focus {
  text-decoration: none !important;
  outline-offset: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
}

button:focus {
  border: none !important;
  outline: none !important;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-column-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
a {
  color: inherit;
  text-decoration: none !important;
}

* {
  box-sizing: border-box !important;
}
.base_body {
  max-width: 1000px;
  margin: 0 auto;
}

.number {
  font-family: Tahoma;
}
.f12 {
  font-size: 12px !important;
}
.f14 {
  font-size: 14px !important;
}
.f15 {
  font-size: 15px !important;
}
.f16 {
  font-size: 16px !important;
}
.f20 {
  font-size: 20px !important;
}
.f22 {
  font-size: 22px !important;
}
.f24 {
  font-size: 24px !important;
}
.f26 {
  font-size: 26px !important;
}
.bold {
  font-weight: bold !important;
}

input,
textarea {
  border-color: #ccc !important;
}
.btn-primary {
  color: #fff !important;
  background-color: #4e73df;
  padding: 2px;
  border-radius: 3px;
}

.base_container {
  display: block;
  width: 1000px;
  margin: 0 auto;
}
/* === review page */
.review-star {
  width: 120px !important;
}

/* === qna page */
.checked {
  color: white;
  background-color: #f7323f;
  border: 1px solid #f7323f;
}

.point-text {
  font-weight: 800;
  color: #337ab7;
}
.ppoint-text {
  font-weight: 800;
  color: #f7323f;
}

.notice-list {
  font-size: 12px;
}

/* footer 페이지 */
.footer-notice,
.footer-contact {
  font-size: 13px;
}

.footer-contact__text-box {
  width: 350px;
  display: grid;
  grid-template-columns: 0.6fr 1fr 2fr;
  justify-items: start;
}

.menu-bar {
  display: none;
}
/*부품상세*/
.part_view_bc {
  margin-top: 10px !important;
}
.price_table td {
  padding: 10px 3px !important;
}
/*장바구니*/
.cart_num {
  float: left;
}
.cart_num_change {
  float: left;
  padding-left: 10px;
  padding-top: 4px;
}

/*주문페이지*/
.receipt_info_table {
  border: 1px solid #ccc;
}
.receipt_info_table {
  width: 100%;
}
.receipt_info_table th {
  width: 130px !important;
  text-align: center;
  padding-left: 20px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ebebeb;
}

.order_view_cal {
  -webkit-padding-end: var(--chakra-space-4);
          padding-inline-end: var(--chakra-space-4);
  -webkit-padding-start: var(--chakra-space-4);
          padding-inline-start: var(--chakra-space-4);
  height: var(--chakra-sizes-10);
  border-radius: var(--chakra-radii-md);
  border: 1px solid;
}

/* ==== estimate detail 페이지에 사용된 스타일*/
.btn_red {
  background: #412e2f;
  color: #fff;
  font-weight: bold;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 5px 10px;
}
.radius-0 {
  border-radius: 0px !important;
}

.btn_white {
  background: #fff;
  color: #707070;
  padding: 5px 10px;
  font-weight: bold;
  border: 1px solid #c4c4c4;
  cursor: pointer;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
.float-right {
  float: right !important;
}
.text-danger {
  color: #a94442;
}
.text-center {
  text-align: center !important;
}
.text-primary {
  color: #4e73df !important;
}
.text-warning {
  color: #f6c23e !important;
}
.text-success {
  color: #1cc88a !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.mr10 {
  margin-right: 10px !important;
}
.mr40 {
  margin-right: 40px !important;
}

.font-weight-bold {
  font-weight: bold !important;
}
.font10 {
  font-size: 10px !important;
}
.font12 {
  font-size: 12px !important;
}
.font13 {
  font-size: 13px !important;
  margin: 0px;
  padding: 0px;
}
.font18 {
  font-size: 18px !important;
}
/* ==== estimate detail 페이지에 사용된 스타일 종료=====*/

.chakra-radio__control {
  color: #7f8c8d !important;
}
.fa-search-plus {
  margin-left: 5px;
}

.supply-first {
  width: 30px;
  border: none;
}
.supply-second {
  width: 100px;
  height: 29.5px;
}
.supply-third {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

/* ==== pagination=== */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
ul {
  list-style: none;
  padding: 0;
}
ul.pagination li {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}
ul.pagination li:first-child {
  border-radius: 5px 0 0 5px;
}
ul.pagination li:last-child {
  border-radius: 0 5px 5px 0;
}
ul.pagination li a {
  text-decoration: none;
  color: #337ab7;
  font-size: 1rem;
}
ul.pagination li.active a {
  color: white;
}
ul.pagination li.active {
  background-color: #337ab7;
}

.page-selection {
  width: 48px;
  height: 30px;
  color: #337ab7;
}

.jexcel tbody td:nth-child(even),
.jexcel tbody td:nth-child(3) {
  background-color: #fff7ff !important;
}

/*배송 및 환불 관련*/
.shipping .tab-data table {
  margin-bottom: 40px;
  table-layout: fixed;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  font-size: 13px;
  width: 100%;
}
.shipping .tab-data th {
  padding: 12px 16px;
  border-bottom: 1px solid #eee;
  background-color: #fafafa;
  color: #111;
  font-weight: 400;
  font-size: 12px;
  overflow: hidden;
}
.shipping .tab-data td {
  white-space: normal;
  word-break: break-all;
  padding: 12px 16px;
  color: #333;
  border-bottom: 1px solid #eee;
  border-right: none;
  border-left: none;
  border-top: none;
  line-height: 17px;
  font-size: 12px;
}
.shipping .tab-data h3 {
  font-size: 14px;
  padding-top: 20px;
  padding-bottom: 10px;
  font-weight: bold;
}
.dropzone {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.dropzone p {
  text-align: center !important;
}

/* 데이터시트 */
.datasheet {
  display: flex;
  font-size: 12px;
  color: var(--panda-main-color);
  border: 1px solid var(--panda-main-color);
  padding: 5px 15px;
  justify-content: center;
  margin: 5px;
  width: 120px;
  border-radius: 3px;
}

.datasheet_icon {
  width: 15px;
  height: 15px;
  margin-right: 5px !important;
}

.page {
  width: 21cm;

  min-height: 29.7cm;

  padding: 2cm;
  padding-top: 0;
  background: #eee;
  margin: 0 auto;
}

.subpage {
  width: 100%;
  height: 100%;
}

.page h3 {
  margin-top: 15px;
  line-height: 2em;
  text-align: left;
  font-size: 13px;
}

.page h3 span {
  display: inline-block;
  font-weight: bold;
}

@page {
  size: A4;

  margin: 0;
}

@media print {
  html,
  body {
    width: 210mm;

    height: 297mm;
  }

  .page {
    margin: 0;

    border: medium none currentColor;

    border: initial;

    width: auto;

    width: initial;

    min-height: 0;

    min-height: initial;

    box-shadow: none;

    box-shadow: initial;

    background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;

    background: initial;

    page-break-after: always;
  }
}

.link {
  color: blue;
}

/* top-button */
.scroll_container {
  position: fixed;
  right: 5%;
  bottom: 5%;
  z-index: 100;
}
#top {
  font-weight: bold;
  font-size: 20px;
  padding: 7px 15px;
  margin: 0 24px;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgb(233, 236, 239);
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}
#saleBtn {
  display: block;
  font-weight: bold;
  font-size: 14.5px;
  margin-top: 10px;
  padding: 20px 15px;
  background: linear-gradient(
    131.8deg,
    rgb(188, 97, 74) 11.41%,
    rgb(238, 76, 93) 99.63%
  );
  color: #fff;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}

#saleBtn i {
  background: url(/_next/static/media/speaker.d57f53c8.png) no-repeat 50% 50%;
  background-size: cover;
  filter: invert(96%) sepia(96%) saturate(0%) hue-rotate(324deg)
    brightness(102%) contrast(102%);
  display: block;
  width: 30px;
  height: 30px;
}

.register_page {
  /* background: url("../public/images/register_inventory2.jpeg") no-repeat 50% 50%; */
  background-size: cover;
  position: relative;
  height: 1200px;
}

.bg_register {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  top: 0;
  left: 0;
  display: block;
  opacity: 0.35;
  z-index: 1;
}

.sign {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fa-external-link-alt:before {
  content: "\f35d";
}

/* .tui-grid-cell.cell-red {background-color: red}
.tui-grid-cell.cell-green {background-color: green} */
.cell-red {
  background-color: red !important;
}
.cell-green {
  background-color: green;
}

.category_wrap .category_list {
  display: none;
  z-index: 30;
  position: absolute;
  top: 100%;
  left: 0;
  width: 210px;
  height: 610px;
  /*border:1px solid #e1e1e1;*/
  background: #3c464d url("/images/renew_pc/common/catalog_bg.png") no-repeat
    top center / auto auto; /***0429 background-color, height 변경, border삭제***/
  /*-webkit-box-shadow: 10px 7px 16px 2px rgba(0,0,0,0.2);
    -moz-box-shadow: 10px 7px 16px 2px rgba(0,0,0,0.2);
    box-shadow: 5px 5px 16px 2px rgba(0,0,0,0.2);*/
} /***box-shadow 삭제***/
.category_wrap.active .category_list {
  display: block;
}
.category_list a {
  display: block;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}

.category_list > li > a {
  height: 35px;
  line-height: 35px;
  padding: 0 30px 0 13px;
  font-size: 14px;
  letter-spacing: -0.4px;
  color: #fff;
} /***0429 color 추가***/
.category_list > li.active > a {
  /*background-color:#21418d;*/
  color: #3c464d;
  font-weight: bold;
} /***0429 background-color 삭제, color 변경***/
.category_list > li.active > a {
  background-image: url("/images/renew_pc/common/btn/btn_arr_cate.png");
  background-repeat: no-repeat;
  background-position: 189px 50%;
}
.category_list > li .dp2 {
  display: none;
  position: absolute;
  top: -1px;
  left: 100%;
  min-width: 300px;
  height: 525px;
  padding-top: 57px;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  box-shadow: 5px 5px 16px 2px rgba(0, 0, 0, 0.2);
}
.category_list > li.active .dp2 {
  display: block;
}
.category_list > li .dp2 h2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 8px 22px;
  border-bottom: 1px solid #e1e1e1;
  font-size: 24px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}
.category_list > li .dp2 .dp2_con {
  padding: 20px 0 0;
  white-space: nowrap;
}
.category_list > li .dp2 .dp2_con:after {
  content: "";
  clear: both;
  display: block;
}
.category_list > li .dp2 .dp2_con > ul {
  display: inline-block;
  vertical-align: top;
}
.category_list > li .dp2 .dp2_con > ul > li {
  position: relative;
  width: 100%;
}
.category_list > li .dp2 .dp2_con > ul > li > a {
  width: 224px;
  height: 26px;
  line-height: 26px;
  padding: 0 20px;
  font-size: 13px;
  letter-spacing: -0.4;
}
.category_list .dp2_con > ul > li.active > a {
  background-color: #fbeee6;
  color: #3c464d;
}
.category_list .dp2_con > ul > li .dp3 {
  display: none;
  z-index: 40;
  position: absolute;
  top: 0;
  left: 100%;
  width: 190px;
  margin-left: -22px;
  border: 2px solid #3d4c65;
  background-color: #fff;
  border-radius: 4px;
}
.category_list .dp2_con > ul > li.active .dp3 {
  display: block;
}
.category_list .dp2_con > ul > li .dp3 a {
  height: 23px;
  line-height: 23px;
  padding: 0 10px;
  font-size: 12px;
}

/* index_main 시작 */
#visualBanner {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
}

.sec_first {
  box-sizing: border-box;
  word-break: break-all;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  padding: 0;
  border: 0;
  margin: 0 auto;
  width: 1100px;
  height: 468px;
}

.visual {
  /* height: 100%; */
  height: 468px !important;
  width: 670px;
  margin: 0;
  height: 0;
  padding: 0;
  margin: 0;
  border: 0;
  position: relative;
  float: left;
  border: 0;
  word-break: break-all;
}

.access {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 14px;
  font-weight: 700;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: -9999px;
  text-indent: -9999px;
  visibility: hidden;
  color: transparent;
}

.list {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  list-style: none;
  float: left;
  width: 227px;
  height: 100%;
  padding-left: 8px;
  background-color: rgb(86, 100, 114);
}

.list li {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
}

.list li button {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  vertical-align: middle;
  margin: 0;
  font-family: '"Noto Sans KR", dotum, sans-serif';
  font-style: normal;
  border: 0px none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 0px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  overflow: hidden;
  padding: 6px;
  outline: none;
  width: 100%;
  height: 36px;
  line-height: 1.9;
  font-size: 13px;
  font-weight: 400;
  color: rgb(255, 255, 255);
  text-align: left;
  letter-spacing: -0.25px;
}

.cnt {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  float: left;
  overflow: hidden;
  position: relative;
  width: 443px;
  height: 100%;
}

.btn_up_slick_arrow {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  vertical-align: middle;
  margin: 0px;
  padding: 0px;
  font-family: '"Noto Sans KR; dotum, sans-serif';
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  border: 0px none;
  cursor: pointer;
  border-radius: 0px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-repeat: no-repeat;
  outline: none;
  z-index: 20;
  position: absolute;
  top: 50%;
  right: 20px;
  width: 36px;
  height: 36px;
  background-image: url("https://www.icbanq.com/images/renew_pc/main/btn/btn_vertical.png");
  background-color: rgba(0, 0, 0, 0.2);
  text-indent: -9999px;
  color: transparent;
  background-position: 0px 0px;
  margin-top: -38px;
}

.single_item_vertical {
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  height: 468px;
}

.slick_list {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  border: 0px;
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  position: relative;
  display: block;
  transform: translate3d(0px, 0px, 0px);
  height: 468px;
}

.slick_track {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  position: relative;
  top: 0px;
  left: 0px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  opacity: 1;
  height: 12636px;
  transform: translate3d(0px, -1872px, 0px);
}

.slick_slide {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  float: left;
  min-height: 1px;
  display: block;
  border: 0px none;
  height: 468px;
  width: 443px;
}

.slick_slide > div {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
}

.slick_slide > div > div {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  width: 100%;
  display: inline-block;
}

.slick_slide > div > div > a {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  text-decoration: none;
  color: rgb(51, 51, 51);
  outline: none;
  display: block;
  height: 100%;
}

.slick_slide > div > div > a img {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  vertical-align: middle;
  display: block;
  width: 100%;
  height: auto;
}

.btn_down {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  vertical-align: middle;
  margin: 0px;
  padding: 0px;
  font-family: '"Noto Sans KR; dotum, sans-serif';
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  border: 0px none;
  cursor: pointer;
  border-radius: 0px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-repeat: no-repeat;
  outline: none;
  z-index: 20;
  position: absolute;
  top: 50%;
  right: 20px;
  width: 36px;
  height: 36px;
  background-image: url("https://www.icbanq.com/images/renew_pc/main/btn/btn_vertical.png");
  background-color: rgba(0, 0, 0, 0.2);
  text-indent: -9999px;
  color: transparent;
  background-position: 0px -46px;
  margin-top: 2px;
}

.visual_banner {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  overflow: hidden;
  border-left: 1px solid rgb(229, 229, 229);
  border-right: 1px solid rgb(229, 229, 229);
  width: 430px;
  position: relative;
  float: left;
  height: 100%;
}

.im_maker {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  border-bottom: 1px solid rgb(229, 229, 229);
  height: 40%;
}

.thumb_list {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  border: 0px;
  padding: 8px 14px 0px;
}

.thumb_list h3 {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  line-height: 1.8;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
  color: rgb(34, 63, 141);
  background: url("https://www.icbanq.com/images/renew_pc/main/icon/ico_maker.png")
    0px 0px no-repeat;
  padding-left: 24px;
}

.webinar_wrap .thumb_list h3 {
  background: none;
}

.thumb_list ul {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  padding: 0px;
  border: 0px;
  list-style: none;
  overflow: hidden;
  margin: 0px -5px;
}

.thumb_list ul li {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  border: 0px;
  float: left;
  padding: 0px 5px;
  width: 33.3333%;
}

.thumb_list ul li a {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  text-decoration: none;
  color: rgb(51, 51, 51);
  display: block;
}

.thumb_list ul li a > div {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  overflow: hidden;
  height: 72px;
}

.thumb_list ul li a > div img {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  vertical-align: middle;
  width: 100%;
  height: auto;
}

.thumb_list ul li a > p {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  overflow: hidden;
  line-height: 1.4;
  font-size: 13px;
  font-weight: 400;
  color: rgb(51, 51, 51);
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-top: 8px;
  height: 4em;
  -webkit-line-clamp: 3;
}

.coding_mall {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  float: left;
  border-right: 1px solid rgb(229, 229, 229);
  width: 44%;
  height: 60%;
}

.coding_mall .thumb_list {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  border: 0px;
  padding: 8px 14px 0px;
}

.coding_mall .thumb_list h3 {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  line-height: 1.8;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
  color: rgb(34, 63, 141);
  background: url("https://www.icbanq.com/images/renew_pc/main/icon/ico_tit_newico4_20px.png")
    0px 0px no-repeat;
  padding-left: 24px;
}

.coding_mall .thumb_list ul {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  padding: 0px;
  border: 0px;
  list-style: none;
  overflow: hidden;
  margin: 0px -4px;
}
.coding_mall .thumb_list ul li {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  border: 0px;
  float: left;
  padding: 0px 4px 7px;
  width: 50%;
}
.coding_mall .thumb_list ul li a {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  text-decoration: none;
  color: rgb(51, 51, 51);
  display: block;
}
.coding_mall .thumb_list ul li a p {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  overflow: hidden;
  line-height: 1.4;
  font-size: 13px;
  font-weight: 400;
  color: rgb(51, 51, 51);
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-top: 2px;
  height: 2.6em;
  -webkit-line-clamp: 2;
}
.coding_mall .thumb_list ul li a div {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  border: 1px solid rgb(229, 229, 229);
  height: 76px;
  line-height: 70px;
}
.coding_mall .thumb_list ul li a div img {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  vertical-align: middle;
  width: 100%;
  height: auto;
}

.webinar_wrap {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  float: left;
  width: 56%;
  height: 60%;
}
.webinar_wrap .seminar {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  overflow: hidden;
  border-bottom: 1px solid rgb(229, 229, 229);
  width: 100%;
  height: 50%;
}
.webinar_wrap .seminar .thumb_list {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  border: 0px;
  padding: 8px 14px 0px;
  overflow: hidden;
}
.webinar_wrap .seminar .thumb_list h3 {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  line-height: 1.8;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
  color: rgb(34, 63, 141);
}
.webinar_wrap .seminar .thumb_list a {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  color: rgb(51, 51, 51);
  display: block;
  text-decoration: none;
}
.webinar_wrap .seminar .thumb_list a div.desc_wrap {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  float: left;
  width: 65.98%;
  margin-top: -3px;
  padding-left: 10px;
}
.webinar_wrap .seminar .thumb_list a div.desc_wrap h4 {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  overflow: hidden;
  line-height: 1.4;
  font-size: 13px;
  font-weight: 700;
  color: rgb(51, 51, 51);
  height: 4em;
  -webkit-line-clamp: 3;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.webinar_wrap .seminar .thumb_list a div.desc_wrap p {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  overflow: hidden;
  line-height: 1.4;
  font-size: 13px;
  font-weight: 400;
  color: rgb(51, 51, 51);
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-top: 2px;
  height: 2.6em;
  -webkit-line-clamp: 2;
}
.webinar_wrap .seminar .thumb_list a div.img_wrap {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  float: left;
  border: 1px solid rgb(229, 229, 229);
  width: 34.02%;
  height: 82px;
}
.webinar_wrap .seminar .thumb_list a div.img_wrap img {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  vertical-align: middle;
  width: 100%;
  height: auto;
}
.webinar_wrap .im_experiences {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  overflow: hidden;
  width: 100%;
  height: 50%;
}
.webinar_wrap .im_experiences .thumb_list {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  border: 0px;
  padding: 8px 14px 0px;
}
.webinar_wrap .im_experiences .thumb_list h3 {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  line-height: 1.8;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
  color: rgb(34, 63, 141);
}
.webinar_wrap .im_experiences .thumb_list a {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  color: rgb(51, 51, 51);
  display: block;
  text-decoration: none;
}
.webinar_wrap .im_experiences .thumb_list a .img_wrap {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  float: left;
  border: 1px solid rgb(229, 229, 229);
  width: 34.02%;
  height: 82px;
}
.webinar_wrap .im_experiences .thumb_list a .img_wrap img {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  vertical-align: middle;
  width: 100%;
  height: auto;
}
.webinar_wrap .im_experiences .thumb_list a .desc_wrap {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  float: left;
  width: 65.98%;
  margin-top: -3px;
  padding-left: 10px;
}
.webinar_wrap .im_experiences .thumb_list a .desc_wrap h4 {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  overflow: hidden;
  line-height: 1.4;
  font-size: 13px;
  font-weight: 700;
  color: rgb(51, 51, 51);
  height: 4em;
  -webkit-line-clamp: 3;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.webinar_wrap .im_experiences .thumb_list a .desc_wrap p {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  overflow: hidden;
  line-height: 1.4;
  font-size: 13px;
  font-weight: 400;
  color: rgb(51, 51, 51);
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-top: 2px;
  height: 2.6em;
  -webkit-line-clamp: 2;
}

.sec_second {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  padding: 0px;
  border: 0px;
  margin: 0px auto;
  width: 1100px;
  height: 348px;
}

.event_plan_left {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  border-right: 1px solid rgb(229, 229, 229);
  position: relative;
  width: 228px;
  overflow: hidden;
  float: left;
  height: 100%;
}
.event_plan_left .access {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  font-weight: 700;
  font-size: 14px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: -9999px;
  text-indent: -9999px;
  visibility: hidden;
  color: transparent;
}

.event_plan_left .btn_prev_slick_arrow {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  vertical-align: middle;
  margin: 0px;
  padding: 0px;
  font-family: '"Noto Sans KR; dotum, sans-serif';
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  border: 0px none;
  cursor: pointer;
  border-radius: 0px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  overflow: hidden;
  background-repeat: no-repeat;
  outline: none;
  z-index: 20;
  position: absolute;
  top: 50%;
  width: 36px;
  height: 36px;
  margin-top: -18px;
  background-image: url("https://www.icbanq.com/images/renew_pc/main/btn/btn_horizontal.png");
  background-color: rgba(0, 0, 0, 0.2);
  text-indent: -9999px;
  color: transparent;
  background-position: 0px 0px;
  left: 0px;
}
.event_plan_left .btn_prev_slick_arrow span {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  vertical-align: middle;
  display: none;
  z-index: 100;
  position: absolute;
  top: 0px;
  height: 36px;
  line-height: 32px;
  text-indent: 0px;
  font-weight: 400;
  color: rgb(220, 220, 220);
  letter-spacing: 1px;
  right: 8px;
}
.event_plan_left .btn_prev_slick_arrow span .now_num {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  vertical-align: middle;
  font-style: normal;
  font-size: 13px;
  font-weight: 700;
  color: rgb(255, 255, 255);
}
.event_plan_left .btn_prev_slick_arrow span .all_num {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  vertical-align: middle;
  font-style: normal;
  font-size: 13px;
}
ul.single_item_eventPlan {
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  list-style: none;
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
ul.single_item_eventPlan .slick_list {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  border: 0px;
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  position: relative;
  display: block;
  transform: translate3d(0px, 0px, 0px);
}
ul.single_item_eventPlan .slick_track {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  position: relative;
  top: 0px;
  left: 0px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  opacity: 1;
  width: 2951px;
  transform: translate3d(-681px, 0px, 0px);
}
ul.single_item_eventPlan .slick_track .slick_slide {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  float: left;
  height: 100%;
  min-height: 1px;
  display: block;
  width: 227px;
}
ul.single_item_eventPlan .slick_track .slick_slide > div {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
}
ul.single_item_eventPlan .slick_track .slick_slide > div li {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  width: 100%;
  display: inline-block;
}
ul.single_item_eventPlan .slick_track .slick_slide > div li a {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  text-decoration: none;
  color: rgb(51, 51, 51);
  outline: none;
  display: block;
}
ul.single_item_eventPlan .slick_track .slick_slide > div li a img {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  vertical-align: middle;
  display: block;
}

ul.slick_dots {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  list-style: none;
  display: none;
}
ul.slick_dots li {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
}
ul.slick_dots li button {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  vertical-align: middle;
  margin: 0px;
  padding: 0px;
  font-family: '"Noto Sans KR; dotum, sans-serif';
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  border: 0px none;
  cursor: pointer;
  border-radius: 0px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  overflow: hidden;
  background-repeat: no-repeat;
  outline: none;
  z-index: 20;
  position: absolute;
  top: 50%;
  width: 36px;
  height: 36px;
  margin-top: -18px;
  background-image: url("https://www.icbanq.com/images/renew_pc/main/btn/btn_horizontal.png");
  background-color: rgba(0, 0, 0, 0.2);
  text-indent: -9999px;
  color: transparent;
}

button.btn_next_slick_arrow {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  vertical-align: middle;
  margin: 0px;
  padding: 0px;
  font-family: "Noto Sans KR; dotum, sans-serif";
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  border: 0px none;
  cursor: pointer;
  border-radius: 0px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  overflow: hidden;
  background-repeat: no-repeat;
  outline: none;
  z-index: 20;
  position: absolute;
  top: 50%;
  width: 36px;
  height: 36px;
  margin-top: -18px;
  background-color: rgba(0, 0, 0, 0.2);
  text-indent: -9999px;
  color: transparent;
  background-position: right -46px;
  right: 0px;
}
button.btn_next_slick_arrow span {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  vertical-align: middle;
  display: none;
  z-index: 100;
  position: absolute;
  top: 0px;
  height: 36px;
  line-height: 32px;
  text-indent: 0px;
  font-weight: 400;
  color: rgb(220, 220, 220);
  letter-spacing: 1px;
  left: 8px;
}
button.btn_next_slick_arrow span .now_num {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  vertical-align: middle;
  font-style: normal;
  font-size: 13px;
  font-weight: 700;
  color: rgb(255, 255, 255);
}
button.btn_next_slick_arrow span .all_num {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  vertical-align: middle;
  font-style: normal;
  font-size: 13px;
}

.right {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  overflow: hidden;
  float: left;
  height: 100%;
  border-right: 1px solid rgb(233, 233, 233);
  border-bottom: 1px solid rgb(233, 233, 233);
  width: 872px;
}

.right .brand_pdt {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  border-bottom: 1px solid rgb(233, 233, 233);
  position: relative;
  height: 55%;
}

.right .brand_pdt .access {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  font-weight: 700;
  font-size: 14px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: -9999px;
  text-indent: -9999px;
  visibility: hidden;
  color: transparent;
}

.right .brand_pdt .brand_pdt_list {
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  border: 0px;
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  padding: 2px 26px 10px;
  height: 100%;
  opacity: 1;
  visibility: visible;
  border-top: 1px solid rgb(229, 229, 229);
}
.right .brand_pdt .brand_pdt_list .slick_list {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  border: 0px;
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  position: relative;
  display: block;
  transform: translate3d(0px, 0px, 0px);
  height: 100%;
}
.right .brand_pdt .brand_pdt_list .slick_list .slick_track {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  position: relative;
  top: 0px;
  left: 0px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  opacity: 1;
  width: 819px;
  transform: translate3d(0px, 0px, 0px);
}
.right .brand_pdt .brand_pdt_list .slick_list .slick_track .slick_slide {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  float: left;
  min-height: 1px;
  display: block;
  height: 100%;
  width: 117px;
}
.right .brand_pdt .brand_pdt_list .slick_list .slick_track .slick_slide > div {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  height: 100%;
}
.right
  .brand_pdt
  .brand_pdt_list
  .slick_list
  .slick_track
  .slick_slide
  > div
  > div {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  border: 0px;
  height: 100%;
  padding: 2px;
  width: 100%;
  display: inline-block;
}
.right
  .brand_pdt
  .brand_pdt_list
  .slick_list
  .slick_track
  .slick_slide
  > div
  > div
  a {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  text-decoration: none;
  color: rgb(51, 51, 51);
  display: block;
  height: 50%;
  position: relative;
  text-align: center;
}
.right
  .brand_pdt
  .brand_pdt_list
  .slick_list
  .slick_track
  .slick_slide
  > div
  > div
  a
  figure {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  padding: 0px;
  border: 0px;
  height: 100%;
  text-align: center;
  width: 75%;
  margin: 0 auto;
}
.right
  .brand_pdt
  .brand_pdt_list
  .slick_list
  .slick_track
  .slick_slide
  > div
  > div
  a
  figure
  figcaption {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  border: 0px;
  overflow: hidden;
  padding: 0px 5px 5px;
  position: absolute;
  bottom: 1px;
  left: 0px;
  width: 100%;
  max-height: 2.5em;
  line-height: 1.2;
  text-align: center;
  color: rgb(102, 102, 102);
  -webkit-line-clamp: 2;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.right
  .brand_pdt
  .brand_pdt_list
  .slick_list
  .slick_track
  .slick_slide
  > div
  > div
  a
  figure
  img {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  vertical-align: middle;
  display: inline;
}

.social_banner {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  height: 45%;
}

.social_banner h2.access {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  font-weight: 700;
  font-size: 14px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: -9999px;
  text-indent: -9999px;
  visibility: hidden;
  color: transparent;
}
.social_banner .n_blog {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  float: left;
  width: 50%;
  height: 100%;
  border-right: 1px solid rgb(229, 229, 229);
  position: relative;
}
.n_blog_list {
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  border: 0px;
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  padding: 8px 46px 0px;
  height: 100%;
}
.n_blog_list .slick_list {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  border: 0px;
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  position: relative;
  display: block;
  transform: translate3d(0px, 0px, 0px);
  height: 100%;
}
.n_blog_list .slick_list .slick_track {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  position: relative;
  top: 0px;
  left: 0px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  opacity: 1;
  width: 3430px;
  transform: translate3d(-3087px, 0px, 0px);
}
.n_blog_list .slick_list .slick_track .slick_slide {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  float: left;
  height: 100%;
  min-height: 1px;
  display: block;
  position: relative;
  width: 343px;
}
.n_blog_list .slick_list .slick_track .slick_slide > div {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
}

.n_blog_list .slick_list .slick_track .slick_slide > div > div {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  width: 100%;
  display: inline-block;
}
.n_blog_list .slick_list .slick_track .slick_slide > div > div h3 {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  padding-bottom: 8px;
  font-size: 15px;
  font-weight: 700;
  color: rgb(0, 199, 60);
}

.n_blog_list .slick_list .slick_track .slick_slide > div > div strong {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  font-size: 14px;
  font-weight: 700;
  color: rgb(51, 51, 51);
  text-overflow: ellipsis;
}
.n_blog_list .slick_list .slick_track .slick_slide > div > div strong a {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  text-decoration: none;
  color: rgb(51, 51, 51);
}

.n_blog_list .slick_list .slick_track .slick_slide > div > div p {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  overflow: hidden;
  line-height: 1.4;
  font-size: 13px;
  font-weight: 400;
  color: rgb(102, 102, 102);
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  height: 2.6em;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}

.n_blog_list .slick_list .slick_track .slick_slide > div > div span {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  display: block;
  margin-top: 8px;
  font-size: 14px;
  color: rgb(117, 117, 117);
}

.n_blog_list .slick_list .slick_track .slick_slide > div > div a.link_blog {
  box-sizing: border-box;
  word-break: break-word;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  text-decoration: none;
  border-radius: 100%;
  position: absolute;
  bottom: -13px;
  right: 0px;
  width: 34px;
  height: 34px;
  text-indent: -9999px;
  color: transparent;
  background: 'url("https://www.icbanq.com/images/renew_pc/main/icon/ico_blog.png") no-repeat';
}

/* 데이터시트 */
.datasheet {
  display: flex;
  font-size: 12px;
  color: var(--panda-main-color);
  border: 1px solid var(--panda-main-color);
  padding: 5px 15px;
  justify-content: center;
  margin: 5px;
  width: 120px;
  border-radius: 3px;
}

.datasheet_icon {
  width: 15px;
  height: 15px;
  margin-right: 5px !important;
}

.page {
  width: 21cm;

  min-height: 29.7cm;

  padding: 2cm;
  padding-top: 0;
  background: #eee;
  margin: 0 auto;
}

.subpage {
  width: 100%;
  height: 100%;
}

.page h3 {
  margin-top: 15px;
  line-height: 2em;
  text-align: left;
  font-size: 13px;
}

.page h3 span {
  display: inline-block;
  font-weight: bold;
}

@page {
  size: A4;

  margin: 0;
}

@media print {
  html,
  body {
    width: 210mm;

    height: 297mm;
  }

  .page {
    margin: 0;

    border: medium none currentColor;

    border: initial;

    width: auto;

    width: initial;

    min-height: 0;

    min-height: initial;

    box-shadow: none;

    box-shadow: initial;

    background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;

    background: initial;

    page-break-after: always;
  }
}

.link {
  color: blue;
}

/* top-button */
.scroll_container {
  position: fixed;
  right: 5%;
  bottom: 5%;
  z-index: 100;
}
#top {
  font-weight: bold;
  font-size: 20px;
  padding: 7px 15px;
  margin: 0 24px;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgb(233, 236, 239);
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}

.register_page {
  /* background: url("../public/images/register_inventory2.jpeg") no-repeat 50% 50%; */
  background-size: cover;
  position: relative;
  height: 1200px;
}

.bg_register {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  top: 0;
  left: 0;
  display: block;
  opacity: 0.35;
  z-index: 1;
}

.sign {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fa-external-link-alt:before {
  content: "\f35d";
}

/* .tui-grid-cell.cell-red {background-color: red}
.tui-grid-cell.cell-green {background-color: green} */
.cell-red {
  background-color: red !important;
}
.cell-green {
  background-color: green;
}

.category_wrap .category_list {
  display: none;
  z-index: 30;
  position: absolute;
  top: 100%;
  left: 0;
  width: 210px;
  height: 610px;
  /*border:1px solid #e1e1e1;*/
  background: #3c464d url("/images/renew_pc/common/catalog_bg.png") no-repeat
    top center / auto auto; /***0429 background-color, height 변경, border삭제***/
  /*-webkit-box-shadow: 10px 7px 16px 2px rgba(0,0,0,0.2);
    -moz-box-shadow: 10px 7px 16px 2px rgba(0,0,0,0.2);
    box-shadow: 5px 5px 16px 2px rgba(0,0,0,0.2);*/
} /***box-shadow 삭제***/
.category_wrap.active .category_list {
  display: block;
}
.category_list a {
  display: block;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}

.category_list > li > a {
  height: 35px;
  line-height: 35px;
  padding: 0 30px 0 13px;
  font-size: 14px;
  letter-spacing: -0.4px;
  color: #fff;
} /***0429 color 추가***/
.category_list > li.active > a {
  /*background-color:#21418d;*/
  color: #3c464d;
  font-weight: bold;
} /***0429 background-color 삭제, color 변경***/
.category_list > li.active > a {
  background-image: url("/images/renew_pc/common/btn/btn_arr_cate.png");
  background-repeat: no-repeat;
  background-position: 189px 50%;
}
.category_list > li .dp2 {
  display: none;
  position: absolute;
  top: -1px;
  left: 100%;
  min-width: 300px;
  height: 525px;
  padding-top: 57px;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  box-shadow: 5px 5px 16px 2px rgba(0, 0, 0, 0.2);
}
.category_list > li.active .dp2 {
  display: block;
}
.category_list > li .dp2 h2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 8px 22px;
  border-bottom: 1px solid #e1e1e1;
  font-size: 24px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}
.category_list > li .dp2 .dp2_con {
  padding: 20px 0 0;
  white-space: nowrap;
}
.category_list > li .dp2 .dp2_con:after {
  content: "";
  clear: both;
  display: block;
}
.category_list > li .dp2 .dp2_con > ul {
  display: inline-block;
  vertical-align: top;
}
.category_list > li .dp2 .dp2_con > ul > li {
  position: relative;
  width: 100%;
}
.category_list > li .dp2 .dp2_con > ul > li > a {
  width: 224px;
  height: 26px;
  line-height: 26px;
  padding: 0 20px;
  font-size: 13px;
  letter-spacing: -0.4;
}
.category_list .dp2_con > ul > li.active > a {
  background-color: #fbeee6;
  color: #3c464d;
}
.category_list .dp2_con > ul > li .dp3 {
  display: none;
  z-index: 40;
  position: absolute;
  top: 0;
  left: 100%;
  width: 190px;
  margin-left: -22px;
  border: 2px solid #3d4c65;
  background-color: #fff;
  border-radius: 4px;
}
.category_list .dp2_con > ul > li.active .dp3 {
  display: block;
}
.category_list .dp2_con > ul > li .dp3 a {
  height: 23px;
  line-height: 23px;
  padding: 0 10px;
  font-size: 12px;
}

/* 소개 페이지 */
.panda-intro {
  background-color: #fff;
  width: 100%;
  color: #555;
}

.panda-intro_wrap_1st {
  background-color: #fdedec;
  width: 100%;
  height: 500px;
}

.intro_id {
  display: flex;
  max-width: 1080px;
  margin: 0 auto;
  background-color: #fdedec;
  height: 100%;
}

.introLeft {
  flex: 1 1;
  position: relative;
}

.introLeft_ex {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
}

.introLeft_ex > div {
  background: #fed7d7;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 20px;
  padding: 3px 8px;
  border: 1px solid #ea2f3c;
}

.introLeft_ex > div p {
  color: #ea2f3c;
  font-size: 15px;
}

.introLeft_ex h4 {
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 15.5px;
}

.introLeft_ex > span {
  display: block;
  font-size: 20px;
  line-height: 1.5;
}

.introRight {
  flex: 1 1;
}

.introRight img {
  height: 100%;
}

.panda-intro_search {
  background-color: #f6f8fb;
  width: 100%;
  height: 800px;
}

.panda-intro_search_wrap {
  max-width: 1080px;
  position: relative;
  height: 100%;
  margin: 0 auto;
}

.panda-intro_search_tp {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -10%);
}

.panda-intro_search_tp > div {
  background: #fed7d7;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 20px;
  padding: 3px 8px;
  margin: 0 auto 13.5px;
  border: 1px solid #ea2f3c;
}

.panda-intro_search_tp > div > p {
  font-size: 15px;
  color: #ea2f3c;
}

.panda-intro_search_tp > h4 {
  display: block;
  font-size: 50px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 18.5px;
}

.panda-intro_search_tp > span {
  display: block;
  font-size: 19px;
  line-height: 1.5;
  text-align: center;
}

.panda-intro_search_btm {
  position: absolute;
  width: 90%;
  height: 400px;
  left: 50%;
  top: 75%;
  transform: translate(-50%, -75%);
}

.panda-intro_search_btm ul {
  width: 100%;
  height: 200px;
}

.panda-intro_search_btm ul li {
  width: calc(90% / 5);
  height: 100%;
  background: #fff;
  margin: 1%;
  position: relative;
  float: left;
}

.panda-intro_search_btm ul li img {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.panda-intro_search_btm ul:first-child li:nth-child(4) img {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 75%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.panda-intro_estimate {
  background-color: #fff;
  width: 100%;
  height: 500px;
}

.panda-intro_estimate_wrap {
  max-width: 1080px;
  position: relative;
  height: 100%;
  margin: 0 auto;
}

.panda-intro_estimate_wrap_ex {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.panda-intro_estimate_wrap_ex h4 {
  display: block;
  font-size: 45px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  margin-bottom: 30.5px;
}

.panda-intro_estimate_wrap_ex span {
  display: block;
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
}

.panda-intro_estimate_wrap_ex a {
  display: block;
  background: #ea2f3c;
  font-size: 18px;
  text-align: center;
  color: #fff;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 30px auto 20px;
  padding: 20px 90px;
  border-radius: 10px;
}

.panda-intro_estimate_wrap_ex_req span:first-child {
  color: #444;
  text-align: center;
  display: block;
  font-size: 15px;
}

.panda-intro_estimate_wrap_ex_req span:last-child {
  display: block;
  background: #444;
  color: #fff;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: -8px auto 0;
  padding: 5px 8px;
  border-radius: 15px;
}

.affiliate_img {
  padding: 5rem;
}

.center-body {
  min-height: 500px;
}

.center-body_list {
  width: 750px;
  background-color: #fff;
  color: #555;
  margin-bottom: 30px;
}

.center-search {
  width: 750px;
  height: 130px;
  background-color: red;
}
.center-board_list {
  width: 750px;
  background-color: #fff;
  color: #333;
  font-size: 13px;
}

.children {
  width: 110%;
}

.login-page {
  width: 400px;
}

.login-page > div:first-child,
.login-page > div:nth-child(2) {
  width: 400px;
}

.mypage-body_table {
  width: 700px;
}

.mypage-body_table_list {
  width: 750px;
}

.order-Header__box,
.order-detail-header {
  width: 700px;
}
.my_page_nav_tit {
  position: absolute;
  bottom: 60px;
}

.goto_inventory_page {
  display: block;
  font-size: 13px;
  color: #ee3842;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;

  position: absolute;
  bottom: 20px;
}

/* 팝업 */
.popUp {
  position: absolute;
  z-index: 500;
  top: 10px;
  left: 10px;
}

.popUp_box {
  width: 100%;
  height: auto;
}

.popUp_close {
  position: absolute;
  top: 0;
  right: 0;

  width: 40px;
  height: 40px;
  background-color: #444;
  box-shadow: -1px 1px #ccd;
}

.popUp_close a {
  color: #fff;
  font-size: 19.5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 2px 2px 2px rgba(255, 255, 255, 0 0.15);
}

.popUp_txt {
  position: relative;
  display: flex;
  padding-left: 5px;
  background-color: #fff;
}

.popUp_txt input {
  width: 15px;
  height: 15px;
  display: block;
  margin-top: 5px;
}

.popUp_txt a {
  color: #222;
  margin-left: 5px;
  font-size: 15px;
  background-color: #fff;
}

.mallview_add_cart_back_order_btn {
  width: 95%;
  margin: 0 auto;
  background-color: #fff !important;
  color: #ee3842 !important;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.search-special {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #fff;
  background: #ee3842;
  padding: 0 5px;
  border-radius: 5px;
}

/* 대시보드 시작 */
.seller_menu {
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 50px;
}

.seller_menu > ul {
  display: flex;
}

.seller_menu > ul > li {
  border-top: 1px solid #3c464d !important;
  border-left: 1px solid #3c464d !important;
  border-right: 1px solid #3c464d !important;
  font-size: 15px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #3c464d !important;
  color: #fff !important;
}

.seller_menu > ul > li.active {
  border-top: 1px solid #fbeee6 !important;
  border-left: 1px solid #fbeee6 !important;
  border-right: 1px solid #fbeee6 !important;
  background-color: #fbeee6 !important;
  color: #3c464d !important;
  font-weight: bold;
}

.seller_menu > ul > li:first-child {
  margin-right: 2.5px;
}

#myChart {
  position: relative;
  height: 100% !important;
  width: 100% !important;
}

.seller_inventory_menu {
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid #fbeee6;
  border-top: 1px solid #fbeee6;
  margin-bottom: 20px;
}

.seller_inventory_menu li {
  padding: 0 10px;
}

.seller_inventory_menu li.active {
  font-weight: bold;
}

.seller_inventory_menu li:first-child {
  /* margin-right: 17.5px; */
}
/* 대시보드 끝 */

/* 재고관리 시작 */
.inventory_upload_btn {
  width: 100%;
  position: relative;
  height: 100px;
}

.inventory_upload_btn_lft {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 50px;
}

.inventory_upload_btn_lft .category-search {
  margin: 0;
}

.inventory_upload_btn_rt {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 50px;
  float: right;
}

.inventory_upload_btn_rt button,
.inventory_upload_btn_rt a {
  background-color: #fc8181;
  border: 1px solid #fc8181;
  color: #fff;
  padding: 5px;
  font-size: 13.5px;
  margin-left: 7.5px;
}

.inventory_upload_btn_rt a:hover,
.inventory_upload_btn_rt button:hover {
  background-color: #f56565;
}
/* 재고 등록 페이지 끝 */

.inventory_data_box {
  height: 100%;
  width: 100%;
  position: relative;
  margin: 10px 0;
}

.inventory_data_box_line {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.inventory_data_box_line > div {
  border: 1px solid #111;
  height: 400px;
  margin: 0 2% 10px;
  position: relative;
}

.inventory_data_box_line .inven_data_chk {
  position: absolute;
  top: 10px;
  left: 5px;
}
.inventory_data_box_line .inven_data_img {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 200px;
  border: 1px solid #bbb;
}

.inventory_data_box_line .inven_data_img img {
  height: 100%;
  width: auto;
  margin: 0 auto;
}
.inventory_data_box_line .inven_data_file {
  position: absolute;
  top: 250px;
  left: 10px;
  width: 90%;
}
.inventory_data_box_line .inven_data_itid {
  position: absolute;
  top: 280px;
  left: 10px;
  font-size: 14px;
  color: #bbb;
}
.inventory_data_box_line .inven_data_price {
  position: absolute;
  font-size: 15px;
  top: 300px;
  left: 10px;
  font-weight: bold;
}
.inventory_data_box_line .inven_data_manufac {
  position: absolute;
  top: 320px;
  left: 10px;
  font-size: 14px;
  color: #bbb;
}
.inventory_data_box_line .inven_data_stock {
  position: absolute;
  top: 350px;
  left: 10px;
  font-size: 16.5px;
}

.market-category-title .category-search {
  height: 35px;
  align-items: center;
}

.market-category-title .category-search > div {
  height: 35px;
}
.market-category-title h1 {
  width: 114px;
  height: 23px;
  background: url(/_next/static/media/marekt_place_title.7b15971d.png);
  display: block;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  margin: 20px auto 50px;
}
/* 재고관리 끝 */

/* 마켓플레이스 시작 */

.marketPlace_category {
  width: 100%;
  padding: 0 10px;
}

.marketPlace_box {
  margin-bottom: 50px;
}

.marketPlace_category > div:first-child > div {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.marketPlace_empty {
  height: 500px;
  position: relative;
}

.marketPlace_empty > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.marketPlace_empty img {
  margin: 0 auto 10px;
}

.marketPlace_category > div:nth-child(2) {
  margin: 50px 0;
}

.marketPlace_category > div:nth-child(2) > div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.marketPlace_category > div h3 {
  font-size: 15px;
  display: block;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
  color: #222;
  font-weight: bold;
}

.marketPlace_category > div {
  display: block;
  margin-top: 7.5px;
}

.marketPlace_category > div label {
  display: block;
}

.marketPlace_category > div label > span {
  font-size: 12.5px;
}

.marketPlace_category > div select {
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 5px;
}

.marketPlace_box .mallCategory-grid_product_link > a {
  width: 100% !important;
}

.mallCategory_info_right {
  width: 60px;
  height: 25px;
  font-size: 12px;
  background-color: rgb(62, 151, 255);
  color: rgb(255, 255, 255);
  border-radius: 5px;
  text-align: center;
  padding: 2.5px 5px;

  text-decoration: none !important;
}

.mallCategory_info_special {
  width: 60px;
  height: 25px;
  font-size: 12px !important;
  background-color: rgb(238, 56, 66);
  color: rgb(255, 255, 255) !important;
  border-radius: 5px;
  margin: 0 0 0 5px !important;
  padding: 2.5px 7.5px;
  text-decoration: none !important;
  margin: 0 auto;
}

.mallCategory_info_special_grid {
  width: 60px;
  height: 25px;
  font-size: 12px !important;
  background-color: rgb(238, 56, 66);
  color: rgb(255, 255, 255) !important;
  border-radius: 5px;
  margin-top: 5px !important;
}

.goto_marketplacestore {
  display: block;
  width: 200px;
  height: 50px;
  font-size: 16px;
  border: 1px solid #202e46;
  padding: 10px 0;
  margin: 20px auto 50px;
  border-radius: 5px;
  background-color: #202e46;
  color: #fff;
}

/* 서버 점검 팝업 시작 */
.popUp_title {
  width: 100%;
  height: 250px;
  /* background-color: #272953; */
  background-color: #ee3842;
  position: relative;
}

.popUp_title > div {
  background-color: #fff;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 30px;
  transform: translateX(-50%);
}

.popUp_title img {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popUp_title h1 {
  width: 100%;
  text-align: center;
  font-size: 30px;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 180px;
  transform: translateX(-50%);
  font-weight: bold;
  letter-spacing: 0.1em;
}

.popUp_content {
  background-color: #fff;
  height: 270px;
  width: 100%;
  position: relative;
  box-shadow: -1px 1px 1px #eee;
}

.popUp_content > div:first-child {
  font-size: 12.75px;
  text-align: center;
  padding: 25px 30px 15px;
  text-align: left;
}

.popUp_schedule {
  width: 95%;
  margin: 15px auto 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  border: 1px solid #d6dbdf;
  border-radius: 7.5px;
}

.popUp_content .popUp_schedule > div:first-child {
  padding: 20px 10px 5px;
  font-size: 15px;
  text-align: left;
}

.popUp_content .popUp_schedule > div:nth-child(2) {
  padding: 5px 10px 20px;
  font-size: 15px;
  text-align: left;
}

.popUp_schedule span {
  display: inline-block;
  margin-right: 5px;
  color: #fff;
  font-weight: bold;
  background-color: #fed7d7;
  border-radius: 25px;
  padding: 5px 15px;
}

/* 서버 점검 팝업 끝 */

/* 신규 마이페이지 시작 */
.mypage {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 800px;
  margin-bottom: 80px;
}

.mypage_tit h1 {
  font-size: 35px;
  text-align: center;
  margin: 50px auto 75px;
}

.mypage_menu {
  width: 100%;
  height: 180px;
  margin: 50px 0;
  position: relative;
}

.mypage_menu ul {
  width: 100%;
  height: 100%;
  position: relative;
}

.mypage_menu ul li {
  float: left;
  color: #363636;
  font-size: 13px;
  text-align: center;
  padding: 0 20px;
  letter-spacing: -0.07em;
  width: 25%;
  height: 20px;
  margin: 20px 0;
}

.mypage_menu_only_en ul li {
  width: 33.33%;
}

.mypage_menu ul li button,
.mypage_menu ul li a {
  position: relative;
}

.mypage_menu.service_menu ul li {
  width: 33.33%;
}

.mypage_menu ul li button.active,
.mypage_menu ul li a.active {
  color: #000;
  font-weight: 600;
  padding-bottom: 0.3125vw;
}
.mypage_content {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 10px;
}

.quote_content {
  max-width: unset;
}

.quote_list_detail
  table
  td
  input:not(.mypage_content table td input[type="radio"]) {
  width: unset !important;
  padding: 0 !important;
  border: unset !important;
  line-height: unset !important;
}

.quote_list_detail table th {
  text-align: left !important;
}

.mypage_content h1 {
  font-weight: 500;
  font-size: 1.5em;
  margin-bottom: 20px;
  padding-left: 10px;
  display: block;
  margin-bottom: 20px;
  display: block;
  font-size: 25px;
}

.mypage_menu ul li button.active::after,
.mypage_menu ul li a.active::after {
  background-color: #000;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

.mypage_content {
  width: 100%;
  margin: 50px auto 0;
}

.mypage_content p {
  text-align: left;
  font-size: 13.5px;
  color: #ee3842;
  margin-bottom: 20px;
  padding-left: 10px;
}

.mypage_content table {
  width: 100%;
  height: 100%;
  position: relative;
}
.mypage_content table th {
  text-align: left;
  border-bottom: 1px solid #dcdcdc;
  background-color: #fbfbfb;
  padding: 10px 25px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: #000;
  width: 40%;
}

.mypage_content table td {
  padding: 15px 0 15px 0px;
  border-bottom: 1px solid #dcdcdc;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: #000;
}

.mypage_content
  table
  td
  input:not(.mypage_content table td input[type="radio"]) {
  width: 380px;
  padding: 0 10px;
  border: 1px solid #d6d6d6;
  line-height: 30px;
}
.mypage_content table td:last-child label {
  margin-bottom: 5px;
  margin-right: 20px;
}

.mypage_content table tr:first-child {
  border-top: 1px solid #573838;
}

.mypage_info_body td input,
.mypage_info_body td label {
  margin-left: 15px;
}

.mypage_info_register {
  width: 150px;
  height: 60px;
  margin: 60px auto 0;
  border: #ee3842;
  color: #fff;
  background-color: #ee3842;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  display: block;
}

.mypage_content button:hover {
  font-weight: 600;
}

.mypage_order_head tr th {
  padding: 9px 10px 10px 10px;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  background-color: #f7f7f7;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: #000;
}

.mypage_order_body tr td:first-child {
  text-align: center;
  padding: 15px 10px 14px 10px;
  color: #777;
  border-right: 1px dotted #ededed;
  text-align: center;
}

.mypage_order_body tr td:first-child em,
.mypage_order_body tr td:first-child p,
.mypage_order_body tr td:first-child a {
  display: block;
  text-align: center;
  margin-bottom: 5px;
  color: #333;
}

.mypage_order_body tr td:first-child a {
  border-bottom: 1px solid #ee3842;
  color: #ee3842;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto 5px;
  font-weight: 600;
}

.mypage_order_body tr td span,
.mypage_order_body tr td a {
  display: block;
  text-align: center;
  margin-bottom: 5px;
}

.mypage_order_body tr td:nth-child(2) span {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  height: 120px;
}

.mypage_order_body tr td:nth-child(3) span,
.mypage_order_body tr td:nth-child(4) span,
.mypage_order_body tr td:nth-child(5) span {
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
}
.mypage_order_body tr td:nth-child(2) span:last-child,
.mypage_order_body tr td:nth-child(3) span:last-child,
.mypage_order_body tr td:nth-child(4) span:last-child,
.mypage_order_body tr td:nth-child(5) span:last-child {
  border-bottom: none;
}

.mypage_order_body tr td:nth-child(5) a {
  background-color: #ee3842;
  border: 1px solid #ee3842;
  height: 30px;
  width: 70px;
  padding: 5px 1px;
  color: #fff;
  font-weight: 600;
}

.mypage_order_search {
  width: 100%;
  height: 100%;
  border: 2px solid #f0f0f0;
  clear: both;
  position: relative;
  margin: 0 0 50px;
  padding: 10px 10px 10px 15px;
}

.mypage_order_search label {
  padding: 6px 15px 0 0;
  -moz-columns: #777;
       columns: #777;
  font-size: 15px;
  font-weight: 500;
}

.mypage_order_search input {
  width: 100%;
  padding: 0 10px;
  border: 1px solid #d6d6d6;
  line-height: 30px;
  margin-top: 15px;
}

.mypage_order_search button {
  width: 100%;
  height: 32px;
  font-weight: 400;
  font-size: 17px;
  color: #fff;
  margin-top: 20px;
  background-color: #ee3842;
}

.mypage_order_search button:hover {
  font-weight: 600;
}

.mypage_wish_btn button {
  padding: 3px 10px 4px 10px;
  color: #3e3d3c;
  border: 1px solid #ccc !important;
  text-align: center;
  background-color: #fff !important;
  margin-right: 7.5px;
}

.mypage_coupon_head th,
.mypage_coupon_body td {
  text-align: center !important;
}

.mypage_coupon_body td img {
  margin: 0 auto 10px;
}

.mypage_coupon_menu {
  clear: both;
}

.mypage_coupon_menu ul {
  width: 100%;
  height: 60px;
  margin: 0 auto 50px;
  float: none;
  border-bottom: 1px solid #dbdbdb;
}

.mypage_coupon_menu ul li {
  position: relative;
  width: 200px;
  font-size: 12px;
  text-align: center;
  z-index: 10;
  float: left;
}

.mypage_coupon_menu ul li button {
  margin: 0 0 0 -1px;
  padding: 10px 20px 12px 20px;
  color: #999;
  font-size: 14px;
  border: 1px solid #dadada;
  background-color: #fff;
  width: 200px;
  margin-top: 15px;
}

.mypage_coupon_menu ul li button.active {
  padding-bottom: 16px;
  margin-top: 0;
  color: #333;
  font-weight: 600;
  border: 1px solid #dbdbdb !important;
  border-bottom: none;
  height: 60px;
}

.mypage_coupon_body.body_empty td {
  padding: 50px 0;
}

.mypage_point_view {
  clear: both;
  border: 2px solid #f0f0f0;
  margin: 0 auto 50px;
  width: 95%;
}
.mypage_point_view ul {
  height: 150px;
  width: 100%;
  padding: 20px 10px;
}

.mypage_point_view ul li {
  float: left;
  height: 95%;
  width: 50%;
  text-align: center;
  border-right: 1px solid #f0f0f0;
}

.mypage_point_view ul li:last-child {
  border-right: none;
}

.mypage_point_view ul li p {
  display: block;
  font-size: 15px;
  color: #ee3842;
  font-weight: 600;
}

.mypage_point_view ul li img,
.mypage_point_view ul li p,
.mypage_point_view ul li span {
  text-align: center;
  margin: 0 auto 5px;
}

.mypage_wish_body table tbody td:first-child input {
  width: unset;
}

.mypage_wish_head th,
.mypage_wish_body td {
  text-align: center !important;
}

.mypage_wish_body td img {
  margin: 0 auto;
}

.mypage_address_btn {
  margin-bottom: 20px;
}

.mypage_address_btn:hover {
  font-weight: bold !important;
  cursor: pointer;
}

.mypage_pagination {
  width: 100%;
  position: relative;
  margin: 50px auto;
}

.mypage_home_body > div > a {
  display: block;
  float: right;
  color: #fff;
  background-color: #111;
  font-weight: 400;
  padding: 2.5px 10px;
}

.mypage_gotoseller {
  display: block;
  float: right;
  color: #fff;
  background-color: #111;
  font-weight: 400;
  padding: 2.5px 10px;
  float: right;
}

.mypage_home > a:hover,
.mypage_gotoseller a:hover {
  font-weight: 600;
}

.mypage_home_body {
  margin-bottom: 90px;
  clear: both;
}

.mypage_home_body h3 {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
}

.mypage_home_body h3 span {
  padding-left: 5px;
  color: #777;
  font-size: 12px;
  font-weight: 500;
}

.mypage_home_order_info {
  margin-bottom: 35px;
  padding: 35px 0;
  border: 1px solid #f0f0f0;
  border-top: 1px solid #dbdbdb;
  text-align: center;
}

.mypage_home_order_info > ul {
  display: inline-block;
  width: 95%;
  vertical-align: middle;
  margin-left: 5%;
}

.mypage_home_order_info > ul li {
  display: inline-block;
  width: 25%;
  min-width: 66px;
  font-size: 13px;
  text-align: center;
}

.mypage_home_order_info > ul li b {
  display: block;
  padding: 0 40px 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.mypage_home_order_info > ul li strong {
  display: inline-block;
  width: 52px;
  height: 52px;
  padding: 0;
  line-height: 52px;
  color: #ffff;
  font-size: 18px;
  text-align: center;
  vertical-align: middle;
  background-color: #ccc;
  border-radius: 50%;
}

.mypage_home_order_info > ul li i {
  font-size: 20px;
  font-weight: 600;
  padding: 10px 0 0 20px;
}

.mypage_home_order_info > ul li:last-child i {
  visibility: hidden;
}

.mypage_home_order_info > ul li b.present {
  font-weight: 600;
}

.mypage_home_order_info > ul li strong.present {
  background-color: #666;
}

.mypage_home_order_cancel {
  display: inline-block;
  width: 19%;
  text-align: center;
  vertical-align: middle;
}

.mypage_home_order_cancel ul {
  display: inline-block;
  width: 70%;
}

.mypage_home_order_cancel ul li {
  padding: 8px 5px 10px 5px;
  font-size: 12px;
  text-align: left;
  font-weight: 400;
  border-bottom: 1px dotted #bfbfbf;
}

.mypage_home_order_cancel ul li strong {
  float: right;
}

.mypage_home_body h3 {
  margin-bottom: 10px;
}

.mypage_home_order_recent th {
  text-align: center !important;
}

.mypage_home_order_recent tbody tr:first-child td {
  border-bottom: none !important;
}

.mypage_home_order_recent tbody tr td:first-child {
  padding: 15px 30px;
}

.mypage_home_order_recent
  tbody
  tr
  td
  span:not(.mypage_home_order_recent tbody tr td:first-child span) {
  text-align: center;
}

.mypage_home_order_recent tbody tr td span {
  display: block;
  margin: 5px 0;
}

.mypage_home_order_recent tbody tr td span,
.mypage_home_order_recent tbody tr td span strong,
.mypage_home_order_recent tbody tr td span b {
  font-weight: 400 !important;
}

.mypage_home_order_recent tbody tr td span strong {
  margin-right: 5px;
}

.mypage_home_personal {
  border-right: 1px solid #eee;
  padding: 20px 0;
}

.mypage_home_order_recent tbody tr td:nth-child(5) span {
  margin: 10px 0;
}

.mypage_home_recent_od_number {
  border-bottom: 1px solid #ee3842;
  color: #ee3842;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto 5px;
  font-weight: 600;
}

.mypage_home_order_tracking {
  background-color: #ee3842;
  border: 1px solid #ee3842;
  padding: 2px 5px;
  color: #fff;
  font-weight: 600;
}

.mypage_home_more_btn {
  float: right;
}

.mypage_homer_quote_recent,
.mypage_homer_point_recent {
  margin-top: 50px;
}

.mypage_homer_quote_recent tbody,
.mypage_homer_point_recent tbody {
  border-bottom: 1px solid #dedede;
}

.mypage_homer_quote_recent tbody td,
.mypage_homer_point_recent tbody td {
  text-align: center;
  height: 50px;
}

.mypage_home_order_empty {
  height: 270px;
  border-bottom: 1px solid #dcdcdc;
}

.mypage_home_order_empty td {
  text-align: center;
}

.mypage_order_detail table {
  margin-top: 75px;
}

.mypage_order_detail table td span {
  margin-left: 20px;
}

.mypage_order_code_btn {
  margin-top: 20px;
  margin-left: 20px;
}

.mypage_order_detail_body td {
  padding: 0 !important;
}

.mypage_order_detail_body tr {
  height: 50px !important;
}

.mypage_order_detail_body td span {
  text-align: center;
  display: block !important;
  height: unset !important;
}

.mypage_order_goback_list {
  width: 175px;
  margin: 60px auto 0;
  border: #ee3842;
  color: #fff;
  background-color: #ee3842;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  display: block;
  text-align: center;
  padding: 20px 0;
}

.mypage_order_goback_list:hover {
  font-weight: 600;
}

.mypage_menu_only {
  height: 120px;
}

.mypage_menu_only ul li:last-child,
.mypage_menu_only ul li:nth-child(6),
.mypage_menu_only ul li:nth-child(5),
.mypage_menu_only ul li:nth-child(4) {
  width: 25%;
}

.mypage_info_body_modify {
  display: table;
  width: 100%;
  padding: 5px 0 0 0;
}

.mypage_info_body_modify_title {
  display: table-cell;
  width: 27%;
  min-width: 80px;
  padding-top: 20px;
  vertical-align: middle;
}

.mypage_info_body_modify_content {
  display: table-cell;
  width: 73%;
  vertical-align: middle;
  padding-top: 20px;
}

.mypage_info_body_modify_content input {
  width: 100%;
  padding: 0 10px;
  border: 1px solid #d6d6d6;
  line-height: 30px;
}

.mypage_info_body_modify:last-child .mypage_info_body_modify_content label {
  margin-right: 10px;
}

.mypage_order_body_m {
  border: 1px solid #f0f0f0;
  margin-bottom: 25px;
  padding: 10px 0;
  background-color: #fff;
}

.mypage_info_body {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 10px 0 30px 0;
}

.mypage_order_list {
  padding: 10px;
  font-size: 15px;
  line-height: 30px;
}

.mypage_order_list em,
.mypage_order_list_goods em {
  display: block;
  width: 100%;
  height: 1px;
  margin-top: 15px;
  border-bottom: 1px solid #f0f0f0;
}

.mypage_order_list > div span:first-child {
  display: inline-block;
  margin-right: 10px;
}

.mypage_order_list > div a {
  border-bottom: 1px solid #ee3842;
  color: #ee3842;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto 5px;
  font-weight: 600;
}

.mypage_order_list_goods {
  margin-top: 20px;
  padding: 10px;
}

.mypage_order_list_goods_img {
  position: relative;
  width: 100%;
  height: 80px;
}

.mypage_order_list_goods_img img {
  display: table-cell;
  width: 20%;
  padding-bottom: 5px;
  float: left;
}

.mypage_order_list_goods_img > div {
  display: table-cell;
  width: 80%;
  padding: 0 10px;
  float: right;
}

.mypage_order_list_goods_img > div span {
  display: block;
  font-size: 12.5px;
  margin-top: 5px;
}

.mypage_order_list_goods_img > div span:first-child {
  font-weight: 600;
}
.mypage_order_list_goods_chase {
  position: relative;
  width: 100%;
  height: 30px;
}

.mypage_order_items_trace {
  background-color: #ee3842;
  border: 1px solid #ee3842;
  height: 30px;
  width: 70px;
  display: block;
  padding: 5px 7px;
  color: #fff;
  font-weight: 400;
  margin-top: 10px;
  float: right;
  text-align: center;
}

.mypage_order_items_trace:hover {
  font-weight: 600;
}

.mypage_order_items_trace.disabled {
  background-color: #ccc; /* 비활성화 시의 배경색 */
  border: 1px solid #ccc; /* 비활성화 시의 테두리 색 */
  color: #999; /* 비활성화 시의 글자 색 */
  cursor: not-allowed; /* 비활성화 시 마우스 커서 */
  font-weight: 400; /* 기본 글자 굵기 유지 */
}

.mypage_order_items_trace.disabled:hover {
  font-weight: 400; /* 비활성화 상태에서는 hover 효과 적용 안 함 */
}

.mypage_address_body_m {
  position: relative;
  width: 100%;
  height: 100%;
}

.mypage_address_list {
  width: 100%;
  height: 100%;
  position: relative;
}

.mypage_address_title {
  background-color: #f2f3f3;
  padding: 10px;
  font-size: 13.5px;
  font-weight: 600;
}

.mypage_address_title span {
  display: inline-block;
}

.mypage_address_base {
  display: inline-block;
  border: 1px solid #ea2f3c;
  padding: 5px 10px;
  color: #ea2f3c;
  margin-right: 10px;
}

.mypage_wish_body_m > div:not(.mypage_wish_title) {
  padding: 5px 10px;
  font-size: 13px;
}

.mypage_address_detail span {
  display: block;
  margin-top: 5px;
}

.mypage_address_detail,
.mypage_address_info {
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 5px 15px 10px !important;
}

.mypage_address_info > div {
  width: 100%;
  height: 20px;
  margin-top: 10px;
}
.mypage_address_info span:first-child {
  float: left;
}

.mypage_address_info span:nth-child(2) {
  float: right;
}

.mypage_address_status_btn {
  margin-top: 10px;
  height: 50px;
}
.mypage_address_status_btn > div {
  float: right;
}

.mypage_empty {
  /* border-top: 1px solid #f0f0f0; */
  padding: 61px 0;
  /* border-bottom: 1px solid #f0f0f0; */
  text-align: center !important;
  width: 100%;
  height: 150px;
}

.mypage_empty span {
  float: unset !important;
}

.mypage_point_list > div span:last-child {
  float: right;
}

.mypage_coupon_body_m > img {
  display: block;
  margin: 10px 0 0 10px;
}

.mypage_wish_top {
  position: relative;
  width: 100%;
  height: 40px;
}

.mypage_wish_top div:first-child {
  float: left;
}

.mypage_wish_top div:first-child label {
  margin-left: 7.5px;
}

.mypage_wish_top div:nth-child(2) {
  float: right;
}

.mypage_list_check {
  padding: 10px;
  font-size: 15px;
  line-height: 30px;
}

.mypage_list_check b {
  font-weight: 400 !important;
  margin-left: 7.5px;
}

.mypage_list_name > div {
  float: right;
}

.mypage_list_name > div img {
  float: left;
}

.mypage_list_wrap > div {
  height: 50px;
}

/* 신규 마이페이지 끝 */

/* 신규 견적 관려 시작 */
.quote_list_body {
  text-align: center;
}

.quote_finish {
  color: #999 !important;
}

.quote_start {
  font-weight: 600;
  color: #ee3842 !important;
}

.quote_list_body td:nth-child(2) a {
  color: #111;
  border-bottom: 1px solid #111;
  padding-bottom: 1.45px;
  font-weight: 600;
}

.quote_list_body td:nth-child(2) a:hover {
  font-weight: 700;
}

.quote_list_detail .center-body {
  min-height: 350px;
}
.quote_request_body .center-body {
  min-height: 450px;
}

.quote_list_detail .center-body > button {
  width: 50px;
  height: 32px;
  font-weight: 400;
  color: #fff;
  margin-top: 10px;
  background-color: #ee3842;
  float: right;
}

.quote_list_detail .center-body > button:hover {
  font-weight: 600;
}
.quote_list_detail_body {
  font-size: 12.5px !important;
}

.quote_list_detail_body td input {
  margin-left: 10px;
}

.quote_list_detail_body td span {
  margin-left: 20px;
}

.quote_list_detail_body td span.quote_total {
  color: #ee3842;
  font-weight: 600;
  margin-left: 5px;
}

.quote_cart {
  margin-top: 50px;
}

.quote_list_detail_body th i {
  display: inline-block;
  margin-left: 5px;
  color: #ee3842;
}

.quote_request_file_body {
  margin-top: 30px;
}

.quote_list_detail_body .easy-edit-wrapper {
  margin-left: 20px;
}

.quote_list_detail_body .easy-edit-wrapper .font-weight-bold {
  margin-left: 0 !important;
}

.quote_request_file_body .dropzone {
  height: 350px;
  display: block;
  text-align: center;
  padding: 150px 0;
}

.quote_request_body .estimate-request__btn {
  background-color: #111 !important;
  border: none;
  border-radius: 0;
}

.quote_request_file_body .dropzone p {
  font-size: 15px;
}

.quote_request_file_checkList {
  float: right;
  display: flex;
  padding: 15px 0;
  width: 100%;
  justify-content: flex-end;
}

.quote_request_file_ask_btn {
  width: 100%;
  margin-bottom: 50px;
}

.quote_request_file_ask_btn button,
.quote_request_ask_btn {
  width: 150px;
  height: 60px;
  margin: 60px auto 0;
  border: #ee3842;
  color: #fff;
  background-color: #ee3842;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  display: block;
  border-radius: 0;
}

.quote_request_ask_btn {
  width: 150px !important;
  height: 60px !important;
  margin: 60px auto !important;
  border: #ee3842 !important;
  color: #fff !important;
  background-color: #ee3842 !important;
  cursor: pointer !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  display: block !important;
  border-radius: 0 !important;
}

.quote_request_file_ask_btn button:hover,
.quote_request_ask_btn:hover {
  font-weight: 600 !important;
  border: #ee3842 !important;
  color: #fff !important;
  background-color: #ee3842 !important;
}

.quote_request_file_template {
  margin-bottom: 10px;
}

.quote_request_file_template button {
  border-radius: 0 !important;
  font-weight: 400 !important;
}

.quote_request_file_template button:hover,
.quote_request_file_how:hover {
  font-weight: 600 !important;
}

.quote_request_file_how {
  border-radius: 0 !important;
  font-weight: 400 !important;
  float: right;
  background-color: #111 !important;
  color: #fff !important;
  margin-bottom: 10px !important;
}

.quote_request_file_how_bx {
  position: relative;
  width: 100%;
}

.quote_request_file_how_manual {
  position: absolute;
  border-radius: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  right: -50px;
  top: 10px;
}

.quote_manual_close {
  float: right;
  font-size: 15px;
  margin-bottom: 20px;
  display: block;
  cursor: pointer;
}

.quote_request_agree {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 20px auto 0;
}

.quote_request_agree input {
  float: left;
}

.quote_request_agree p {
  float: left;
  margin-left: 10px;
  margin-top: -3px;
}

.quote_request_body .jexcel td {
  background-color: #fbfbfb !important;
  /* padding: 4px !important; */
}

.quote_request_body .jexcel tbody td:nth-child(2),
.quote_request_body .jexcel tbody td:nth-child(3),
.quote_request_body .jexcel tbody td:nth-child(4) {
  background-color: #fbfbfb !important;
}

.quote_request_body .jexcel tbody td:nth-child(5) {
  background-color: #fff !important;
}

.quote_request_body
  .jexcel
  thead
  tr:first-child
  td:not(.quote_request_body .jexcel tr:first-child td:last-child) {
  color: #ea2f3c;
  font-weight: 600;
  padding: 5px 0;
}

.quote_request_body th span {
  font-size: 6.5px;
  color: #ea2f3c;
  margin-right: 10px;
  display: inline-block;
}

.quote_request_body .mypage_info_body tr:nth-child(4) th span {
  visibility: hidden;
}

.quote_body {
  width: 100%;
  height: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

.quote_body > div {
  width: 50%;
  height: 100%;
  position: relative;
  float: left;
  margin: 0 auto;
}
.quote_body_register_img,
.quote_body_register_ex {
  position: relative;
  height: 100%;
}

.quote_body_register_img img,
.quote_body_register_ex > div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.quote_body_register_img img {
  margin-right: 5%;
}
.quote_body_register_ex > div {
  margin-left: 5%;
}

.quote_body_register_ex > div > span {
  display: block;
  margin: 20px auto;
  text-align: center;
  font-size: 17.5px;
  color: #ccc;
}

.quote_body_register_ex > div > p {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: left !important;
  color: #000;
}

.quote_body_register_ex > div > p > span {
  display: block;
}

.quote_body_register_ex > div > p > span:first-child {
  font-weight: 600;
  font-size: 45px;
  display: block;
  margin-bottom: 15px;
  color: #000;
}

.quote_body_register_ex > div > p > span:nth-child(2),
.quote_body_register_ex > div > p > span:nth-child(3) {
  font-size: 35px;
  color: #222;
  display: block;
}

.quote_body_register_ex > div > p > span:nth-child(4) {
  margin-top: 15px;
}
.quote_body_register_ex > div > p > span:nth-child(4),
.quote_body_register_ex > div > p > span:nth-child(5) {
  margin-bottom: 5px;
  color: #555;
  font-size: 15.5px;
}

.quote_body img {
  display: block;
  margin: 20px auto 50px;
}

.quote_body > em {
  width: 100%;
  height: 1px;
  border: 1px solid #dcdcdc;
  display: block;
}

.quote_body_btn {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 20px auto 50px;
}

.quote_body_register_img img {
  margin: 0 !important;
}

.quote_center_body {
  height: 600px;
  background-color: rgb(250, 250, 250);
}

.quote_body_btn a:first-child {
  padding: 10px 20px;
  border: 1px solid #111;
  background-color: #111;
  display: block;
  color: #fff;
  float: left;
  margin-right: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}
.quote_body_btn a:nth-child(2) {
  padding: 10px 20px;
  border: 1px solid #ee3842;
  background-color: #ee3842;
  display: block;
  color: #fff;
  float: left;
  margin-left: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
}

.quote_body_btn a strong {
  font-size: 11.5px;
  display: inline-block;
  margin-top: 10px;
  margin-left: 10px;
}

.quote_body_btn a:hover {
  font-weight: 600;
  font-size: 20px;
}

.quote_list_detail_body th,
.quote_list_detail_body td {
  font-size: 14.5px !important;
}

.quote_list_detail_body tr:nth-of-type(2) th,
.quote_list_detail_body tr:nth-of-type(3) th,
.quote_list_detail_body tr:nth-of-type(4) th {
  font-weight: 600;
}

/* 신규 견적 관려 끝 */

/* 신규 고객센터 시작 */
.service_content {
  width: 100%;
  max-width: 1000px;
  margin: 50px auto 90px;
}

.service_qna_head {
  border-top: 1px solid #dcdcdc;
}

.service_content h1 {
  font-weight: 500;
  font-size: 1.5em;
  float: left;
  margin-bottom: 20px;
}

.service_qna_head tr:first-child {
  border-top: none !important;
}

.service_qna_head tr th {
  text-align: center;
}
.service_qna_body {
  text-align: center;
}

.service_qna_body tr td:first-child {
  text-align: left;
  padding-left: 10px;
}

.service_qna_body tr td:last-child span {
  /* border: #ee3842 !important;
  color: #fff !important;
  background-color: #ee3842 !important; */
  border: #111 !important;
  color: #fff !important;
  background-color: #111 !important;
  font-size: 14px !important;
  border-radius: 0 !important;
  padding: 5px 10px;
}

.service_qna_body tr td:last-child span.clear {
  border: 1px solid #ccc !important;
  color: #3e3d3c !important;
  background-color: #fff !important;
}

.service_faq_body {
  width: 100%;
  clear: both;
  display: block;
  margin-top: 50px;
}

.service_faq_q {
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
}

.service_content .css-17mg6aq {
  border-top: none !important;
  border-bottom: none !important;
}

.service_faq_body h2 {
  color: #333;
  font-size: 13px;
}

.service_faq_q {
  background-color: #fbfbfb;
}

.service_faq_a > div {
  background-color: #fff !important;
  padding: 30px 10px 30px;
  line-height: 1.5;
}
.service_faq_a > div p {
  display: block;
  margin-bottom: 15px;
}

.service_notice_body td {
  text-align: center;
}

.service_view_body {
  clear: both;
}

.service_view_body h3 {
  overflow: hidden;
  border-top: 2px solid #000000;
  border-bottom: 1px solid #000000;
  background: #fff;
  padding: 16px 0 17px 0;
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  vertical-align: top;
  line-height: 1.4;
}

.service_view_body_info {
  overflow: hidden;
  padding: 20px 0px 20px 0px;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  clear: both;
}

.service_view_body_info span:first-child {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  font-size: 15px;
}
.service_view_body_info span:nth-child(2) {
  display: inline-block;
  height: 10px;
  margin: 0 0 0 7px;
  color: #c4c4c4;
  font-size: 15px;
  font-weight: 400;
  vertical-align: top;
}
.service_view_body_info span:nth-child(3) {
  display: inline-block;
  float: right;
  text-align: right;
  color: #c4c4c4;
  font-size: 15px;
  letter-spacing: -0.03em;
}

.service_view_body_cont {
  min-height: 300px;
  padding: 30px 15px 30px 15px;
  line-height: 1.8;
  border-bottom: 1px solid #f0f0f0;
  font-size: 16px;
}

.service_view_body_cont p {
  color: #333 !important;
}

.service_view_notice_del {
  margin: 10px 0 0 0;
  float: left;
  height: 45px;
  line-height: 43px;
  margin: 20px 0 0 5px;
  width: 110px;
  color: #fff;
  border: 1px solid #f7323f;
  text-align: center;
  font-size: 15px;
  background: #f7323f;
}
.service_view_notice_edit {
  margin: 10px 0 0 0;
  float: left;
  height: 45px;
  line-height: 43px;
  margin: 20px 0 0 5px;
  width: 110px;
  color: #fff;
  border: 1px solid #000000;
  text-align: center;
  font-size: 15px;
  background: #000000;
}

.service_view_body_list {
  margin: 10px 0 0 0;
  float: right;
  height: 45px;
  line-height: 43px;
  margin: 20px 0 0 5px;
  width: 110px;
  color: #fff;
  border: 1px solid #000000;
  text-align: center;
  font-size: 15px;
  background: #000000;
}

.service_view_notice_del:hover,
.service_view_notice_edit:hover,
.service_view_body_list:hover {
  font-weight: 600;
}

.service_qna_write_title {
  border: 1px solid #111;
  width: 100%;
  padding: 5px 10px;
}

.service_qna_write_content {
  border: 1px solid #111;
  width: 100%;
  padding: 5px 10px;
  min-height: 500px;
}

.notice_edit textarea {
  margin-top: 30px;
  padding: 10px;
  width: 100%;
  border: 1px solid #f0f0f0;
  font-size: 16px;
}

.notice_edit > div {
  margin-bottom: 20px;
  float: right;
}
.service_view_body_qna_info > div {
  overflow: hidden;
  padding: 20px 0px 20px 0px;
  border-bottom: 1px solid #f0f0f0;
}

.service_view_body_date {
  margin-top: 20px;
}

.service_qna_write_submit {
  width: 150px !important;
  height: 60px !important;
  margin: 60px auto !important;
  border: #ee3842 !important;
  color: #fff !important;
  background-color: #ee3842 !important;
  cursor: pointer !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  display: block !important;
  border-radius: 0 !important;
}

.service_qna_write_submit:hover,
.service_qna_write_btn:hover {
  font-weight: 600 !important;
}

.service_qna_write_btn {
  border: #ee3842 !important;
  float: right;
  padding: 10px 30px;
  margin-bottom: 20px;
  margin-right: 10px;
  color: #fff !important;
  background-color: #ee3842 !important;
  cursor: pointer !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  display: block !important;
  border-radius: 0 !important;
}

.service_review_item_link {
  color: #3182ce;
}
.service_review_item_link:hover {
  font-weight: 600;
}

.service_review_box {
  border: 1px solid #f0f0f0;
  margin: 10px 0;
}

.service_review_box p {
  color: #111;
  font-size: 15px;
  text-align: left;
}

.service_review_box p > span:first-child {
  color: #abb2b9;
  width: 50px;
  display: inline-block;
  margin-left: 10px;
}

.service_review_box p:nth-child(4) > span:last-child {
  display: block;
  margin: 10px 0 0 10px;
}

.service_review_box p > a {
  display: inline-block;
  margin-right: 10px;
}
/* 신규 고객센터 끝 */

/* 영문판 시작 */
.header-english {
  border: 1px solid #111;
  width: 100%;
  height: 50px;
  border-radius: 50px;
  padding: 5px 20px;
  color: #333;
}

.menu-bar-english li:nth-child(4) a {
  padding: 17px 0 !important;
}

.header-english button svg {
  color: #111;
}

.category-bar-english > div {
  width: 100%;
  height: 50px;
  position: relative;
}

.category-bar-english div.header-link {
  display: block !important;
  float: left;
  width: 50% !important;
  text-align: center;
  margin-right: 0 !important;
  padding: 15px 0 !important;
}

.header-english div input {
  max-width: 350px;
  border: none !important;
}
.category_square_words {
  width: 50%;
}

.category_grid_words {
  width: 100%;
}

.mall_proceed {
  text-align: center;
  font-size: 10px !important;
}

.english-top-bar {
  justify-content: unset !important;
}

.empty_page {
  display: block;
  margin: 10px auto 0;
  color: #fff;
  background-color: orange;
  padding: 5px 0;
  width: 125px;
  font-weight: 500;
}

.top-bar_transfer_lang {
  position: relative;
}

.top-bar_transfer_lang ul {
  position: absolute;
  z-index: 9999;
  background-color: #fff;
  color: #222;
  padding: 4px 0;
  box-shadow: 1px 1px 3px rgb(39, 55, 70);
  top: 50%;
  right: 50%;
  transform: translate(50%, 50%);
}

.top-bar_transfer_lang ul li {
  width: 150px;
  height: 30px;
  padding: 4px 0;
  text-align: center;
  cursor: pointer;
}

.top-bar_transfer_lang ul li span {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
}

.top-bar_transfer_lang ul li span:first-child {
  width: 20px;
  margin-right: 5px;
}

.top-bar_transfer_lang ul li span:nth-child(2) {
  width: 10px;
  margin-right: 5px;
}

.top-bar_transfer_lang ul li span:nth-child(3) {
  width: 52px;
  margin-right: 5px;
}
.top-bar_transfer_lang ul li span:nth-child(4) {
  width: 5px;
}

.top-bar_transfer_lang ul li:last-child span:nth-child(4) {
  visibility: hidden;
}

/* 영문판 완성 */

.customer_feedback_box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  transition: 0.15s;
}

.customer_feedback_bg {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}

.customer_feedback_box .customer_feedback_content {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}

.customer_feedback_box .customer_feedback_content h1 {
  text-align: center;
  padding: 20px 0 10px;
  font-size: 25px;
}

.customer_feedback_box .customer_feedback_content h3 {
  text-align: center;
  padding-bottom: 20px;
}

.service_view_body span {
  display: block;
  margin-bottom: 10px;
  font-size: 15px;
}

.service_view_body span b {
  font-size: 6.5px;
  color: #ea2f3c;
  margin-right: 10px;
  display: inline-block;
}

.customer_feedback_cont_title {
  margin-top: 30px;
}

.customer_feedback_box .center-body {
  background-color: #fff;
  border-radius: 5px;
}

.customer_feedback_box .center-body .service_view_body {
  padding: 0 20px;
}

.customer_feedback_box .service_view_body_cont {
  min-height: 200px;
  padding: 0 0 30px;
  line-height: 1.8;
  border-bottom: 1px solid #f0f0f0;
}

.customer_feedback_box .service_view_body_cont .service_qna_write_content {
  min-height: 300px;
}

.customer_feedback_btn {
  width: 100%;
  height: 60px;
  position: relative;
}

.customer_feedback_btn > div {
  margin: 0 auto;
  width: 210px;
}

.customer_feedback_chk_agree {
  width: 100%;
  height: 50px;
  margin: 20px 0;
}

.customer_feedback_chk_agree > div {
  float: right;
}

.customer_feedback_submit {
  width: 100px;
  height: 50px;
  float: left;

  cursor: pointer !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  display: block !important;
  border-radius: 0 !important;
}

.customer_feedback_submit:first-child {
  margin-right: 5px;
  border: #ee3842;
  color: #fff;
  background-color: #ee3842;
}

.customer_feedback_submit:nth-child(2) {
  margin-left: 5px;
  border: #111;
  color: #fff;
  background-color: #111;
}

/* 기본 및 tablet 가로 */
@media (max-width: 1024px) {
  .top-bar {
    width: 100%;
  }
  .search-gnb {
    width: 100%;
  }

  .maxist_body div {
    width: 100%;
  }

  .maxist_body > div > div p {
    width: 100%;
  }

  .menu_category {
    width: 100%;
  }

  .menu_category_major {
    width: 100%;
  }

  .base_container {
    width: 100%;
  }

  .affiliate_img {
    padding: 2.5rem;
  }

  .center-body,
  .parent {
    display: block !important;
  }

  .center-body {
    width: 100%;
  }

  .parent {
    width: 100% !important;
    background-color: transparent !important;
    height: 150px;
    margin-bottom: 20px;
  }

  .parent h4 {
    float: left;
    width: 30%;
    background-color: #ee3842;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10%;
  }

  .center-nav {
    position: relative;
    float: left;
    top: 0;
    width: 70%;
    height: 100%;
    display: block;
  }

  .center-nav > div {
    display: block !important;
    width: 100%;
    height: 100%;
  }

  .center-nav > div span.cursor {
    display: block;
    width: 45%;
    float: left;
    margin: 1% 1%;
  }

  .center-body_list {
    width: 100%;
  }

  .center-search {
    width: 100%;
  }

  .center-board_list {
    width: 100%;
  }

  .view-box {
    width: 97.5%;
    margin: 0 auto;
  }

  .center-board_list .flex-between {
    width: 100% !important;
  }

  .center-board_list_detail {
    width: 100%;
  }

  .order-body_list_wrap {
    overflow-x: scroll;
  }

  .order-body_list {
    width: 150%;
  }

  .children {
    width: 100%;
  }
}

/* tablet 세로 */
@media (max-width: 768px) {
  .scroll_container {
    bottom: 10%;
    transform: translate(5%, 10%);
  }

  /* .main_new_product .swiper .swiper-button-next,
  .main_new_product .swiper .swiper-button-prev {
    top: 10%;
  } */

  .header .flex-between {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
  .header .flex-between > div:not(last-child) {
    display: none;
  }

  .header .flex-between > div.logIn {
    display: block;
    margin-right: 10px;
  }

  .menu_category {
    min-height: none;
  }

  .menu_category_major {
    width: 100%;
    clear: both;
  }

  .menu_category_middle {
    clear: both;
    border-top: 1px solid #ddd;
    width: 95% !important;
    margin: 0 auto;
  }
  /* 
  .menu_category_minor {
    width: 50%;
    float: left;
  } */

  .menu_category_minor-child {
    width: 100%;
  }

  .menu-bar {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 72px;
    z-index: 500;
    background-color: rgba(0, 0, 0);
  }

  .menu-bar ul {
    display: flex;
    flex-direction: row;
  }

  .menu-bar ul li {
    flex: 1 1;
    text-align: center;
  }

  .menu-bar ul li a,
  .menu-bar ul li button {
    display: block;
    color: #fff;
    font-size: 15px;
    padding: 17px 0;
  }

  .menu-bar ul li.buyer-list a,
  .menu-bar ul li.buyer-list button,
  .menu-bar ul li.estimate-cart a,
  .menu-bar ul li:nth-child(4) a {
    padding: 6px 0;
  }

  .menu-bar ul li a span,
  .menu-bar ul li button span {
    display: block;
    margin-right: 5px;
    font-size: 15px;
  }

  .menu-bar ul li button {
    display: inline-block;
  }

  .maxist_body .footer_menu .footer_menu_list {
    height: 20px;
    margin: 10px 0;
    justify-content: center;
  }

  .maxist_body .footer_menu .footer_menu_list a {
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    float: left;
    -webkit-margin-start: none;
    margin-inline-start: none;
    padding: none;
    font-size: 12px;
  }

  .maxist_body > div > div:first-child {
    display: block;
  }

  .maxist_body > div > div:first-child > div {
    padding: 2.5rem 20px;
  }

  .maxist_body > div > div:last-child p {
    text-align: center;
    font-size: 12px;
    padding-top: 13px;
    margin-bottom: 13px;
  }

  .category_view_list {
    width: 100%;
    border: none;
  }

  .category_view_list .swiper {
    width: 100%;
    border: 1px solid #e2e8f0;
    margin: 0 auto;
  }

  .search-list__content > div {
    display: flex !important;
    justify-content: flex-start !important;
  }

  .search-list__header__box {
    width: 150% !important;
  }

  .search-list__radio {
    width: 100% !important;
  }

  .order-payment_btn {
    display: none !important;
  }

  .order-payment_btn_mobile {
    display: flex !important;
    margin-top: 20px !important;
  }

  .introLeft {
    width: 100%;
    height: 50%;
  }

  .introLeft_ex {
    position: relative;
    top: unset;
    left: unset;
    transform: none;
    width: unset;
    padding-top: 7.5%;
  }

  .intro_id {
    display: block;
  }

  .introLeft_ex > div {
    margin: 0 auto;
  }
  .introRight {
    height: 45%;
  }

  .introRight img {
    height: 100%;
    margin: 0 auto;
  }

  .panda-intro_estimate_wrap_ex h4,
  .panda-intro_search_tp > h4,
  .introLeft_ex h4 {
    font-size: 30px;
    text-align: center;
  }

  .panda-intro_estimate_wrap_ex span,
  .panda-intro_search_tp > span,
  .introLeft_ex > span {
    font-size: 14.5px;
    text-align: center;
  }
  .panda-intro_estimate_wrap_ex a {
    font-size: 15px;
    padding: 15px 50px;
  }

  .panda-intro_search_tp {
    width: 100%;
  }

  .introLeft_ex > div p {
    font-size: 13.5px;
  }
  .introLeft_ex > div {
    margin-bottom: 10px;
  }

  .panda-intro_search_tp > div > p {
    font-size: 13.5px;
  }

  .affiliate_img {
    padding: 1.5rem;
  }

  div.sign {
    width: 90%;
    padding: 58px 20px 50px;
  }

  .parent h4 {
    padding: 0 9%;
  }

  .mypage-body_table {
    width: 97.5%;
    margin: 0 auto;
  }

  .mypage-body_table_list {
    width: 97.5%;
    margin: 0 auto;
  }

  .order-Header__box,
  .order-detail-header {
    width: 100%;
  }

  .order-deatil__payinfo-box,
  .order-detail-header-textbox {
    width: 100% !important;
  }

  .order-detail-header {
    width: 100% !important;
    height: 150px;
    font-size: 13.5px;
  }

  .order-detail-header-textbox {
    display: block;
  }

  .mypage-body_table_list thead th {
    font-size: 13px;
  }

  .order-deatil__payinfo-contents {
    grid-template-columns: 1fr 2fr;
  }

  .only-order-header-list {
    display: block;
    width: 100%;
  }

  .only-order-header-list-tit {
    display: block !important;
    text-align: left;
    margin: 0 0 10px 10px;
  }

  .only-order-header-btm {
    width: 100% !important;
    padding: 5px 10px;
  }
}

/* 모바일  */
@media (max-width: 768px) {
  .affiliate_img {
    padding: 0.75rem;
  }

  .parent h4 {
    padding: 0 7%;
  }

  .maxist_body > div > div:last-child p {
    font-size: 8px;
  }

  .maxist_body > div > div:first-child > div {
    padding: 1.5rem 20px;
  }

  .order-notice_ex > p {
    display: inline-block;
    text-align: center;
    width: 100%;
    font-size: 12.5px;
  }

  .receipt_box {
    position: relative;
    width: 100%;
    height: 20px;
  }

  .receipt_box:first-child,
  .receipt_box:last-child {
    height: 40px;
  }

  .cart-result-box__paragraph {
    position: absolute;
    top: 20px;
    left: 0;
  }

  .cart-result-box_receipt {
    position: absolute;
    left: 0;
  }

  .cart-result-box_receipt_number {
    position: absolute;
    right: 0;
  }

  .main_swiper_ad_img div:first-child {
    background-size: contain !important;
    height: 39% !important;
  }

  .search-list__header__box {
    width: 100% !important;
  }

  .search-list__radio {
    width: 250px !important;
    padding-right: 0 !important;
  }

  .center-body_list_faq p,
  .center-body_list_faq div,
  .center-body_list-faq_answer p span {
    font-size: 10px !important;
  }

  .login-page {
    width: 95%;
    margin: 0 auto;
  }

  .login-page > div:first-child,
  .login-page > div:nth-child(2) {
    width: 100%;
  }

  .parent h4 {
    padding: 0;
    width: 38%;
    justify-content: center;
  }
  .center-nav {
    width: 62%;
  }
  .center-nav > div {
    padding-top: 15px;
  }

  .center-nav > div span.cursor {
    font-size: 12.5px;
  }

  .panda-intro_estimate_wrap_ex h4,
  .panda-intro_search_tp > h4,
  .introLeft_ex h4 {
    font-size: 23.5px;
  }

  .scroll_container {
    bottom: 95px;
    transform: translateX(5%);
  }

  .panda-intro_search_btm ul li img {
    max-width: 75% !important;
    height: auto !important;
  }
}

/* 모바일(구) */
@media (max-width: 370px) {
}

svg:not(:root).svg-inline--fa {
  overflow: visible; }

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -.125em; }
  .svg-inline--fa.fa-lg {
    vertical-align: -.225em; }
  .svg-inline--fa.fa-w-1 {
    width: 0.0625em; }
  .svg-inline--fa.fa-w-2 {
    width: 0.125em; }
  .svg-inline--fa.fa-w-3 {
    width: 0.1875em; }
  .svg-inline--fa.fa-w-4 {
    width: 0.25em; }
  .svg-inline--fa.fa-w-5 {
    width: 0.3125em; }
  .svg-inline--fa.fa-w-6 {
    width: 0.375em; }
  .svg-inline--fa.fa-w-7 {
    width: 0.4375em; }
  .svg-inline--fa.fa-w-8 {
    width: 0.5em; }
  .svg-inline--fa.fa-w-9 {
    width: 0.5625em; }
  .svg-inline--fa.fa-w-10 {
    width: 0.625em; }
  .svg-inline--fa.fa-w-11 {
    width: 0.6875em; }
  .svg-inline--fa.fa-w-12 {
    width: 0.75em; }
  .svg-inline--fa.fa-w-13 {
    width: 0.8125em; }
  .svg-inline--fa.fa-w-14 {
    width: 0.875em; }
  .svg-inline--fa.fa-w-15 {
    width: 0.9375em; }
  .svg-inline--fa.fa-w-16 {
    width: 1em; }
  .svg-inline--fa.fa-w-17 {
    width: 1.0625em; }
  .svg-inline--fa.fa-w-18 {
    width: 1.125em; }
  .svg-inline--fa.fa-w-19 {
    width: 1.1875em; }
  .svg-inline--fa.fa-w-20 {
    width: 1.25em; }
  .svg-inline--fa.fa-pull-left {
    margin-right: .3em;
    width: auto; }
  .svg-inline--fa.fa-pull-right {
    margin-left: .3em;
    width: auto; }
  .svg-inline--fa.fa-border {
    height: 1.5em; }
  .svg-inline--fa.fa-li {
    width: 2em; }
  .svg-inline--fa.fa-fw {
    width: 1.25em; }

.fa-layers svg.svg-inline--fa {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0; }

.fa-layers {
  display: inline-block;
  height: 1em;
  position: relative;
  text-align: center;
  vertical-align: -.125em;
  width: 1em; }
  .fa-layers svg.svg-inline--fa {
    transform-origin: center center; }

.fa-layers-text, .fa-layers-counter {
  display: inline-block;
  position: absolute;
  text-align: center; }

.fa-layers-text {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center; }

.fa-layers-counter {
  background-color: #ff253a;
  border-radius: 1em;
  box-sizing: border-box;
  color: #fff;
  height: 1.5em;
  line-height: 1;
  max-width: 5em;
  min-width: 1.5em;
  overflow: hidden;
  padding: .25em;
  right: 0;
  text-overflow: ellipsis;
  top: 0;
  transform: scale(0.25);
  transform-origin: top right; }

.fa-layers-bottom-right {
  bottom: 0;
  right: 0;
  top: auto;
  transform: scale(0.25);
  transform-origin: bottom right; }

.fa-layers-bottom-left {
  bottom: 0;
  left: 0;
  right: auto;
  top: auto;
  transform: scale(0.25);
  transform-origin: bottom left; }

.fa-layers-top-right {
  right: 0;
  top: 0;
  transform: scale(0.25);
  transform-origin: top right; }

.fa-layers-top-left {
  left: 0;
  right: auto;
  top: 0;
  transform: scale(0.25);
  transform-origin: top left; }

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em; }

.fa-xs {
  font-size: .75em; }

.fa-sm {
  font-size: .875em; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit; }

.fa-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em; }

.fa-pull-left {
  float: left; }

.fa-pull-right {
  float: right; }

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: .3em; }

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: .3em; }

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
          animation: fa-spin 2s infinite linear; }

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
          animation: fa-spin 1s infinite steps(8); }

@-webkit-keyframes fa-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes fa-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg); }

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg); }

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg); }

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1); }

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1); }

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1); }

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  filter: none; }

.fa-stack {
  display: inline-block;
  height: 2em;
  position: relative;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0; }

.svg-inline--fa.fa-stack-1x {
  height: 1em;
  width: 1.25em; }

.svg-inline--fa.fa-stack-2x {
  height: 2em;
  width: 2.5em; }

.fa-inverse {
  color: #fff; }

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.svg-inline--fa .fa-primary {
  fill: var(--fa-primary-color, currentColor);
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1); }

.svg-inline--fa .fa-secondary {
  fill: var(--fa-secondary-color, currentColor);
  opacity: 0.4;
  opacity: var(--fa-secondary-opacity, 0.4); }

.svg-inline--fa.fa-swap-opacity .fa-primary {
  opacity: 0.4;
  opacity: var(--fa-secondary-opacity, 0.4); }

.svg-inline--fa.fa-swap-opacity .fa-secondary {
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1); }

.svg-inline--fa mask .fa-primary,
.svg-inline--fa mask .fa-secondary {
  fill: black; }

.fad.fa-inverse {
  color: #fff; }

