.mallCategory_wrap {
  width: 1100px;
  margin: 1px auto 0;
}

.category-container {
  margin-bottom: 100px !important;
}
.category-nav {
  font-weight: var(--panda-main-font-weight);
}
.category-nav__list {
  font-size: 13px !important;
  text-decoration-line: none;
}
.category-nav__list:hover {
  color: var(--panda-main-color);
  font-weight: var(--panda-main-font-weight);
  text-decoration-line: none !important;
}
.category-box {
  width: 980px;
}
.category-list {
  background-color: #fafafa;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 15px;
  font-size: 10px;
  /* color: #7f8c8d; */
  color: #444;
  font-weight: 600;
}
.category-list__manufacturer:hover {
  color: var(--panda-main-color);
}

.category-heading {
  padding: 10px;
  font-size: 15px !important;
  background-color: #eaebec;
  cursor: pointer;
}
.category-counting,
.category-list__title {
  margin-top: 10px;
  font-weight: var(--chakra-fontWeights-semibold);
}
.category-list__title-box {
  display: flex;
}

.category-list_header {
  width: 100%;
  height: 60px;
  padding: 10px;
  padding-left: 30px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  display: grid;
  grid-template-columns: 2fr 4fr;
  background-color: #f9f9f9;
}
.category-info,
.category-info__box,
.category-info_list,
.category-info-list_item {
  display: flex;
}
.category-info {
  width: 980px;
  flex-direction: column;
  border-top: 2px solid gray;
  font-weight: var(--chakra-fontWeights-semibold);
}
.category-info__box {
  height: 100px;
  padding: 10px;
  background-color: #f0f0f0;
  justify-content: space-between;
  position: re;
}
.category-info__box-img {
  width: 100px;
  height: 80px;
  margin-right: 10px;
}
.mallCategory-info__box-info:first-child > a {
  position: absolute;
  width: 100px;
  height: 80px;
}

.category-info__contents {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 30px;
}

.category-info__contents-provider {
  display: flex;
  font-size: 12px;
  margin-left: 10px;
}

.category-info-list_blank {
  width: 10%;
}
.category-info-list_item {
  width: 90%;
  padding: 10px;
  justify-content: space-between;
  border-bottom: 1px solid gray;
  text-align: center !important;
}
.category-info_list:last-child .category-info-list_item {
  border-bottom: 1px solid white;
}

.category-info_list__text {
  font-weight: var(--chakra-fontWeights-semibold);
  width: fit-content;
  display: block;
  margin: 0 auto;
}

.category-info-list_item__goto {
  margin: 10px 0px;
  width: 150px;
  height: 40px;
  font-size: 15px;
  font-weight: var(--chakra-fontWeights-semibold);
  color: white;
  background-color: var(--panda-main-color);
  border-radius: 3px;
}
.category-info-list_item__goto:hover {
  background-color: #c0392b;
}
.category-info-list_item__goto__text {
  font-size: 10px;
  color: #a6a9ad !important;
}

.mallCategory-info-list_item__goto_backOrder {
  border: 1px solid #ccc;
  margin: 10px 0px;
  width: 120px;
  height: 30px;
  font-size: 13px !important;
}

.mallCategory-info-list_item__goto_inventory {
  /* border: 1px solid #ccc; */
  background-color: orange !important;
  margin: 10px 0px;
  width: 120px;
  height: 30px;
  font-size: 13px !important;
  color: #fff !important;
}

.mallCategory-info-list_item__goto_inventory_list {
  margin-top: 5px;
  height: 30px;
  width: 100%;
}

.mallCategory-grid_product_link > a {
  display: block;
  width: 100%;
  height: 30px;
}

.mallCategory-info-list_item__goto_inventory_list > div {
  display: block;
  font-size: 12px !important;
  padding: 5px 15px;
  background-color: orange;
  color: #fff;
  border-radius: 3px;
}
.mallCategory-info-list_item__goto_inventory_list_grid {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mallCategory-info-list_item__goto_inventory_list_grid > div {
  background-color: orange;
  color: #fff;
  padding: 5px 15px;
  border-radius: 3px;
  font-size: 13px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overflow-show {
  height: auto;
  overflow: visible;
}
.overflow-hidden {
  height: 130px;
  overflow: hidden;
}

/* =====전체 카테고리==== */
.all-category__title {
  color: rgba(45, 52, 54, 0.9) !important;
  display: flex;
  align-items: center;
}
.all-category__title-hover:hover {
  color: black !important;
}
.all-category__content-hover:hover {
  transform: scale(1.2) translate(10%, -10%);
}
.all-category__img {
  width: 30px !important;
  height: 30px !important;
  margin-right: 5px;
}

/* mallCategory */
.mallCategory-box {
  width: 100%;
}

.mallCategory-nav {
  padding-top: 25px;
}

.mallCategory-nav > nav {
  /* text-align: center; */
}

.mallCategory-nav,
.mallCategory-nav__list {
  font-size: 30px !important;
  text-decoration-line: none;
  color: #555 !important;
  font-weight: 400;
}

.mallCategory-list {
  font-size: 30px !important;
  text-decoration-line: none;
  color: #555;
  font-weight: 400;
}

.mallCategory-list_header {
  width: 100%;
  height: 60px;
  padding: 10px 10px 10px 30px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  display: flex;
  grid-template-columns: 2fr 4fr;
  background-color: #f0f0f0;
}

.mallCategory-list_header > div {
  display: inline;
  text-align: center;
  font-weight: 400;
  margin-top: 10px;
}

.mallCategory-list_header > div:first-child,
.mallCategory-info__box-info:first-child {
  width: 50%;
  display: flex;
}

.mallCategory-list_header > div:last-child,
.mallCategory-info__box-info:last-child {
  width: 150px;
}

.mallCategory-list_header > div:nth-child(2),
.mallCategory-list_header > div:nth-child(3),
.mallCategory-info__box-info:nth-child(2),
.mallCategory-info__box-info:nth-child(3) {
  width: calc((50% - 150px) / 2);
}

.mallCategory-list_header {
  width: 100%;
  height: 60px;
  padding: 10px;
  padding-left: 30px;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  grid-template-columns: 2fr 4fr;
  background-color: #f0f0f0;
}

.mallCategory-info {
  width: 100%;
  flex-direction: column;
  border-top: 1px solid #e7e7e7;
  font-weight: var(--chakra-fontWeights-semibold);
}
.mallCategory-info__box {
  height: 100px;
  padding: 10px;
  justify-content: space-between;
  display: flex;
}

.category-menu .swiper {
  display: flex;
  padding-bottom: 10px;
  margin-top: 10px;
  height: fit-content;
}

.category-menu .swiper-wrapper {
  width: 90%;
  margin: 0 auto;
  height: 35px;
}

.category-menu .swiper-wrapper .swiper-slide {
  box-shadow: 10px 5px 5px #ccc;
}

.category-menu .swiper-wrapper .swiper-slide a,
.category-menu .swiper-wrapper .swiper-slide button {
  display: block;
  width: fit-content;
  height: fit-content;
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 11.5px;
  transform: translate(-50%, -50%);
}

.category-menu .swiper-wrapper .swiper-slide a div {
  width: 100%;
  height: 90%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}

.category-menu .swiper-wrapper .swiper-slide a:hover,
.category-menu .swiper-wrapper .swiper-slide button:hover {
  color: #ea2f3c;
  font-weight: bold;
}

.category-menu .swiper-button-prev,
.category-menu .swiper-button-next {
  background-color: transparent;
  color: #999 !important;
  font-weight: bold;
  width: 50px;
  height: 50px;

  margin-top: 0;
  width: 50px !important;
  height: 100% !important;
  top: 0 !important;
  display: block;
}

.category-menu .swiper-button-prev::after,
.category-menu .swiper-button-next::after {
  font-size: 20px !important;
}

.category-sub {
  margin-bottom: 10px;
}
.category-sub strong {
  font-weight: 700;
  letter-spacing: 0.1em;
  font-size: 15px;
  color: #333;
  line-height: 1.42857143;
}

.category-list-btn {
  width: 100%;
  height: 30px;
  margin: 20px 0;
  position: relative;
}

.category-list-btn ul {
  float: right;
  display: flex;
  margin-right: 10px;
}

.category-list-btn ul li {
  flex: 1;
}

.category-list-btn ul li button {
  display: block;
  font-size: 25px;
  color: #99a3a4;
}

.category-list-btn ul li button.active {
  color: #ee3842;
  font-weight: bold;
}

.category-search {
  display: flex;
  margin: 20px 0;
  height: 35px;
}

.category-search > div {
  display: flex;
}

.category-search > div input {
  border: 1px solid #777;
  border-radius: 10px;
  margin-left: 15px;
  margin-right: 5px;
  width: 350px;
  padding-left: 5px;
}

.category-search > div button {
  width: 35px;
  height: 35px;
  margin-left: 3px;
  background: #ea2f3c;
  border-radius: 10px;
}

.category-search > div button:hover {
  background: #c0392b;
}

.category-search > div button i {
  color: #fff;
  font-size: 17.5px;
  padding: 5px 0;
}

.mallCategory-info-list_item {
  width: 100%;
  padding: 5px 10px;
  justify-content: space-between;
  border-bottom: 1px solid gray;
  text-align: center !important;
}
.mallCategory-info-list_item {
  border-bottom: none;
  flex-direction: column;
}

.mallCategory-info-list_item > p {
  display: inline-block;
  text-align: center;
  width: 90%;
  margin: 0 auto;
  line-height: 35px;
  font-weight: 400;
  color: #111;
}

.mallCategory-info__contents {
  flex: 0 1;
  border-left: 1px solid #e7e7e7;
  margin-right: 0;
  float: right;
}

.mallCategory-info_price span:first-child {
  text-decoration: line-through;
  font-weight: 400;
}

.mallCategory-info_number {
  font-weight: bold;
  line-height: none;
  color: rgb(238, 56, 66);
}

.mallCategory-info-list_item > p span {
  font-weight: bold;
  display: block;
}

.mallCategory-info-list_item__goto {
  margin: 10px 0px;
  width: 120px;
  height: 30px;
  font-size: 13px !important;
}
.mallCategory-info-list_item__goto:hover {
  background-color: #c0392b !important;
}

.mallview_info {
  display: flex;
}

.mallview_info_box {
  width: 600px;
  color: #333;
  padding: 0.25rem;
}

.mallview_info_box h1 {
  margin-top: 25px;
  text-shadow: 2px 2px 2px rgb(255 255 255 / 15%);
}

.mallview_ad {
  width: 1000px;
  /* background-color: red; */
  height: 160px;
  color: #fff;
  margin-top: 2.5rem;
}

.mallview_info_content {
  width: 500px;
  color: #333;
  font-size: 14px;
}

.mallview_pd_img {
  width: 100%;
  max-width: 300px;
  min-height: 250px;
}

.mallview_info_qty {
  width: 290px;
}

.mallCategory-list_container.grid {
}

.mallCategory-list_container.grid .mallCategory-list_wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.mallCategory-grid_product {
  padding: 10px;
  overflow: hidden;
}

.mallCategory-grid_product_img {
  margin-bottom: 5px;
}

.mallCategory-grid_product_img img {
  width: 100%;
  height: auto;
  width: 80px;
  height: 80px;
  overflow: hidden;
  margin: 0 auto;
}

.mallCategory-grid_product_name {
  display: block;
  height: 60px;
}

.mallCategory-grid_product_name span:first-child {
  font-size: 10.5px;
  display: block;
  color: #99a3a4;
}

.mallCategory-grid_product_name span:nth-child(2) {
  font-size: 15px;
  display: block;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mallCategory-grid_product_name span:nth-child(3) {
  font-size: 10.5px;
  color: #99a3a4;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  width: 95%;
}
.mallCategory-grid_product_number {
  display: block;
  margin: 10px 0;
}

.mallCategory-grid_product_number span:first-child {
  font-size: 12.5px;
  display: block;
}

.mallCategory-grid_product_number span:nth-child(2) {
  display: block;
  font-size: 15px;
  color: #99a3a4;
  text-decoration: line-through;
}

.mallCategory-grid_product_number span:nth-child(3) {
  display: block;
  color: #ee3842;
  font-size: 15px;
  font-weight: bold;
}

.mallCategory-grid_content {
  height: fit-content;
}
.mallCategory-grid_product_link {
  display: block;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ccc;
}

.mallCategory-grid_product_link .datasheet {
  display: block;
  text-align: center;
  margin: 0;
}

.mallCategory-grid_product_link > a div {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mallCategory-list_wrap,
.category-list_header {
  display: none;
}

.category-grid_product {
  border-bottom: 1px solid #333;
  margin-bottom: 50px;
  padding-bottom: 25px;
}

.category-grid_product_img img {
  width: 200px;
  height: auto;
  margin: 0 auto;
  overflow: hidden;
}

.category-grid_content {
  width: 100%;
  height: 170px;
  padding-bottom: 25px;
  border-bottom: 1px solid #333;
}

.category-grid_product_name {
  width: 100%;
  height: 60px;
  overflow: hidden;
}

.category-grid_product_name span {
  display: block;
}

.category-grid_product_name span:first-child {
  color: rgb(153, 163, 164);
  font-size: 12px;
}

.category-grid_product_name span:nth-child(2) {
  font-size: 15px;
  color: #111;
  font-weight: bold;
}

.category-grid_product_name span:nth-child(3) {
  font-size: 12px;
  color: rgb(153, 163, 164);
  margin-top: 2.5px;
  overflow: hidden;
  width: 95%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.category-grid_info_contents {
  float: right;
  width: fit-content;
  height: 60px;
  margin-top: 25px;
}

.category-grid_stock_match {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.category-grid_stock_match_logo {
  width: 100%;
  height: 100px;
  position: relative;
}

.category-grid_stock_match_logo img {
  width: 75%;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.category-grid_stock_match_number,
.category-grid_stock_match_pkg {
  width: 100%;
  height: 25px;
  color: #555;
  text-align: center;
}

.category-grid_stock_match_box a {
  display: block;
  width: fit-content;
  margin: 0 auto 10px;
}

.category-grid_stock_match_box {
  padding-bottom: 10px;
  border-bottom: 1px solid #e7e7e7;
}

.category-grid_stock_match_box .category-info-list_item__goto__text {
  text-align: center;
}

.mallCategory-info_right {
  width: 60px;
  height: 25px;
  font-size: 12px !important;
  background-color: rgb(62, 151, 255);
  color: rgb(255, 255, 255) !important;
  border-radius: 5px;
  margin-right: 5px;
  justify-content: center;
  align-items: center;
  display: flex !important;
  text-decoration: none !important;
}

.mallCategory-info_special {
  width: 60px;
  height: 25px;
  font-size: 12px !important;
  background-color: #ee3842;
  color: rgb(255, 255, 255) !important;
  border-radius: 5px;
  padding: 5px;
  margin-right: 5px;
  text-decoration: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.non-dataSheet > div {
  color: #e5e7e9;
  cursor: auto;
}

/* 기본 및 tablet 가로 */
@media (max-width: 1024px) {
  .mall_view {
    width: 100% !important;
  }

  .mallview_ad {
    width: 100%;
  }

  .mallCategory_wrap {
    width: 100%;
  }

  .category-box {
    width: 100%;
  }

  .category-list {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* tablet 세로 */
@media (max-width: 768px) {
  .mallCategory-nav > nav {
    margin-left: 15px;
  }

  .category-grid_stock_match_logo img {
    width: 35%;
  }

  .category-list {
    grid-template-columns: repeat(3, 1fr);
  }

  .category-info {
    width: 100%;
  }

  .mallview_info {
    display: block !important;
  }

  .part_view_bc {
    margin-left: 15px;
  }

  .mallview_info_img {
    align-items: flex-start !important;
  }

  .mallview_info_box {
    width: 100%;
    padding: 5px 15px;
  }

  .mallview_info_content {
    width: 100%;
    color: #333;
    font-size: 14px;
  }

  .mallview_info_content ul li span:first-child {
    font-weight: bold;
    width: 140px;
    display: inline-block;
  }

  .mallview_info_content ul {
    width: 100%;
    height: 100%;
  }

  .mallview_info_content ul li {
    width: 100%;
  }

  .mallview_info_content-mpn,
  .mallview_info_content-manu {
    display: flex;
  }

  .mallview_info_content-mpn span:nth-child(2),
  .mallview_info_content-manu h2 {
    width: calc(100% - 150px);
  }

  .mallview_info_content ul h2,
  .mallview_info_content ul span:nth-child(2) {
    display: inline-block;
    margin-left: 10px;
  }

  .mallview_pd_img {
    max-width: 95%;
    max-height: auto;
    margin: 0 auto;
  }

  .mallview_sheet {
    width: 100%;
    heightl: fit-content;
  }

  .mallview_sheet p {
    width: 95%;
    margin: 5px auto;
  }

  .mallview_info_box article {
    max-width: 100%;
  }

  .mallview_add_cart_btn {
    width: 95%;
    margin: 0 auto;
  }

  .mallview_info_qty {
    width: 95%;
    margin: 0 auto;
  }

  .mallview_info_qty article {
    max-width: 100%;
  }

  .mallview_info_list_btn button {
    font-size: 13.5px;
  }

  .mallCategory-info__box {
    height: 150px;
  }
}

/* 모바일  */
@media (max-width: 480px) {
  .category-list {
    grid-template-columns: repeat(2, 1fr);
  }

  .mallview_info_list_btn button {
    font-size: 11px;
  }

  .category-search > div input {
    width: 300px;
  }

  .mallCategory-list_container,
  .category-list_container {
    overflow: visible;
  }

  .mallCategory-list_wrap,
  .category-list_wrap,
  .mallCategory-info,
  .category-list_header {
    width: 100%;
  }

  .mallCategory-info__box,
  .category-info {
    height: 200px;
    display: block;
  }

  .category-info {
    height: 300px;
  }

  .category-info__box {
    height: 63%;
    display: block;
  }

  .category-info > ul {
    height: 37%;
    border-bottom: 1px solid #555;
  }

  .category-info__box > .category-info__contents {
    float: right;
    margin-right: 5px;
  }

  .mallCategory-info__box-info:first-child {
    width: 100%;
    height: 50%;
    border-bottom: 1px solid #e7e7e7;
    position: relative;
  }

  .mallCategory-info__box-info:nth-child(2) {
    width: 25%;
    float: left;
    height: 55%;
    align-items: center;
    display: flex;
    padding: 1% 0;
  }

  .mallCategory-info__box-info:nth-child(3),
  .mallCategory-info__box-info:last-child {
    width: 40%;
    float: left;
    height: 55%;
    align-items: center;
    display: flex;
    padding: 1% 0;
  }

  .mallCategory-info__box-info:nth-child(3) {
    width: 35%;
  }

  .mallCategory-info__box-info:nth-child(3) p {
    line-height: 1.75;
  }

  .mallCategory-info__box-info:last-child {
    height: 60%;
  }

  .mallCategory-info__contents {
    border-left: none;
    margin-top: 10px;
  }

  .mallCategory-info__contents a:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mallCategory-info {
    border-bottom: 1px solid #444;
  }

  .mallCategory-list_header > div:first-child {
    width: 11%;
  }

  .mallCategory-list_header > div:nth-child(2),
  .mallCategory-list_header > div:nth-child(3) {
    width: 25%;
  }

  .category-list_header {
    display: flex;
  }

  .category-list_header > p {
    flex: 1;
  }

  .category-list__title-box {
    flex: 3;
  }

  .category-list__title-box > p:first-child {
    margin-right: 10px;
  }

  .search-main {
    overflow: visible !important;
  }

  .search-list__header,
  .search-main .flex-between.search-list__content,
  .search-list_provider {
    width: 100% !important;
  }
  .category-list_wrap > ul {
    height: 100%;
  }

  .category-list_wrap > ul > li.category-info {
    height: 100%;
  }

  .category-info__box {
    height: 170px;
  }

  .category-info .category-info-list_blank {
    display: none;
  }
  .category-info .category-info-list_item {
    width: 100%;
  }

  .affiliate_bg {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #222;
    opacity: 0.25;
  }

  .affiliate_banner {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .affiliate_banner p {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    color: #fff;
    font-size: 13.5px;
  }

  .affiliate_banner h2 {
    position: absolute;
    top: 130px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    font-size: 23px;
    color: #fff;
  }

  .affiliate_banner a {
    display: block;
    position: absolute;
    left: 50%;
    top: 210px;
    transform: translateX(-50%);
    z-index: 100;
  }

  .affiliate_warning {
    font-size: 15px !important;
  }

  .affiliate_notice .affiliate_img h4 {
    font-size: 13px;
  }

  .affiliate_notice .affiliate_img img {
    max-width: 75%;
    margin: 0 auto;
  }

  .mallCategory-info__box-info:first-child div {
    width: calc(100% - 100px);
  }
}

/* 모바일(구) */
@media (max-width: 370px) {
  .mallview_info_list_btn {
    font-size: 10px;
  }

  .category-search > div input {
    width: 200px;
  }
  .mallview_info_content p {
    font-size: 11px;
  }
}
