.write-background {
  width: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.write-text {
  width: 100%;
  font-size: 30px;
  color: black;
  font-weight: var(--chakra-fontWeights-semibold);
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid black;
}
.write-subject {
  width: 90% !important;
  height: 40px !important;
  margin: 20px 20px;
  border-radius: 3px;
  border: 1px solid black !important;
  color: black;
}
.write-content {
  width: 95% !important;
  height: 200px !important;
  color: black;
  margin: 0px 10px;
  margin-bottom: 20px;
  border: 1px solid black !important;
}
.write-box-checkbox {
  width: 90% !important;
  text-align: right;
}
.write-checkbox {
  color: black;
  /* border:1px solid black; */
}
.write-register-btn {
  width: 82% !important;
  margin: 10px 0px;
  margin-bottom: 5px;
  padding: 4px;
  background-color: #333333 !important;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 400;
}

div.sign {
  background-color: white;
  display: block;
  width: 65%;
  height: 1050px;
  max-width: 980px;
  margin: 0 auto;
  padding: 58px 90px 50px;
}

div.write-background {
  width: 100%;
}

div.write-text {
  font-weight: 500;
}

div.write-text > span {
  font-size: 15px;
  display: block;
  margin: 15px 0 25px;
  line-height: 1.5;
}

div.write-input {
  width: 100%;
  margin-bottom: 40px;
}

.write-input_tit {
  font-size: 24px;
  color: #000;
  text-align: center;
  padding: 35px 0 50px;
}

div.write-input > input {
  display: block;
  margin: 0 auto 20px;
  padding: 0;
  border: none !important;
  max-width: 400px;
  border-bottom: 1px solid #333 !important;
  border-radius: 0 !important;
}

.write-input_select {
  display: flex;
  color: #333;
  font-size: 15px;
  width: 90%;
  max-width: 400px;
  margin: 0 auto;
}

.write-input_select h3 {
  font-size: 15px;
  display: block;
  margin: 12px 0;
}

.write-input_select select {
  border: 1px solid #333;
  width: 70%;
  margin-left: 20px;
}

.write-input_agree {
  margin-top: 12px;
  color: #333;
  width: 90%;
  margin: 25px auto;
  max-width: 400px;
}

.write-input_agree h3 {
  display: block;
  margin-bottom: 12px;
  font-size: 15px;
}

.write-input_agree_ex input {
  margin-right: 10px;
}

.write-input_agree_ex label {
  color: #999;
  max-width: 400px;
}

.write-input_agree_ex label a {
  font-weight: bold;
}

.write-register-btn {
  display: block;
  background-color: #e53e3e !important;
  font-size: 20px;
  max-width: 400px;
  height: 50px;
}

.write-register-last {
  color: #333;
  font-size: 15px;
  text-align: center;
  margin: 25px 0;
}

/* 기본 및 tablet 가로 */
@media (max-width: 1024px) {
}

/* tablet 세로 */
@media (max-width: 768px) {
}

/* 모바일  */
@media (max-width: 480px) {
}
div.write-text {
  font-size: 20px;
}
div.write-text span {
  font-size: 12px !important;
}

div.write-input .write-input_tit {
  font-size: 20px;
}

.write-input_agree_ex,
.write-register-last {
  font-size: 12px;
}

.write-input_agree h3 {
  font-size: 12px;
}

/* 모바일(구) */
@media (max-width: 370px) {
}
