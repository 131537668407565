.cart-view_btn-container {
  width: 500px !important;
  margin: 40px !important;
  padding: 0px 30px;
}
.cart-view_btn,
.cart-view_btn-order {
  width: 200px !important;
  height: 50px !important;
  padding: 8px !important;
  border: 1px solid #ee3842 !important;
  color: #ee3842 !important;
  font-size: 15px !important;
  border-radius: 3px !important;
}
.cart-view_btn-order {
  background-color: #ee3842 !important;
  color: white !important;
}
.cart-view_btn-order:hover {
  background-color: #c0392b !important;
}
.cart-view_btn:hover {
  background-color: #ecf0f1 !important;
}

.cart-header {
  margin: 10px 0px;
  margin-top: 50px;
}
.cart-header__text {
  font-size: 12px;
  color: var(--panda-main-color);
  background-color: #f7f7f8;
  margin-right: 10px;
}
.cart-header__right {
  width: 350px;
  display: flex;
  justify-content: flex-end;
}
.cart-header__right__btn {
  width: 100px !important;
  color: white;
  background-color: #ee3842 !important;
  margin: 0px 10px;
}
.cart-header__right__coupon {
  margin-right: 5px;
}
.cart-header__search {
  margin: 0px 10px;
}

.cart-checkbox {
  border: 1px solid var(--panda-main-color);
}

.cart-number-input {
  width: 100px;
}
.cart-product-info {
  display: flex;
}
.cart-product-info__text {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: var(--chakra-fontWeights-semibold);
  color: #b2b2b2;
}
.cart-delete-box {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: #f7f7f8;
  border-bottom: 1px solid #989ba2;
  padding: 0px 20px;
}
.cart-delete-box__btn {
  color: #ffffff;
  font-weight: bold;
  border: 1px solid #707070;
  background-color: #707070 !important;
  margin-right: 10px;
}
.cart-result-box {
  width: 95%;
  height: 80px;
  margin: 10px;
  border: 3px solid #989ba2;
}
.cart-result-box__text {
  padding: 20px 150px;
}
.cart-result-box__order-price {
}
.cart-result-box__paragraph {
  font-size: 10px;
  color: #989ba2;
}
.cart-result-list {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 1000px;
  color: #333;
  font-size: 13px;
}
.cart-result-list__text {
  width: 100%;
  font-size: 13px;
  padding-left: 30px;
  margin: 10px 0px;
  color: var(--panda-main-color);
  text-align: left;
}
.cart-result-btns {
  width: 100%;
  padding: 20px;
}
.cart-result-list__btn-box {
  width: 360px;
}
.cart-result-list__btn,
.cart-header__search-btn {
  border: 1px solid #666;
  background-color: white !important;
}
.cart-result-list__btn {
  width: 400px !important;
  background: var(--chakra-colors-gray-100);
  margin-right: var(--chakra-space-2);
  height: var(--chakra-sizes-10);
  min-width: var(--chakra-sizes-10);
  font-size: var(--chakra-fontSizes-md);
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  width: auto;
  line-height: 1.2;
  border-radius: var(--chakra-radii-md);
  font-weight: var(--chakra-fontWeights-semibold);
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
}
.excel-btn {
  width: 100px !important;
  font-size: 12px !important;
}
.cart-result-list__btn:hover {
  background-color: #ecf0f1 !important;
}
.cart-trash {
  cursor: pointer;
  font-size: 1.6em !important;
}
.cart-none {
  font-size: 15px;
  text-align: center !important;
  font-weight: var(--chakra-fontWeights-semibold);
}

/* ======= 거래명세서 =======*/

.estimate-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-right: 20px;
}
.estimate-page-print-btn {
  width: 280px !important;
  height: 50px !important;
  margin-top: 30px;
  background-color: var(--panda-main-color) !important;
  color: white;
  border: none;
}
.estimate-page__buyer-table {
  /* width: 330px;
  height: 150px; */
  /* borderCollapse: "collapse", */
  /* border: 1px solid black;
  text-align: center,; */
}
.estimate-page__buyer-table th,
.estimate-page__buyer-table td {
  border: 1px solid black;
}
.estimate-page__orderlist th,
.estimate-page__orderlist td {
  border: 1px solid black;
}

.cart-header__text_estimate {
  text-align: center;
  font-weight: 400;
  line-height: 1.5;
  font-size: 15px;
  margin-bottom: 10px;
}

.cart-estimate-banner {
  display: block !important;
  height: 130px;
  position: relative;
  background-color: orange;
}

.cart-estimate-banner_tp {
  position: relative;
  width: 100%;
  height: 80%;
}
.cart-estimate-banner_btm {
  position: relative;
  width: 100%;
  height: 20%;
  display: block;
  clear: both;
}

.cart-estimate-banner_tp h1 {
  font-size: 40px;
  position: absolute;
  top: 10px;
  left: 20px;
}

.cart-estimate-banner_tp > div {
  position: absolute;
  top: 20px;
  right: 10px;
}

.cart-estimate-banner_tp > div img {
  width: 30px;
  height: auto;
  filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(306deg)
    brightness(103%) contrast(101%);
  margin: 0 auto 5px;
}

.cart-estimate-banner_tp > div span {
  text-align: center;
}

.cart-estimate-banner_btm span {
  font-size: 15px;
  position: absolute;
  bottom: 10px;
  left: 20px;
}

.estimate-page-body {
  padding-top: 100px;
}
.estimate-page-info {
  margin: 20px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.estimate-page-info-buyer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.estimate-page-info-buyer__box {
  display: flex;
  justify-content: space-around;
  width: 300px;
  border-bottom: 1px solid black;
  margin: 10px 0px;
  padding-bottom: 10px;
}
.estimate-page-info-supply {
  width: 380px;
  height: 150px;
  display: flex;
}
.estimate-page-info-supply-box {
  text-align: center;
}
.estimate-page__total {
  height: 25px;
  font-size: 12px;
  font-weight: 600;
}
.estimate-page__siganture-box {
  position: relative !important;
}
.estimate-page__siganture {
  position: absolute !important;
  width: 60px;
  height: 60px;
  top: -20px;
  left: 30px;
}
.estimate-page-body__title {
  font-size: 25px;
}

.cart-banner {
  width: 1000px;
  color: #fff;
  margin-top: 15px;
}
.cart-banner img {
  width: 1000px;
  height: auto;
}

.cart-body {
  width: 1000px;
  background-color: #fff;
  color: #333;
  font-size: 13px;
}

.order_banner {
  width: 1000px;
  color: #fff;
}

.order_banner img {
  width: 1000px;
  height: none;
}
.order-box {
  width: 1000px;
  color: #333;
  font-size: 13px;
  background-color: #fff;
}

.order-box_info_p {
  width: 1000px;
  height: 20px;
  display: flex;
}
.order-number-table {
  margin-top: 1.25rem;
  border-top: 1px solid #555;
}
.order-payment {
  width: 1000px;
  background-color: #fff;
  color: #333;
  font-size: 13px;
}

.order-notice {
  width: 1000px;
}

.order-payment_btn_mobile {
  display: none !important;
}

.order-done_banner {
  width: 1000px;
  margin-top: 15px;
  color: #fff;
}

.order-done_banner img {
  width: 1000px;
  height: auto;
}

.order-done-table {
  font-size: 15px;
  color: #333;
}

.order-purchaser-row-input input {
  width: 360px;
}

.cart-mobile-box {
  display: none;
}

.cart-header_select_all_chk {
  display: none;
}

.cart-product-row-back-order {
  background-color: #fed7d7;
}

.cart-estimate-mobile-empty {
  width: 100%;
  height: 250px;
  border-top: 1px solid #999;
  position: relative;
}

.cart-estimate-mobile-empty > div {
  width: 100%;
  height: 66px;

  background-color: #f7f7f8;
}

.cart-estimate-mobile-empty h3 {
  width: 100%;
  height: calc(250px - 66px);
  position: relative;
}

.cart-estimate-mobile-empty span {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
}

.cart-order-log-note,
.cart-order-log-note-m {
  border: 1px solid #fed7d7;
  text-align: center;
  background-color: #fed7d7;
  color: #fff;
  padding: 2px 1px;
  border-radius: 3px;
  text-shadow: 2px 2px 2px rgba(0 0 0 / 15%);
}

/* 기본 및 tablet 가로 */
@media (max-width: 1024px) {
  .cart-banner,
  .cart-banner img {
    width: 100%;
  }

  .cart-body {
    width: 100%;
  }

  .cart-result-list {
    width: 100%;
  }

  .order_banner {
    width: 100%;
  }

  .order_banner img {
    width: 100%;
  }

  .order-box {
    width: 100%;
  }

  .order-purchaser-table {
    width: 100% !important;
  }

  .order-payment {
    width: 100%;
  }

  .order-notice {
    width: 100%;
  }

  .order-done_banner {
    width: 100%;
  }

  .order-done_banner img {
    width: 100%;
  }
}

/* tablet 세로 */
@media (max-width: 768px) {
  .cart-body table td {
    -webkit-padding-start: unset;
    padding-inline-start: unset;
    -webkit-padding-end: unset;
    padding-inline-end: unset;
    text-align: center;
  }

  .cart-result-box__text {
    padding: 10px;
  }

  .cart-result-box {
    height: fit-content;
  }

  .cart-result-box__text {
    display: block !important;
  }

  .cart-delete-box {
    font-size: 13px;
    display: block !important;
    width: 100% !important;
    height: fit-content;
    padding: 10px;
  }

  .cart-delete-box button {
    margin-bottom: 10px;
  }

  .cart-delete-box p {
    text-align: right;
  }

  .cart-plus,
  .cart-minus,
  .cart-equal {
    display: none;
  }

  .cart-result-box__text div {
    margin-top: 15px;
  }

  .cart-result-box__text div strong {
    margin-left: 10px;
  }

  .cart-result-box__text div:first-child {
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
  }

  .cart-result-box__text div:last-child {
    padding-top: 15px;
    border-top: 1px solid #ccc;
    text-align: right;
  }

  .cart-view_btn-container a {
    margin-right: 10px;
  }

  .order-box tbody tr td:nth-child(2) {
    display: block;
  }

  .order-box table th,
  .order-box table td {
    font-size: 10px;
  }

  .order-box_info_p {
    width: 100%;
    display: block;
  }

  .order-box_buyer_info {
    display: block !important;
  }

  .order-receiver-table {
    -webkit-margin-start: 0;
    margin-inline-start: 0;
  }

  .order-number-table {
    margin-top: 0;
    border-top: none;
    border-bottom: 1px solid #333;
  }

  .order-done-table {
    width: 90% !important;
    margin: 25px auto;
  }

  .cart-view_btn-container {
    width: 100% !important;
  }
}

/* 모바일  */
@media (max-width: 768px) {
  .order-box {
    display: block !important;
    overflow-x: scroll;
  }

  .order-box table {
    width: 150%;
  }

  .order-purchaser-row-input input {
    width: 218px;
  }

  .order-receiver-table {
    margin-left: 0 !important;
  }

  .order-number-table {
  }

  .order-view__table-font-size {
    width: 130px !important;
  }

  .cart-body {
    width: 97.5%;
    overflow: visible;
    display: block !important;
    margin: 0 auto;
  }

  .cart-pc-box {
    display: none;
  }

  .cart-mobile-box {
    display: block;
  }

  .cart-mobile-box ul {
    width: 100%;
  }

  .cart-mobile-box ul li {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    border-top: 1px solid #222;
  }

  .cart-mobile-box ul li:last-of-type {
    border-bottom: 1px solid #222;
  }

  .cart-mobile-box ul li > div {
    display: flex;
  }

  .cart-mobile-box_tp {
    width: 100%;
    height: 40px;
    justify-content: space-between;
  }

  .cart-mobile-box_tp > div {
    display: flex;
    align-items: center;
  }

  .cart-mobile-box_md1 {
    width: 100%;
    height: 100px;
    display: block;
  }

  .cart-mobile-box_img {
    width: 15%;
    margin: 0 10px;
    float: left;
  }

  .cart-mobile-box_img img {
    width: 100%;
    height: auto;
    margin-top: 0;
  }

  .cart-mobile-box_name p span:first-child {
    font-size: 10.5px;
    color: #bbb;
    display: block;
  }

  .cart-mobile-box_name p span:nth-child(2) {
    font-size: 15px;
    display: block;
    font-weight: 600;
    margin-top: 7.5px;
  }

  .cart-mobile-box_md2 {
    height: 40px;
    display: block;
  }
  .cart-mobile-box_md2 > div {
    float: left;
    font-size: 12.5px;
    width: 100%;
  }

  .cart-mobile-box_md2_estimate > div {
    display: flex;
    margin: 20px 0;
  }

  .cart-mobile-box_md2_estimate p {
    margin-left: 15px;
    font-weight: 600;
    font-size: 14.5px;
    margin-right: 10px;
  }

  .cart-mobile-box_md2 p {
    margin-right: 7.5px;
    font-weight: 400;
    font-size: 15px;
    float: left;
  }

  .cart-mobile-box_md2 p,
  .cart-mobile-box_estimate p {
    width: 48%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .cart-mobile-box_btm {
    width: 100%;
    height: 35px;
    margin-bottom: 20px;
  }

  .cart-mobile-box_estimate {
    width: 100%;
    height: 35px;
    margin-bottom: 20px;
  }

  .cart-mobile-box_btm > div {
    display: flex;
    align-items: center;
  }

  .cart-mobile-input_change_number {
    margin-left: 15px;
    margin-top: 5px;
  }

  .cart-view_btn-order.market-cart {
    background-color: orange !important;
    border: 1px solid orange !important;
  }
  .cart-mobile-input_total {
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-left: 15px;
    font-size: 14.5px;
  }

  .cart-checkbox {
    border: none;
  }

  .cart-header_notice {
    display: block !important;
    margin-bottom: 10px;
  }

  .cart-header_notice > div p:last-child {
    margin-right: 7.5px;
  }

  .cart-header__right__coupon,
  .cart-header__text {
    margin-left: 5px;
  }

  .cart-header_coupon_list {
    margin-top: 10px;
    width: 95%;
    margin-left: 5px;
  }

  .cart-header_select_all_chk {
    display: flex;
    margin-left: 5px;
    margin-top: 10px;
  }
  .cart-header_select_all_chk p {
    margin-left: 7.5px;
  }

  /* 견적장바구니 견적수량 장바구니 시작 */
  .estimate-mobile_m1 {
    display: block !important;
    margin-bottom: 30px;
  }

  .cart-mobile-box_name {
    padding-left: 5px;
    float: left;
  }

  .estimate-mobile-count,
  .estimate-mobile-price {
    margin: 10px 0;
    display: block !important;
    height: 40px;
  }

  .estimate-mobile-count p {
    float: left;
    font-size: 14.5px;
    font-weight: 500;
    padding-left: 5px;
    width: 100px;
  }

  .estimate-mobile-count > div {
    float: left;
    width: calc(100% - 185px);
  }

  .estimate-mobile-count button {
    float: left;
    width: 50px;
    height: 35px;
    margin-left: 15px;
    background-color: orange;
    color: #fff;
  }

  .estimate-mobile-price p {
    float: left;
    font-size: 14.5px;
    font-weight: 500;
    padding-left: 5px;
    width: 100px;
  }

  .estimate-mobile-price input {
    float: left;
    width: calc(95% - 100px);
  }
  /* 견적장바구니 견적수량 장바구니 끝*/

  .order-box table {
    display: none;
  }

  .order-box .cart-mobile-box_img {
    display: flex;
    align-items: center;
  }

  .order-box .cart-mobile-box_name {
    display: flex;
    align-items: center;
  }

  .order-box .cart-mobile-box_md2 > div {
    margin-left: 15px;
  }

  .order-box .cart-mobile-box ul li {
    border-top: 1px solid #ccc;
  }

  .order-box .cart-mobile-box ul li:last-of-type {
    border-bottom: 1px solid #ccc;
  }

  .order-box_title {
    margin-left: 15px;
    padding-bottom: 0 !important;
  }

  .order__filter-checkbox {
    padding-top: 0 !important;
  }

  .order-purchaser-table .order-purchaser-row-input {
    width: unset !important;
    margin-left: 10px;
  }

  .order-receiver-table thead th,
  .order-receiver-table__row th {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .order-receiver-table thead td,
  .order-receiver-table__row td {
    width: 100%;
  }

  .order-receiver-table button {
    font-size: 10px;
  }

  .order-number-table td,
  .order-number-table th,
  .order-payment th {
    text-align: center;
  }

  .cart-mobile-cart-none {
    display: block;
    text-align: center;
    margin: 25% 0;
  }

  .order-box-join-alert {
    display: block !important;
    line-height: 1.5;
  }

  .order-box-join-alert a {
    margin-left: 0;
  }

  .receipt_info_table tr td:first-child {
    width: 130px !important;
    text-align: center !important;
    background-color: #f9f9f9;
    font-weight: 7600;
    font-size: 0.75rem;
    color: #4a5568;
  }

  .receipt_info_table_radio_box label {
    clear: both;
    float: left;
    margin: 3px 0;
  }
}

@media (max-width: 480px) {
  .cart-mobile-box_img {
    width: 25%;
  }
}

/* 모바일(구) */
@media (max-width: 370px) {
}
