.req-password__base {
  width: 1000px;
  height: 570px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
}
.req-password__title {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 30px;
}
.req-password__box {
  height: 250px;
  width: 500px;
  border: 1px solid #ededed;
  border-radius: 5px;
}
.req-password__input,
.req-password__btn {
  width: 300px !important;
  margin-top: 10px;
}
.req-password__btn {
  background-color: #ee3842 !important;
  color: white;
}
.req-password__btn:hover {
  background-color: var(--panda-main-hover-color) !important;
}

.border-red {
  border: 2px solid red !important;
}

@media (max-width: 1024px) {
  .req-password__base {
    width: 100%;
  }
}

/* tablet 세로 */
@media (max-width: 768px) {
  .req-password__box {
    width: 100%;
    border: none;
  }
}

/* 모바일  */
@media (max-width: 480px) {
}

/* 모바일(구) */
@media (max-width: 370px) {
}
